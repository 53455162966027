import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Dunning} from "../../models/dunning.interface";
import {DunningsModularService} from "../../services/dunnings-modular.service";
import {deleteDoc} from "@angular/fire/firestore";
import {ActivatedRoute, Router} from "@angular/router";


@Component({
    selector: 'delete-period-dialog',
    templateUrl: './delete-dunning-dialog.component.html',
    styles: [
        /* language=SCSS */
        `
          .fuse-confirmation-dialog-panel {
            @screen md {
              @apply w-128;
            }

            .mat-mdc-dialog-container {

              .mat-mdc-dialog-surface {
                padding: 0 !important;
              }
            }
          }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    providers: [DunningsModularService]
})
export class DeleteDunningDialogComponent implements OnInit {

    dunning: Dunning;
    isDeleting: boolean;

    /**
     * Constructor
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Dunning,
        public matDialogRef: MatDialogRef<DeleteDunningDialogComponent>,
        private snackbar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
        private dunningsModularService: DunningsModularService
    ) {
        console.log('data', data);
        if (!data) {
            // todo 26.10.21 --> show error
            this.snackbar.open('Etwas ist schief gelaufen, bitte versuchen Sie es später erneut oder wenden Sie sich an den Support', 'OK', {duration: 10000});
            this.matDialogRef.close('cancelled');
        } else {
            this.dunning = data;
            this.dunningsModularService.setParentPathForPeriod(this.dunning?.propertyID, this.dunning?.unitID, this.dunning?.periodID);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    delete(): void {
        this.isDeleting = true;

        deleteDoc(this.dunningsModularService.doc(this.dunning.id))
            .then(() => {
                this.matDialogRef.close();
                const msg = 'Mahnung erfolgreich gelöscht';
                this.snackbar.open(msg, 'Schließen', {duration: 5000});
                return this.router.navigate(['/immobilien', this.dunning?.propertyID, 'einheit', this.dunning?.unitID, 'buchungen', this.dunning?.periodID, 'mahnwesen'])
            })
            .catch((err) => {
                console.error('Error:', err);
                const msg = 'Fehler beim Löschen der Mahnung';
                this.snackbar.open(msg, 'Schließen', {duration: 10000});
            })
            .finally(() => this.isDeleting = false);
    }
}
