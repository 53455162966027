<div
		[class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
		[ngClass]="item.classes?.wrapper"
		class="fuse-vertical-navigation-item-wrapper">

	<div
			(click)="toggleCollapsable()"
			[matTooltip]="item.tooltip || ''"
			[ngClass]="{'fuse-vertical-navigation-item-disabled': item.disabled}"
			class="fuse-vertical-navigation-item">

		<!-- Icon -->
		<ng-container *ngIf="item.icon">
			<mat-icon
					[ngClass]="item.classes?.icon"
					[svgIcon]="item.icon"
					class="fuse-vertical-navigation-item-icon"></mat-icon>
		</ng-container>

		<!-- Title & Subtitle -->
		<div class="fuse-vertical-navigation-item-title-wrapper">
			<div class="fuse-vertical-navigation-item-title">
                <ng-container *ngIf="this.item?.translate?.enabled; else defaultTemplate">
                    <ng-container
                        *transloco="let t; read: this.item?.translate?.scope; scope: this.item?.translate?.scope">
                        <span [ngClass]="item.classes?.title">
                            {{ t(this.item?.title) }}
                        </span>
                    </ng-container>
                </ng-container>
                <ng-template #defaultTemplate>
                    <span [ngClass]="item.classes?.title">
                    {{ this.item?.title }}
                    </span>
                </ng-template>
			</div>
			<ng-container *ngIf="item.subtitle">
				<div class="fuse-vertical-navigation-item-subtitle">
                    <ng-container *ngIf="this.item?.translate?.enabled; else defaultTemplate">
                        <ng-container
                            *transloco="let t; read: this.item?.translate?.scope; scope: this.item?.translate?.scope">
                        <span [ngClass]="item.classes?.subtitle">
                            {{ t(this.item?.subtitle) }}
                        </span>
                        </ng-container>
                    </ng-container>
                    <ng-template #defaultTemplate>
                        <span [ngClass]="item.classes?.subtitle">
                            {{ this.item?.subtitle }}
                        </span>
                    </ng-template>
				</div>
			</ng-container>
		</div>

		<!-- Badge -->
		<ng-container *ngIf="item.badge">
			<div class="fuse-vertical-navigation-item-badge">
				<div
                    [ngClass]="item.badge.classes"
                    class="fuse-vertical-navigation-item-badge-content">
                    <ng-container *ngIf="this.item?.badge.translate?.enabled; else defaultTemplate">
                        <ng-container
                            *transloco="let t; read: this.item?.translate?.scope; scope: this.item?.translate?.scope">
					<span>
						{{ t(this.item?.badge?.title) }}
					</span>
                        </ng-container>
                    </ng-container>
                    <ng-template #defaultTemplate>
					<span>
						{{ this.item?.badge?.title }}
					</span>
                    </ng-template>
                </div>
			</div>
		</ng-container>

		<!-- Arrow -->
		<mat-icon
				[svgIcon]="'heroicons_solid:chevron-right'"
				class="fuse-vertical-navigation-item-arrow icon-size-4"></mat-icon>

	</div>

</div>

<div
		*ngIf="!isCollapsed"
		@expandCollapse
		class="fuse-vertical-navigation-item-children">

	<ng-container *ngFor="let item of item.children; trackBy: trackByFn">

		<!-- Skip the hidden items -->
		<ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

			<!-- Basic -->
			<ng-container *ngIf="item.type === 'basic'">
				<fuse-vertical-navigation-basic-item
						[item]="item"
						[name]="name"></fuse-vertical-navigation-basic-item>
			</ng-container>

			<!-- Collapsable -->
			<ng-container *ngIf="item.type === 'collapsable'">
				<fuse-vertical-navigation-collapsable-item
						[autoCollapse]="autoCollapse"
						[item]="item"
						[name]="name"></fuse-vertical-navigation-collapsable-item>
			</ng-container>

			<!-- Divider -->
			<ng-container *ngIf="item.type === 'divider'">
				<fuse-vertical-navigation-divider-item
						[item]="item"
						[name]="name"></fuse-vertical-navigation-divider-item>
			</ng-container>

			<!-- Group -->
			<ng-container *ngIf="item.type === 'group'">
				<fuse-vertical-navigation-group-item
						[item]="item"
						[name]="name"></fuse-vertical-navigation-group-item>
			</ng-container>

			<!-- Spacer -->
			<ng-container *ngIf="item.type === 'spacer'">
				<fuse-vertical-navigation-spacer-item
						[item]="item"
						[name]="name"></fuse-vertical-navigation-spacer-item>
			</ng-container>

		</ng-container>

	</ng-container>

</div>
