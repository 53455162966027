import {DatePipe} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {fuseAnimations} from '@fuse/animations';
import {DateTime} from 'luxon';
import {combineLatest, firstValueFrom, Subject, take} from 'rxjs';
import {debounceTime, startWith, takeUntil} from 'rxjs/operators';
import {convertTimestamp} from '../../../../../helpers';
import {
    Booking,
    BookingArt,
    BookingArtOptions,
    BookingStatusOptions,
    BookingType,
    BookingTypeOptions
} from '../../models/booking';
import {BookingService} from '../../services/booking.service';
import {
    GenerateBookingDialog,
    GenerateBookingDialogComponent
} from '../generate-booking-dialog/generate-booking-dialog.component';
import {PeriodsService} from "../../../properties/features/units/features/period/services/periods.service";
import {
    PropertyUnitPeriod
} from "../../../properties/features/units/features/period/models/property.unit.period.interface";

export interface CreateBookingDialog {
    propertyID: string
    unitID: string;
    period: PropertyUnitPeriod;
    booking?: Booking; // Optional to edit
}

@Component({
    selector: 'fuse-confirmation-dialog',
    templateUrl: './create-booking-dialog.component.html',
    styles: [
        `
            .fuse-confirmation-dialog-panel {

                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {

                    .mat-mdc-dialog-surface {
                        padding: 0 !important;
                    }
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [BookingService]
})
export class CreateBookingDialogComponent implements OnInit, OnDestroy {

    today = new Date();

    bookingArtOptions = BookingArtOptions;

    propertyID: string;
    unitID: string;
    period: PropertyUnitPeriod;
    booking: Booking;
    bookingTypeOptions = BookingTypeOptions;

    loading: boolean;
    saving: boolean;
    showError: boolean;
    errorMsg: string;

    bookingFormGroup: FormGroup;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: CreateBookingDialog,
                private matDialogRef: MatDialogRef<CreateBookingDialogComponent>,
                private dialog: MatDialog,
                private formBuilder: FormBuilder,
                private bookingService: BookingService,
                private periodsService: PeriodsService,
                private snackbar: MatSnackBar,
                private datePipe: DatePipe) {
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.booking = this.data?.booking;

        this.propertyID = this.data?.propertyID || this.booking?.propertyID;
        this.unitID = this.data?.unitID || this.booking?.unitID;
        this.period = this.data?.period;

        if (!this.period && this.booking?.periodID) {
            this.loading = true;
            firstValueFrom(this.periodsService.docWithParent(`properties/${this.propertyID}/units/${this.unitID}`, this.booking?.periodID).get())
                .then((res) => {
                    if (res.exists) {
                        this.period = res.data() as PropertyUnitPeriod;
                        this.loading = false;
                        this.init();
                    } else {
                        this.snackbar.open('Etwas ist schief gelaufen, bitte versuchen Sie es später erneut oder wenden Sie sich an den Support', 'OK', {duration: 10000});
                        this.matDialogRef.close('cancelled');
                    }
                })
                .catch((err) => {
                    console.error("Error --> ", err);
                    this.snackbar.open('Etwas ist schief gelaufen, bitte versuchen Sie es später erneut oder wenden Sie sich an den Support', 'OK', {duration: 10000});
                    this.matDialogRef.close('cancelled');
                })
        } else {
            this.init();
        }


    }

    init(): void {
        if (!(this.propertyID && this.unitID) && this.booking) {
            this.snackbar.open('Etwas ist schief gelaufen, bitte versuchen Sie es später erneut oder wenden Sie sich an den Support', 'OK', {duration: 10000});
            this.matDialogRef.close('cancelled');
        }

        console.log('CreateBookingDialogComponent --> propertyID --> ', this.propertyID);
        console.log('CreateBookingDialogComponent --> unitID --> ', this.unitID);
        console.log('CreateBookingDialogComponent --> period --> ', this.period);

        this.bookingService.setParentPath(this.propertyID, this.unitID, this.period?.id);
        this.createBookingFG();
        this.listenOnChanges();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    createBookingFG(): void {
        this.bookingFormGroup = this.formBuilder.group({
            id: this.booking?.id || this.bookingService.createID(),
            customerID: this.booking?.customerID || this.bookingService.authService?.customerID || null,
            createdAt: this.booking?.createdAt || this.today,
            propertyID: this.booking?.propertyID || this.propertyID,
            unitID: this.booking?.unitID || this.unitID,
            periodID: this.booking?.periodID || this.period?.id,
            type: this.booking?.type || BookingTypeOptions.PAYMENT,
            status: this.booking?.status || BookingStatusOptions.EXPECTED,
            date: [this.booking?.date ? convertTimestamp(this.booking?.date) : this.suggestDate(), Validators.required],
            amount: [this.booking?.amount, [Validators.required, this.booking?.type === BookingTypeOptions.PAYOUT ? Validators.max(0) : Validators.min(0)]],
            saldo: this.booking?.saldo,
            text: [this.booking?.text, Validators.required],
            art: [this.booking?.art || this.bookingArtOptions.WARM_MIETE, Validators.required],
            paid: this.formBuilder.control(this.booking?.paid ?? false),
            tenantsName: this.booking?.tenantsName || this.period?.tenantsName
        });
    }

    listenOnChanges(): void {

        combineLatest([
            this.bookingFormGroup.get('art')?.valueChanges.pipe(takeUntil(this._unsubscribeAll), debounceTime(250), startWith(this.bookingFormGroup.get('art')?.value)),
            this.bookingFormGroup.get('date')?.valueChanges.pipe(takeUntil(this._unsubscribeAll), debounceTime(250), startWith(this.bookingFormGroup.get('date')?.value))
        ]).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(([art, date]: [BookingArt, Date]) => {
                const dateFC = this.bookingFormGroup.get('date');
                const amountFC = this.bookingFormGroup.get('amount');
                const textFC = this.bookingFormGroup.get('text');
                const dateText = date ? this.datePipe.transform(date, 'MM/yyyy') : '';
                const typeFC = this.bookingFormGroup.get('type')?.value as BookingType;
                const factor = typeFC === BookingTypeOptions.PAYMENT ? 1 : -1;
                const vat = this.period?.isCommercial ? 1.19 : 1;
                const payoutText = typeFC === BookingTypeOptions.PAYOUT ? `Auszahlung ` : '';

                if (!this.booking) {
                    switch (art) {
                        case BookingArtOptions.WARM_MIETE:
                            const total = this.period?.costs?.total * factor * vat;
                            amountFC.patchValue(parseFloat(total.toFixed(2)));
                            // amountFC.patchValue(this.period?.costs?.total * factor * vat);
                            textFC.patchValue(`${payoutText}Miete ${dateText}`);
                            break;
                        case BookingArtOptions.KALT_MIETE:
                            const kalt = this.period?.costs?.kalt?.amount * factor * vat;
                            amountFC.patchValue(parseFloat(kalt.toFixed(2)));
                            amountFC.patchValue(kalt);
                            textFC.patchValue(`${payoutText}Kalte Miete ${dateText}`);
                            break;
                        case BookingArtOptions.KAUTION:
                            const deposit = this.period?.deposit?.amount * factor;
                            amountFC.patchValue(parseFloat(deposit.toFixed(2)));
                            textFC.patchValue(`${payoutText}Kaution`);
                            break;
                        case BookingArtOptions.BK:
                            const BK_VALUE = this.period?.costs?.bk?.amount * factor * vat;
                            amountFC.patchValue(parseFloat(BK_VALUE.toFixed(2)));
                            textFC.patchValue(`${payoutText}BETRIEBSKOSTEN ${dateText}`);
                            break;
                        case BookingArtOptions.HK:
                            amountFC.patchValue(this.period?.costs?.hk?.amount * factor * vat);
                            textFC.patchValue(`${payoutText}Heizkosten ${dateText}`);
                            break;
                        // case BookingArtOptions.DUNNING_FEES:
                        //     dateFC.patchValue(null, {
                        //         emitEvent: false,
                        //         onlySelf: true
                        //     });
                        //     amountFC.patchValue(null);
                        //     textFC.patchValue(`Mahngebühren`);
                        //     break;
                    }
                }
            });

        this.bookingFormGroup.get('art')?.valueChanges.pipe(takeUntil(this._unsubscribeAll), debounceTime(250), startWith(this.bookingFormGroup.get('art')?.value))
            .subscribe((art: BookingArt) => {
                if (!this.booking && art) {
                    const dateFC = this.bookingFormGroup.get('date');
                    const amountFC = this.bookingFormGroup.get('amount');
                    const textFC = this.bookingFormGroup.get('text');
                    switch (art) {
                        case BookingArtOptions.DUNNING_FEES:
                            dateFC.patchValue(null, {
                                emitEvent: false,
                                onlySelf: true
                            });
                            amountFC.patchValue(null);
                            textFC.patchValue(`Mahngebühren`);
                            break;
                        case BookingArtOptions.SP:
                            dateFC.patchValue(null, {
                                emitEvent: false,
                                onlySelf: true
                            });
                            amountFC.patchValue(null);
                            textFC.patchValue(`Sonderposten`);
                            break;
                        case BookingArtOptions.INTEREST_EXPENSES:
                            dateFC.patchValue(null, {
                                emitEvent: false,
                                onlySelf: true
                            });
                            amountFC.patchValue(null);
                            textFC.patchValue(`Zinsaufwendungen`);
                            break;
                    }
                }
            })

        this.bookingFormGroup
            .get('amount')?.valueChanges
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(250), startWith(this.bookingFormGroup.get('amount')?.value))
            .subscribe((amount: number) => {
                console.log('amount = ', amount);
                this.bookingFormGroup.get('saldo').patchValue(amount * -1);
            });

        this.bookingFormGroup
            .get('type')?.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((type: BookingType) => {
                const amountFC = this.bookingFormGroup.get('amount');
                const amountValue = amountFC?.value as number;

                if (type === BookingTypeOptions.PAYOUT) {
                    amountFC.clearValidators();
                    amountFC.setValidators([Validators.required, Validators.max(0)]);
                    if (amountValue > 0) {
                        amountFC.patchValue(amountValue * -1);
                    }
                } else {
                    amountFC.clearValidators();
                    amountFC.setValidators([Validators.required, Validators.min(0)]);
                    if (amountValue < 0) {
                        amountFC.patchValue(amountValue * -1);
                    }
                }
                amountFC.updateValueAndValidity();
                this.bookingFormGroup.updateValueAndValidity();
            });

    }

    suggestDate(): Date {
        const today = DateTime.fromJSDate(this.today);
        const day = today.day;
        return day >= 15 ? today.endOf('month').plus({day: 1}).toJSDate() : today.startOf('month').toJSDate();
    }

    createBooking(): void {

        if (this.bookingFormGroup.invalid) {
            this.snackbar.open('Bitte überprüfen Sie Ihre Eingaben!', 'OK', {duration: 5000});
            this.bookingFormGroup.markAllAsTouched();
            return;
        }

        this.showError = false;
        this.errorMsg = null;
        this.saving = true;

        const booking: Booking = this.bookingFormGroup.getRawValue() as Booking;
        this.bookingService
            .doc(booking?.id)
            .set(booking, {merge: true})
            .then(() => {
                this.snackbar.open('Ihre Buchung wurde erfolgreich in die Sollstellung übertragen.', 'OK', {duration: 5000});
            })
            .catch((err) => {
                this.errorMsg = err;
                this.showError = true;
            })
            .finally(() => {
                this.saving = false;
                this.matDialogRef.close('confirmed');
            });
    }

    openGenerateBookingsDialog(): void {
        const data: GenerateBookingDialog = {
            propertyID: this.propertyID,
            unitID: this.unitID,
            period: this.period
        };

        this.dialog.open(GenerateBookingDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data,
            panelClass: 'fuse-confirmation-dialog-panel',
            maxHeight: '90vh'
        }).afterOpened().pipe(take(1)).subscribe(() => this.matDialogRef.close(undefined));
    }

}
