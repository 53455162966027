<div #dropzoneContainer class="dropzone-container">
	<input
      #avatarBrowse
	  (change)="onBrowseFiles($event)"
	  [accept]="allowedFormatsString"
	  [multiple]="false"
	  id="inputFile"
	  style="display: none"
	  type="file"
	/>

	<div
			[ngStyle]="{
      'width.px': avatar.width,
      'height.px': avatar.height,
      'border-radius': avatar.round ? '50%' : '7px',
      overflow: avatarEditMode ? 'visible' : 'hidden'
    }"
			class="avatar-uploader-container"
	>
		<ng-container *ngIf="!avatarEditMode">
			<div class="uploaded-image">
				<img
						*ngIf="avatar.srcImage && !this.files[this.files.length - 1]?.file; else croppedImage"
						[ngStyle]="{ 'border-radius': avatar.round ? '50%' : 0 }"
						[src]="avatar.srcImage"
						class="valid-image"
						draggable="false"
				/>
				<ng-template #croppedImage>
					<img
                      #uploadedImage
					  *ngIf="
              this.files[this.files.length - 1]?.file && this.files[this.files.length - 1].status === fileStatus.Completed;
              else placeholder
            "
					  [ngStyle]="{ 'border-radius': avatar.round ? '50%' : 0 }"
					  [src]="convertBlobToBase64(this.files[this.files.length - 1].file, uploadedImage)"
					  class="valid-image"
					  draggable="false"
					/>
				</ng-template>
				<ng-template #placeholder>
					<!-- placeholder -->
					<svg
							*ngIf="avatar.round && false"
							class="valid-svg"
							fill="none"
							height="24"
							stroke-width="1.5"
							viewBox="0 0 24 24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M7 18V17C7 14.2386 9.23858 12 12 12V12C14.7614 12 17 14.2386 17 17V18"
						      stroke="currentColor" stroke-linecap="round"/>
						<path
								d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
						/>
					</svg>
					<img src="assets/illustrations/going_up/default/undraw_going_up.svg">
					<svg
							*ngIf="!avatar.round && false"
							class="valid-svg"
							fill="none"
							height="24"
							stroke-width="1.5"
							viewBox="0 0 24 24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M7 18V17C7 14.2386 9.23858 12 12 12V12C14.7614 12 17 14.2386 17 17V18"
						      stroke="currentColor" stroke-linecap="round"/>
						<path
								d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
						/>
					</svg>
				</ng-template>
			</div>
			<div
					[ngClass]="{
          'upload-error':
            files[files.length - 1]?.status === fileStatus.Canceled || files[files.length - 1]?.status === fileStatus.Unsupported,
          uploading: files[files.length - 1]?.status === fileStatus.Uploading,
          'small-container': avatar.width <= 150
        }"
					class="actions-container"
			>
				<!-- edit icon -->
				<ng-container [ngSwitch]="files[files.length - 1]?.status">

					<ng-container *ngSwitchCase="fileStatus.Uploading">
						<!--						<div [style.width.%]="files[files.length - 1].progress" class="upload-in-progress"></div>-->
						<div [style.width.%]="this.uploadPercent | async" class="upload-in-progress"></div>
						<!--						<div class="upload-in-text">Uploading({{ files[files.length - 1].progress }}%)</div>-->
						<div class="upload-in-text">Uploading({{ this.uploadPercent | async }}%)</div>
					</ng-container>

					<ng-container *ngSwitchCase="fileStatus.Canceled">
						<div (click)="onRestart(files.length - 1)" class="action-button"
						     title="Restart">
							<svg fill="none" height="30" title="restart" viewBox="0 0 24 24" width="30"
							     xmlns="http://www.w3.org/2000/svg">
								<path
										d="M7 10.625H14.2C14.2 10.625 14.2 10.625 14.2 10.625C14.2 10.625 17 10.625 17 13.625C17 17 14.2 17 14.2 17H13.4"
										stroke="currentColor"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="1.5"
								/>
								<path d="M10.5 14L7 10.625L10.5 7" stroke="currentColor" stroke-linecap="round"
								      stroke-linejoin="round" stroke-width="1.5"/>
								<path
										d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
										stroke="currentColor"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="1.5"
								/>
							</svg>
							<div class="title">
								{{ files[files.length - 1].error[0] }}
							</div>
						</div>
					</ng-container>

					<ng-container *ngSwitchDefault>
						<div *ngIf="files[files.length - 1]?.error?.length! > 0" class="error-container">
							<span class="error-title">Incorrect File!</span>
							<div class="error-messages">
								<span *ngFor="let error of files[files.length - 1]?.error">- {{ error }}</span>
							</div>
						</div>
						<div class="panel">
							<div (click)="avatarBrowse.click()"
							     [title]="files.length === 0 ? 'Hinzufügen' : 'Logo ändern'"
							     class="action-button">
								<mat-icon svgIcon="mat_outline:edit"></mat-icon>
								<!--								<svg fill="none" height="30" stroke-width="1.5" viewBox="0 0 24 24" width="30"-->
								<!--								     xmlns="http://www.w3.org/2000/svg">-->
								<!--									<path d="M3 21L12 21H21" stroke="currentColor" stroke-linecap="round"-->
								<!--									      stroke-linejoin="round"/>-->
								<!--									<path-->
								<!--											d="M12.2218 5.82839L15.0503 2.99996L20 7.94971L17.1716 10.7781M12.2218 5.82839L6.61522 11.435C6.42769 11.6225 6.32233 11.8769 6.32233 12.1421L6.32233 16.6776L10.8579 16.6776C11.1231 16.6776 11.3774 16.5723 11.565 16.3847L17.1716 10.7781M12.2218 5.82839L17.1716 10.7781"-->
								<!--											stroke="currentColor"-->
								<!--											stroke-linecap="round"-->
								<!--											stroke-linejoin="round"-->
								<!--									/>-->
								<!--								</svg>-->
								<div class="title">{{ files.length === 0 ? 'Logo hinzufügen' : 'Logo ändern' }}</div>
							</div>
							<div
									(click)="onCancelEdit()"
									*ngIf="files[files.length - 1]?.status === fileStatus.Unsupported"
									class="action-button"
									title="Cancel"
							>
								<svg fill="none" height="30" stroke-width="1.5" viewBox="0 0 24 24" width="30"
								     xmlns="http://www.w3.org/2000/svg">
									<path
											class="circle-x"
											d="M9.17218 14.8284L12.0006 12M14.829 9.17157L12.0006 12M12.0006 12L9.17218 9.17157M12.0006 12L14.829 14.8284"
											stroke="currentColor"
											stroke-linecap="round"
											stroke-linejoin="round"
									/>
									<path
											class="circle"
											d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
											stroke="currentColor"
											stroke-linecap="round"
											stroke-linejoin="round"
									/>
								</svg>
								<div class="title">Cancel</div>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</div>
		</ng-container>
        <dropzone-lib-cropperjs
				(cancelCrop)="onCancelEdit()"
				(croppedAvatar)="onAvatarCropped($event)"
				*ngIf="avatarEditMode"
				[avatar]="{ width: avatar.width, height: avatar.height - (avatar.round ? 0 : 50), round: avatar.round }"
				[file]="files[this.files.length - 1].file"
        ></dropzone-lib-cropperjs>
	</div>
</div>
