import { Pipe, PipeTransform } from '@angular/core';
import { PropertyUnitCategory } from '../../modules/admin/properties/features/units/models/unit.interface';

@Pipe({
  name: 'categorySVG'
})
export class CategorySVGPipe implements PipeTransform {

  transform(value: PropertyUnitCategory, args: PropertyUnitCategory): string {
    switch (args) {
      case PropertyUnitCategory.RESIDENTIAL:
        return 'assets/illustrations/at_home/undraw_at_home.svg';
      case PropertyUnitCategory.COMMERCIAL:
        return 'assets/illustrations/business_shop/undraw_business_shop.svg';
      default:
        return 'assets/icons/error/error.svg';
    }
  }

}
