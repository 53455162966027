<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">

    <mat-drawer-container
        (backdropClick)="onBackdropClicked()"
        class="flex-auto h-full bg-card dark:bg-transparent">

        <!-- Drawer -->
        <mat-drawer
            #matDrawer
            [disableClose]="true"
            [mode]="drawerMode"
            [opened]="false"
            [position]="'end'"
            class="w-full md:w-160 dark:bg-gray-900">
            <router-outlet></router-outlet>
        </mat-drawer>

        <mat-drawer-content class="flex flex-col">

            <!-- Main -->
            <div class="flex-auto">

                <div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden bg-card dark:bg-transparent">

                    <!-- Header -->
                    <div
                        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
                        <!-- Loader -->
                        <div
                            *ngIf="this.isLoading"
                            class="absolute inset-x-0 bottom-0">
                            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                        </div>
                        <!-- Title -->
                        <div class="flex flex-row space-x-2">
                            <div class="text-4xl font-extrabold tracking-tight">Mahnwesen</div>
                            <button (click)="this.openYoutubeDialog(true)"
                                    color="accent"
                                    mat-icon-button
                                    matTooltip="Klicken Sie hier, um das Tutorial-Video zum Mahnwesen-Modul anzusehen und zu lernen, wie Sie Mahnungen und Zahlungserinnerungen effizient erstellen können.">
                                <mat-icon svgIcon="mat_outline:info"></mat-icon>
                            </button>
                        </div>
                        <!-- Actions -->

                        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">

                            <ais-instantsearch [config]='this.config' class="block">
                                <!--                                <div class="flex-auto">-->
                                <instant-search (onNewQuery)="onNewQuery($event)"
                                                [disabled]="this?.dataSource?.length === 0 || (!!this.searchState?.query && this.hitsSource?.data?.length === 0)"
                                                [placeHolder]="'Mahnungen durchsuchen'"
                                                class="flex fuse-mat-dense fuse-mat-rounded min-w-64"
                                                iconToolTip="Nach Referenz-ID der Mahnung oder Mietername suchen"></instant-search>
                                <ais-stats>
                                    <ng-template let-state='state'>
                                        <ng-template (onNewStat)='onNewStat($event)' [state]='state'
                                                     appStatListener></ng-template>
                                        <span *ngIf='this.state?.query?.length > 0 && !this.isLoading' @zoomIn @zoomOut
                                              class="absolute inset-x-6 md:inset-x-8 bottom-0">{{this.state.nbHits}}
                                            Ergebnisse gefunden in {{this.state.processingTimeMS}}
                                            ms.</span>
                                    </ng-template>
                                </ais-stats>
                                <ais-hits>
                                    <ng-template let-hits='hits' let-results='results'>
                                        <ng-template (onNewHits)='onNewHits($event)' [hits]='hits'
                                                     appHitsListener></ng-template>
                                    </ng-template>
                                </ais-hits>
                                <!--                                </div>-->
                            </ais-instantsearch>

                            <a @slideInRight @slideOutLeft
                               [color]="'primary'"
                               [routerLink]="'/immobilien/'+this.property?.id + '/einheit/' + this.unit?.id+'/buchungen/'+ this.period?.id +  '/mahnwesen/erstellen'"
                               class="px-1 sm:px-4 min-w-10"
                               mat-flat-button>
                                <mat-icon
                                    [svgIcon]="'heroicons_solid:plus-circle'"
                                    class="icon-size-5"></mat-icon>
                                <span class="hidden sm:inline-block ml-2">Mahnung erstellen</span>
                            </a>
                        </div>
                    </div>

                    <!-- Main -->
                    <div class="flex flex-auto overflow-hidden">

                        <!-- MSA list -->
                        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden">

                            <ng-container *ngIf="this.dataSource?.data?.length > 0; else loading">

                                <!-- Table wrapper -->
                                <div
                                    cdkScrollable
                                    class="overflow-x-auto sm:overflow-y-auto">

                                    <!-- Table -->
                                    <table
                                        (matSortChange)='sortMsaData($event)'
                                        [dataSource]='this.searchState && this.searchState?.query ? this.hitsSource : this.dataSource'
                                        [matSortDisableClear]="true"
                                        [multiTemplateDataRows]="true"
                                        [ngClass]="{'pointer-events-none': this.isLoading}"
                                        [trackBy]="trackByFn"
                                        class="w-full min-w-256 table-fixed bg-transparent"
                                        mat-table
                                        matSort>

                                        <!-- Checkbox Column -->
                                        <ng-container matColumnDef="select">
                                            <th *matHeaderCellDef
                                                class="w-24 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                mat-header-cell>
                                                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                                              [aria-label]="checkboxLabel()"
                                                              [checked]="selection.hasValue() && isAllSelected()"
                                                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                              color="primary">
                                                </mat-checkbox>
                                            </th>
                                            <td *matCellDef="let row; let i = index" mat-cell>
                                                <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                                                              (click)="$event.stopPropagation()"
                                                              [aria-label]="checkboxLabel(row,i)"
                                                              [checked]="selection.isSelected(row)"
                                                              color="primary">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container>

                                        <!-- id-->
                                        <ng-container matColumnDef='id'>
                                            <th *matHeaderCellDef
                                                matTooltip="Eindeutige Kennung oder Referenznummer der Mahnung."
                                                class="w-24 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                mat-header-cell>
                                                ID
                                            </th>
                                            <td
                                                *matCellDef="let dunning"
                                                class="pr-8 truncate"
                                                mat-cell>
                                                <div *ngIf='this.searchState && this.searchState?.query; else data'>
                                                    <span
                                                        [innerHTML]="dunning?._highlightResult?.refID?.value"
                                                        matTooltip="Einzigartige Referenz-ID der Mahnung: Ermöglicht schnelle Identifikation und Nachverfolgung"></span>
                                                </div>
                                                <ng-template #data>
                                                <span
                                                    matTooltip="Einzigartige Referenz-ID der Mahnung: Ermöglicht schnelle Identifikation und Nachverfolgung">{{ dunning?.refID || '' }}</span>
                                                </ng-template>
                                            </td>
                                        </ng-container>

                                        <!-- tenants-->
                                        <ng-container matColumnDef='tenantsName'
                                                      matTooltip="Name des Mieters oder der Organisation, an die die Mahnung gerichtet ist.">
                                            <th *matHeaderCellDef
                                                class="w-24 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                mat-header-cell>
                                                Mieter
                                            </th>
                                            <td
                                                *matCellDef="let dunning"
                                                class="pr-8 truncate"
                                                mat-cell>
                                                <div *ngIf='this.searchState && this.searchState?.query; else data'>
                                                    <span
                                                        [innerHTML]="dunning?._highlightResult?.tenantsName?.value"></span>
                                                </div>
                                                <ng-template #data>
                                                    <span>{{ dunning?.tenantsName || '' }}</span>
                                                </ng-template>
                                            </td>
                                        </ng-container>

                                        <!-- address-->
                                        <ng-container matColumnDef='address'
                                                      matTooltip="Informationen über das Mietobjekt, wie Adresse oder Einheit.">
                                            <th *matHeaderCellDef
                                                class="w-24 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                mat-header-cell>
                                                Objekt
                                            </th>
                                            <td
                                                *matCellDef="let dunning"
                                                class="pr-8 truncate"
                                                mat-cell>
                                                {{ (dunning?.address | formatAddress) || '' }}
                                            </td>
                                        </ng-container>

                                        <!-- date-->
                                        <ng-container matColumnDef='date'
                                                      matTooltip="Datum, an dem die Mahnung erstellt wurde.">
                                            <th *matHeaderCellDef
                                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                mat-header-cell>
                                                Mahnungsdatum
                                            </th>
                                            <td
                                                *matCellDef="let dunning"
                                                class="pr-8 truncate"
                                                mat-cell>
                                                {{ dunning?.date | toDate | date:'shortDate' }}
                                            </td>
                                        </ng-container>

                                        <!-- dueDate-->
                                        <ng-container matColumnDef='dueDate'
                                                      matTooltip="Datum, bis zu dem die Zahlung erfolgen soll.">
                                            <th *matHeaderCellDef
                                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                mat-header-cell>
                                                Fälligkeitsdatum
                                            </th>
                                            <td
                                                *matCellDef="let dunning"
                                                class="pr-8 truncate"
                                                mat-cell>
                                                {{ dunning?.dueDate | toDate | date:'shortDate' }}
                                            </td>
                                        </ng-container>

                                        <!-- status der Mahnung-->
                                        <ng-container matColumnDef='status'>
                                            <th *matHeaderCellDef
                                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                matTooltip="Status der Mahnung: Zeigt an, ob die Mahnung bezahlt wurde oder noch offen ist."
                                                disableClear
                                                mat-header-cell
                                                mat-sort-header>
                                                Status
                                            </th>
                                            <td
                                                *matCellDef="let dunning"
                                                class="pr-8 truncate"
                                                mat-cell>
                                                <div class="flex items-center w-5">
                                                    <!--                                                    <div class="flex w-full">-->
                                                    <div *ngIf="dunning?.status !== this.bookingStatusOptions.PAID"
                                                         class="px-2 bg-warn text-on-warn rounded"
                                                         matTooltip="Offen: Die Mahnung wurde noch unbezahlt und ist offen.">
                                                        Offen
                                                    </div>
                                                    <div *ngIf="dunning?.status === this.bookingStatusOptions.PAID"
                                                         class="px-2 bg-primary text-on-primary rounded"
                                                         matTooltip="Bezahlt: Die Mahnung wurde vollständig bezahlt.">
                                                        Bezahlt
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>


                                        <!-- process-->
                                        <ng-container matColumnDef='process'>
                                            <th
                                                *matHeaderCellDef
                                                class="w-60 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                disableClear
                                                mat-header-cell
                                                mat-sort-header
                                                matTooltip="Gibt an, ob es sich um eine Zahlungserinnerung, erste Mahnung, zweite Mahnung usw. handelt.">
                                                Mahnungsstufe
                                            </th>
                                            <td
                                                *matCellDef="let dunning"
                                                class=" w-24"
                                                mat-cell>
                                                <div class="flex items-center w-5">
                                                    <div
                                                        *ngIf="this.dunning?.type === this.dunningTypeOptions.PAYMENT_REMINDER"
                                                        class="flex items-center justify-center py-1 px-3 rounded-full leading-normal text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
                                                        <span
                                                            class="text-sm font-medium whitespace-nowrap">Zahlungserinnerung</span>
                                                    </div>

                                                    <div
                                                        *ngIf="this.dunning?.type === this.dunningTypeOptions.FIRST_REMINDER"
                                                        class="flex items-center justify-center py-1 px-3 rounded-full leading-normal text-white bg-orange-500 dark:text-white-300 dark:bg-orange-400">
                                                        <span class="text-sm font-medium whitespace-nowrap">Erste Mahnung</span>
                                                    </div>

                                                    <div
                                                        *ngIf="this.dunning?.type === this.dunningTypeOptions.SECOND_REMINDER"
                                                        class="flex items-center justify-center py-1 px-3 rounded-full leading-normal text-white bg-warn-400 dark:text-white-300 dark:bg-warn-500">
                                                        <span class="text-sm font-medium whitespace-nowrap">Zweite Mahnung</span>
                                                    </div>

                                                    <div
                                                        *ngIf="this.dunning?.type === this.dunningTypeOptions.FINAL_REMINDER"
                                                        class="flex items-center justify-center py-1 px-3 rounded-full leading-normal text-white bg-warn dark:text-white-300 dark:bg-warn-900">
                                                        <ng-container [ngSwitch]="dunning?.lastWarningType">
                                                            <span *ngSwitchDefault
                                                                  class="text-sm font-medium whitespace-nowrap">Letzte Mahnung</span>
                                                            <span
                                                                *ngSwitchCase="this.lastWarningTypeOptions.FINAL_WARNING_BEFORE_LEGAL_ACTION"
                                                                class="text-sm font-medium whitespace-nowrap">LM. vor Mahnbescheid</span>
                                                            <span
                                                                *ngSwitchCase="this.lastWarningTypeOptions.FINAL_WARNING_BEFORE_TERMINATION"
                                                                class="text-sm font-medium whitespace-nowrap">LM. vor Fristloskündigung</span>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </td>

                                        </ng-container>

                                        <!-- amountToPayInclFeesAndInterest -->
                                        <ng-container matColumnDef='amountToPayInclFeesAndInterest'>
                                            <th
                                                *matHeaderCellDef
                                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                mat-header-cell
                                                matTooltip="Gesamtbetrag, der gezahlt werden muss (einschließlich überfälliger Miete, Mahngebühren und Verzugszinsen).">
                                                Betrag
                                            </th>
                                            <td
                                                *matCellDef="let dunning"
                                                class="pr-8 truncate"
                                                mat-cell>
                                                <div *ngIf='this.searchState && this.searchState?.query; else data'>
                                                    <span
                                                        [innerHTML]="dunning?._highlightResult?.amountToPayInclFeesAndInterest?.value + '€'"></span>
                                                </div>
                                                <ng-template #data>
                                                    <span>{{ dunning?.amountToPayInclFeesAndInterest | currency:'EUR' }}</span>
                                                </ng-template>
                                            </td>
                                        </ng-container>

                                        <!-- buttons -->
                                        <ng-container matColumnDef='buttons'>
                                            <th
                                                *matHeaderCellDef
                                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                mat-header-cell>
                                                Aktionen
                                            </th>
                                            <td
                                                *matCellDef="let dunning"
                                                class="pr-8 truncate"
                                                mat-cell>
                                                <div class="flex flex-row space-x-2">
                                                    <button (click)="$event.stopPropagation(); this.getPdf(dunning)"
                                                            *ngIf="!!dunning?.url"
                                                            matTooltip="Mahnungsschreiben als PDF herunterladen"
                                                            color="primary" mat-icon-button>
                                                        <mat-icon svgIcon="mat_outline:picture_as_pdf"></mat-icon>
                                                    </button>
                                                    <button
                                                        (click)="$event.stopPropagation(); this.openDeleteDunningDialog(dunning)"
                                                        color="warn"
                                                        mat-icon-button
                                                        matTooltip="Mahnung löschen">
                                                        <mat-icon svgIcon="mat_outline:delete_forever"></mat-icon>
                                                    </button>
                                                </div>
                                            </td>
                                        </ng-container>

                                        <tr
                                            *matHeaderRowDef="displayedColumns; sticky: true"
                                            class="shadow"
                                            mat-header-row></tr>
                                        <tr
                                            (click)="goToDunning(dunning)"
                                            *matRowDef="let dunning; columns: displayedColumns;"
                                            [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !this.selected || this.selected?.id !== dunning?.id,
                                                    'bg-primary-50 dark:bg-hover': this.selected && this.selected?.id === dunning?.id}"
                                            class="h-18 hover:bg-hover cursor-pointer"
                                            mat-row></tr>

                                    </table>

                                </div>

                                <mat-paginator (page)='onPageChanged($event)'
                                               *ngIf="!(this.searchState && this.searchState?.query)"
                                               @zoomIn
                                               @zoomOut
                                               [disabled]="this.isLoading"
                                               [length]="this.page?.length"
                                               [ngClass]="{'pointer-events-none': this.isLoading}"
                                               [pageIndex]="this.page?.pageIndex"
                                               [pageSizeOptions]="[10, 25,50, 100]"
                                               [pageSize]="this.page?.pageSize"
                                               [showFirstLastButtons]="false"
                                               class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent">
                                </mat-paginator>
                            </ng-container>

                            <ng-template #loading>
                                <div *ngIf="!this.initialLoad; else nodata"
                                     class="p-8 sm:p-16 flex justify-center items-center nodata-container h-full">
                                    <mat-spinner></mat-spinner>
                                </div>
                            </ng-template>
                            <ng-template #nodata>
                                <div class="flex flex-col h-full overflow-x-auto sm:overflow-y-auto">
                                    <div class="flex flex-col justify-center p-8 sm:p-16 h-full">
                                        <div class="flex justify-center">
                                            <!-- Card #4 -->
                                            <fuse-card
                                                class="flex flex-col max-w-80 sm:max-w-100 w-full filter-article">
                                                <img
                                                    [src]="'assets/illustrations/in_the_office/' + this.appTheme + '/undraw_in_the_office.svg'"
                                                    alt="Card cover image"
                                                    class="object-cover">
                                                <div class="m-8 mb-4">
                                                    <div class="text-2xl font-semibold leading-tight">
                                                        Keine Mahnungen vorhanden
                                                    </div>
                                                    <!--														<div class="text-secondary text-md mt-1">Unbegrenzt</div>-->
                                                    <div class="mt-4">
                                                        Aktuell gibt es keine ausstehenden Mahnungen. Sie können neue
                                                        Mahnungen erstellen, um überfällige Mietzahlungen effektiv zu
                                                        verwalten und zu verfolgen.
                                                    </div>
                                                    <div class="flex mt-3 -mx-3 justify-center">
                                                        <a [color]="'primary'"
                                                           [routerLink]="'/immobilien/'+this.property?.id + '/einheit/' + this.unit?.id+'/buchungen/'+ this.period?.id +  '/mahnwesen/erstellen'"
                                                           class="px-3 items-center"
                                                           mat-button>
                                                            <mat-icon
                                                                [svgIcon]="'mat_outline:add_circle'"></mat-icon>
                                                            <span class="ml-2">Mahnung erstellen</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </fuse-card>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                        </div>

                    </div>

                </div>

            </div>

        </mat-drawer-content>

    </mat-drawer-container>

</div>
