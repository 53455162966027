<div #dropzoneContainer [ngStyle]="{ 'grid-template-rows': files.length > 0 ? 'auto 1fr' : '1fr' }"
     class="dropzone-container">
	<div class="drag-area">
		<span class="main-message">Drag and Drop or <label class="browse-link" for="inputFile">Browse</label></span>
		<input
				(change)="onBrowseFiles($event)"
				[accept]="allowedFormatsString"
				[multiple]="multiple"
				id="inputFile"
				style="display: none"
				type="file"
		/>
		<ng-content></ng-content>
	</div>
	<div *ngIf="files.length > 0" class="files-table">
		<div *ngFor="let item of files; index as i" class="file-row">
			<div class="main-row">
				<div (click)="onCancel(i)" [ngClass]="{ 'active-cancel': item.status === fileStatus.Uploading }"
				     class="cancel-file" title="stop">
					<svg fill="none" height="30" stroke-width="1.5" viewBox="0 0 24 24" width="30"
					     xmlns="http://www.w3.org/2000/svg">
						<path
								class="circle-x"
								d="M9.17218 14.8284L12.0006 12M14.829 9.17157L12.0006 12M12.0006 12L9.17218 9.17157M12.0006 12L14.829 14.8284"
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
						/>
						<path
								class="circle"
								d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
						/>
					</svg>
				</div>
				<div class="details">
					<div class="name">
						{{ item.file.name }}
					</div>
					<div *ngIf="item.status !== fileStatus.Canceled && item.status !== fileStatus.Completed"
					     class="info">
						<div (click)="onToggleSizeUnit()" class="pill size" title="Toggle Size Unit">
							{{ item | convertSizeUnit: displayUnit }} {{ displayUnit }}
						</div>
						<div (click)="onToggleSizeUnit()" *ngIf="item.status === fileStatus.Uploading"
						     class="pill uploading">
							{{ item.status }}<span *ngIf="item.status === fileStatus.Uploading"
							                       class="dots-pulse">...</span>
						</div>
					</div>
				</div>
				<div
						(click)="onRestart(i)"
						[ngClass]="{ 'active-restart': item.status === fileStatus.Canceled }"
						class="restart-file"
						title="Restart"
				>
					<svg fill="none" height="30" viewBox="0 0 24 24" width="30" xmlns="http://www.w3.org/2000/svg">
						<path
								d="M7 10.625H14.2C14.2 10.625 14.2 10.625 14.2 10.625C14.2 10.625 17 10.625 17 13.625C17 17 14.2 17 14.2 17H13.4"
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="1.5"
						/>
						<path d="M10.5 14L7 10.625L10.5 7" stroke="currentColor" stroke-linecap="round"
						      stroke-linejoin="round" stroke-width="1.5"/>
						<path
								d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="1.5"
						/>
					</svg>
				</div>
				<div [ngClass]="{ 'active-success': item.status === fileStatus.Completed }" class="success-file"
				     title="Completed!">
					<svg fill="none" height="30" stroke-width="1.5" viewBox="0 0 24 24" width="30"
					     xmlns="http://www.w3.org/2000/svg">
						<path d="M7 12.5L10 15.5L17 8.5" stroke="currentColor" stroke-linecap="round"
						      stroke-linejoin="round"/>
						<path
								class="circle"
								d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
						/>
					</svg>
				</div>
			</div>
			<div
              #file
			  [ngStyle]="{
          width: item.progress + '%',
          height: item.status === fileStatus.Uploading ? '10px' : 0
        }"
			  class="progress-file"
			></div>
			<ul *ngIf="item.error.length > 0" class="file-errors">
				<li *ngFor="let error of item.error">
					{{ error }}
				</li>
			</ul>
		</div>
	</div>
	<div *ngIf="!autoUpload && files.length > 0" class="status-bar">
		<div (click)="onReset()" class="action-button reset" title="Reset">Reset</div>
		<div (click)="onStartUpload()" class="action-button" title="Upload">Upload</div>
	</div>
</div>
