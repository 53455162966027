import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface YoutubeDialogData {
  videoID: string;
  title: string;
  subtitle: string;
}

export type YoutubeDialogResult = 'CONTINUE' | 'IGNORE'

@Component({
  selector: 'fuse-confirmation-dialog',
  templateUrl: './youtube-dialog.component.html',
  styles: [
    `
      .fuse-confirmation-dialog-panel {

        @screen md {
          @apply w-256;
        }

        .mat-mdc-dialog-container {

          .mat-mdc-dialog-surface {
            padding: 0 !important;
          }
        }
      }
    `
  ],
  encapsulation: ViewEncapsulation.None
})
export class YoutubeDialogComponent implements OnInit, OnDestroy {

  videoID: string;
  title: string;
  subtitle: string;

  videoHeight: number = 500;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: YoutubeDialogData,
              private fuseMediaWatcherService: FuseMediaWatcherService,
              private _changeDetectorRef: ChangeDetectorRef) {
    this.videoID = data?.videoID;
    this.title = data?.title;
    this.subtitle = data?.subtitle;
    this._loadApi();
  }

  ngOnInit(): void {
    // Subscribe to media changes
    this.fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        console.log('matchingAliases = ', matchingAliases);
        // Set the drawerMode if the given breakpoint is active
        if (matchingAliases.includes('md')) {
          // this.videoHeight = 500;
        } else {
          // this.videoHeight = 256;
        }
        this._changeDetectorRef.detectChanges();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  private _loadApi() {
    if (!window.YT) {
      // We don't need to wait for the API to load since the
      // component is set up to wait for it automatically.
      const script = document.createElement('script');
      script.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(script);
    }
  }


}
