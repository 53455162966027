import { GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import { Pipe, PipeTransform } from '@angular/core';
import { formatAddress } from '../../helpers/address';

export type AddressPipeFormat = 'short' | 'full'

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(address: GermanAddress, format: AddressPipeFormat = 'full'): unknown {
    if (address) {
      switch (format) {
        case 'short':
          return `${address?.streetName} ${address?.streetNumber}, ${address?.locality?.short || address?.locality?.long}`;
        default:
          return formatAddress(address);
      }
    }
    return '';
  }

}
