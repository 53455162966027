import {CurrencyPipe} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {QuerySnapshot} from '@angular/fire/compat/firestore';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {reverse} from 'lodash-es';
import * as moment from 'moment/moment';
import {ApexOptions, ChartComponent} from 'ng-apexcharts';
import {forkJoin, Subject, takeUntil} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {convertTimestamps} from '../../../../../helpers';
import {
    PropertyUnitPeriod
} from '../../../properties/features/units/features/period/models/property.unit.period.interface';
import {PropertyUnit} from '../../../properties/features/units/models/unit.interface';
import {Property} from '../../../properties/models/property.interface';
import {BookingAccount, BookingAccountOptions, BookingAccountStat} from '../../models/bookingAccount';
import {BookingAccountService} from '../../services/bookingAccount.service';
import {BookingAccountBKStatsService} from '../../services/bookingAccountBKStats.service';
import {BookingAccountDepositStatsService} from '../../services/bookingAccountDepositStats.service';
import {BookingAccountHKStatsService} from '../../services/bookingAccountHKStats.service';
import {BookingAccountRentalStatsService} from '../../services/bookingAccountRentalStats.service';
import {BookingAccountSaldoStatsService} from '../../services/bookingAccountSaldoStats.service';

@Component({
    selector: 'project',
    templateUrl: './booking-accounts-dashboard.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    providers: [BookingAccountService, BookingAccountRentalStatsService, BookingAccountSaldoStatsService, BookingAccountDepositStatsService, BookingAccountBKStatsService, BookingAccountHKStatsService]
})
export class BookingAccountsDashboardComponent implements OnInit, OnDestroy {

    @ViewChild('bookingAccountsChart') chart: ChartComponent;
    @ViewChild('rentalChart') rentalChart: ChartComponent;
    @ViewChild('tenantsAccChart') tenantsAccChart: ChartComponent;
    @ViewChild('saldoChart') saldoChart: ChartComponent;

    property: Property;
    unit: PropertyUnit;
    period: PropertyUnitPeriod;
    bookingAccounts: BookingAccount[];
    loadingRentalStats: boolean = true;
    rentalPerc: number;

    loadingTenantAccStats: boolean = true;
    tenantsAccPerc: number;

    loadingSaldoStats: boolean = true;
    saldoPerc: number;

    bookingsAccountsDataSource: MatTableDataSource<BookingAccount>;
    chartBookingAccountsDistribution: ApexOptions = {};
    chartTenantsAccounts: ApexOptions = {};
    chartRental: ApexOptions = {};
    chartSaldo: ApexOptions = {};
    data: any;
    totalRental: number;
    totalSaldo: number;
    accumulatedTotalAmount: number;

    // columns: string[] = ['id', 'amount', 'saldo', 'saldoPercentage', 'updatedAt'];
    columns: string[] = ['type', 'total', 'remainingAmount', 'remainingPercentage', 'expensesPercentage'];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private cf: ChangeDetectorRef,
        private currencyPipe: CurrencyPipe,
        private _router: Router,
        private route: ActivatedRoute,
        private bookingAccountService: BookingAccountService,
        private bookingAccountRentalStatsService: BookingAccountRentalStatsService,
        private bookingAccountSaldoStatsService: BookingAccountSaldoStatsService,
        private bookingAccountDepositStatsService: BookingAccountDepositStatsService,
        private bookingAccountBKStatsService: BookingAccountBKStatsService,
        private bookingAccountHKStatsService: BookingAccountHKStatsService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.property = this.route.snapshot.data?.property as Property;
        this.unit = this.route.snapshot?.data?.unit as PropertyUnit;
        this.period = this.route.snapshot?.data?.period as PropertyUnitPeriod;
        this.bookingAccounts = this.route.snapshot?.data?.bookingAccounts as BookingAccount[];
        console.log('BookingAccountsDashboardComponent --> data --> ', this.property, this.unit, this.period);
        console.log('BookingAccountsDashboardComponent --> bookingAccounts --> ', this.bookingAccounts);

        if (this.bookingAccounts?.length) {
            this.bookingsAccountsDataSource = new MatTableDataSource<BookingAccount>(this.bookingAccounts);
            this.reduceBookingAccounts(this.bookingAccounts);
        }

        this.bookingAccountService.setParentPathForPeriod(this.property?.id, this.unit?.id, this.period?.id);
        this.bookingAccountRentalStatsService.setParentPathForPeriod(this.property?.id, this.unit?.id, this.period?.id);
        this.bookingAccountSaldoStatsService.setParentPathForPeriod(this.property?.id, this.unit?.id, this.period?.id);
        this.bookingAccountDepositStatsService.setParentPathForPeriod(this.property?.id, this.unit?.id, this.period?.id);
        this.bookingAccountBKStatsService.setParentPathForPeriod(this.property?.id, this.unit?.id, this.period?.id);
        this.bookingAccountHKStatsService.setParentPathForPeriod(this.property?.id, this.unit?.id, this.period?.id);

        this._prepareChartData();
        this.loadBookingAccountsStatsForRental();
        this.loadBookingAccountsStatsForSaldo();
        this.loadTenantBookingAccountsBK_HK_DEPOSIT();
        this.loadRentalBankAccount();
        this.loadSaldoBankAccount();
        this.loadBookingAccounts();

        // Attach SVG fill fixer to all ApexCharts
        window['Apex'] = {
            chart: {
                events: {
                    mounted: (chart: any, options?: any): void => {
                        this._fixSvgFill(chart.el);
                    },
                    updated: (chart: any, options?: any): void => {
                        this._fixSvgFill(chart.el);
                    }
                }
            }
        };
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item?.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    runRentalStatsLogic(stats: BookingAccountStat[]) {
        if (stats?.length > 1) {
            const prevStat: BookingAccountStat = stats[stats?.length - 2];
            const currentStat: BookingAccountStat = stats[stats?.length - 1];
            this.rentalPerc = 100 - (prevStat?.amount * 100 / currentStat?.amount);
            console.log('runRentalStatsLogic --> ', this.rentalPerc);
        }
    }

    loadBookingAccountsStatsForRental(): void {
        this.bookingAccountRentalStatsService.collection(query => query.orderBy('to', 'desc').limit(4))
            .valueChanges()
            .pipe(takeUntil(this._unsubscribeAll), map(bookingAccountStats => reverse(convertTimestamps(bookingAccountStats) as BookingAccountStat[]) as BookingAccountStat[]))
            .subscribe((stats) => {
                const x = [
                    {
                        name: 'Mieteinnahmen',
                        data: stats?.map(stat => stat?.amount || 0)
                    }
                ];
                this.rentalChart.updateOptions({xaxis: {categories: stats.map(stat => moment(stat?.from).format('DD MMM') + ' - ' + moment(stat?.to).format('DD MMM'))}});
                this.rentalChart.updateSeries(x);
                console.log('loadBookingAccountsStatsForRental --> ', stats);
                this.runRentalStatsLogic(stats);
                if (this.loadingRentalStats) {
                    this.loadingRentalStats = false;
                }
                this.cf.markForCheck();
            });
    }

    loadTenantBookingAccountsBK_HK_DEPOSIT(): void {
        forkJoin([
            this.bookingAccountDepositStatsService.collection(query => query.orderBy('to', 'desc').limit(4))
                .get()
                .pipe(take(1), map((query: QuerySnapshot<any>) => query.docs?.map(d => d.data())), map(bookingAccountStats => convertTimestamps(bookingAccountStats) as BookingAccountStat[])),
            this.bookingAccountBKStatsService.collection(query => query.orderBy('to', 'desc').limit(4))
                .get()
                .pipe(take(1), map((query: QuerySnapshot<any>) => query.docs?.map(d => d.data())), map(bookingAccountStats => convertTimestamps(bookingAccountStats) as BookingAccountStat[])),
            this.bookingAccountHKStatsService.collection(query => query.orderBy('to', 'desc').limit(4))
                .get()
                .pipe(take(1), map((query: QuerySnapshot<any>) => query.docs?.map(d => d.data())), map(bookingAccountStats => convertTimestamps(bookingAccountStats) as BookingAccountStat[]))
        ]).pipe(take(1))
            .subscribe(([depositBA, bkBA, hkBA]: [BookingAccountStat[], BookingAccountStat[], BookingAccountStat[]]) => {
                console.log('depositBA --> ', depositBA);
                console.log('bkBA --> ', bkBA);
                console.log('hkBA --> ', hkBA);

                for (let i = 0; i < depositBA?.length; i++) {
                    const bk = bkBA[i]?.amount || 0;
                    const hk = hkBA[i]?.amount || 0;
                    depositBA[i].amount += bk + hk;
                }

                const reverseDepositBA = reverse(depositBA) as BookingAccountStat[];

                const x = [
                    {
                        name: 'Mieteinnahmen',
                        data: depositBA?.map(stat => stat?.amount || 0)
                    }
                ];
                this.tenantsAccChart.updateOptions({xaxis: {categories: reverseDepositBA.map(stat => moment(stat?.from).format('DD MMM') + ' - ' + moment(stat?.to).format('DD MMM'))}});
                this.tenantsAccChart.updateSeries(x);
                console.log('loadBookingAccountsStatsForRental --> ', reverseDepositBA);
                this.tenantsAccPerc = this.runStatsPercentageLogic(reverseDepositBA);
                if (this.loadingTenantAccStats) {
                    this.loadingTenantAccStats = false;
                }
                this.cf.markForCheck();
            });
    }

    runStatsPercentageLogic(stats: BookingAccountStat[]): number {
        if (stats?.length > 1) {
            const prevStat: BookingAccountStat = stats[stats?.length - 2];
            const currentStat: BookingAccountStat = stats[stats?.length - 1];
            const x = prevStat?.amount;
            const y = currentStat?.amount;
            const z = x < 0 ? x * -1 : x;
            const percentage = ((y - x) / z) * 100;
            console.log('runStatsPercentageLogic --> ', percentage);
            return percentage;
        }
    }

    loadBookingAccountsStatsForSaldo(): void {
        this.bookingAccountSaldoStatsService.collection(query => query.orderBy('to', 'desc').limit(4))
            .valueChanges()
            .pipe(takeUntil(this._unsubscribeAll), map(bookingAccountStats => reverse(convertTimestamps(bookingAccountStats) as BookingAccountStat[]) as BookingAccountStat[]))
            .subscribe((stats) => {
                const x = [
                    {
                        // name: 'Saldo',
                        data: stats?.map(stat => stat?.amount || 0)
                    }
                ];
                this.saldoChart.updateOptions({xaxis: {categories: stats.map(stat => moment(stat?.from).format('DD MMM') + ' - ' + moment(stat?.to).format('DD MMM'))}});
                this.saldoChart.updateSeries(x);
                console.log('loadBookingAccountsStatsForSaldo --> ', stats);
                this.saldoPerc = this.runStatsPercentageLogic(stats);
                if (this.loadingSaldoStats) {
                    this.loadingSaldoStats = false;
                }
                this.cf.markForCheck();
            });
    }

    loadRentalBankAccount(): void {
        this.bookingAccountService.doc('rental').valueChanges().pipe(takeUntil(this._unsubscribeAll)).subscribe((rentalBookingAccount) => {
            this.totalRental = rentalBookingAccount?.amount || 0;
            this.cf.markForCheck();
        });
    }

    loadSaldoBankAccount(): void {
        this.bookingAccountService.doc('saldo').valueChanges().pipe(takeUntil(this._unsubscribeAll)).subscribe((saldoBookingAccount) => {
            this.totalSaldo = saldoBookingAccount?.saldo || 0;
            this.cf.markForCheck();
        });
    }

    loadBookingAccounts(): void {
        this.bookingAccountService.collection(query => query.where('id', 'in', ['bk', 'deposit', 'hk']))
            .valueChanges()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((bookingAccounts) => {
                console.log('booking accounts received --> ', bookingAccounts);
                this.bookingsAccountsDataSource = new MatTableDataSource<BookingAccount>(bookingAccounts);
                this.reduceBookingAccounts(bookingAccounts);
                const x = [
                    {
                        name: 'Kontostand',
                        data: bookingAccounts?.map(b => b?.amount || 0)
                    },
                    {
                        name: 'OP',
                        data: bookingAccounts?.map(b => b?.saldo || 0)
                    }
                ];
                this.chart.updateOptions({xaxis: {categories: bookingAccounts.map(b => this.convertBookingAccountIDToText(b))}});
                this.chart.updateSeries(x);
                this.cf.markForCheck();
            });
    }

    convertBookingAccountIDToText(bookingAccount: BookingAccount): string {
        switch (bookingAccount?.id) {
            case BookingAccountOptions.bk:
                return 'Betriebskosten';
            case BookingAccountOptions.hk:
                return 'Heizkosten';
            case BookingAccountOptions.deposit:
                return 'Kaution';
        }
    }

    reduceBookingAccounts(bookingsAccounts: BookingAccount[]): void {
        if (bookingsAccounts?.length) {
            this.accumulatedTotalAmount = bookingsAccounts.map(account => account?.amount ?? 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0);

        } else {
            this.accumulatedTotalAmount = 0;
        }
    }

    /**
     * Fix the SVG fill references. This fix must be applied to all ApexCharts
     * charts in order to fix 'black color on gradient fills on certain browsers'
     * issue caused by the '<base>' tag.
     *
     * Fix based on https://gist.github.com/Kamshak/c84cdc175209d1a30f711abd6a81d472
     *
     * @param element
     * @private
     */
    private _fixSvgFill(element: Element): void {
        // Current URL
        const currentURL = this._router.url;

        // 1. Find all elements with 'fill' attribute within the element
        // 2. Filter out the ones that doesn't have cross reference so we only left with the ones that use the 'url(#id)' syntax
        // 3. Insert the 'currentURL' at the front of the 'fill' attribute value
        Array.from(element.querySelectorAll('*[fill]'))
            .filter(el => el.getAttribute('fill').indexOf('url(') !== -1)
            .forEach((el) => {
                const attrVal = el.getAttribute('fill');
                el.setAttribute('fill', `url(${currentURL}${attrVal.slice(attrVal.indexOf('#'))}`);
            });
    }

    /**
     * Prepare the chart data from the data
     *
     * @private
     */
    private _prepareChartData(): void {

        // booking accounts distribution
        this.chartBookingAccountsDistribution = {
            chart: {
                fontFamily: 'inherit',
                foreColor: 'inherit',
                height: '100%',
                type: 'bar',
                stacked: true
            },
            colors: ['#818CF8', '#FB7185'],
            dataLabels: {
                enabled: true,
                formatter: (val: number): string | number => this.currencyPipe.transform(val, 'EUR'),
                textAnchor: 'start',
                style: {
                    fontSize: '13px',
                    fontWeight: 500
                },
                background: {
                    borderWidth: 0,
                    padding: 4
                }
                // offsetY: -15
            },
            markers: {
                strokeColors: '#818CF8',
                strokeWidth: 4
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        total: {
                            enabled: false,
                            offsetX: 0,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                }
            },
            series: [
                {
                    name: 'Kontostand',
                    data: this.bookingAccounts?.map(b => b?.amount || 0)
                },
                {
                    name: 'OP',
                    data: this.bookingAccounts?.map(b => b?.saldo || 0)
                }
            ],
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            tooltip: {
                theme: 'dark',
                y: {
                    formatter: (val: number): string => this.currencyPipe.transform(val, 'EUR')
                }
            },
            xaxis: {
                labels: {
                    show: true,
                    style: {
                        fontSize: '12px',
                        fontWeight: '500'
                    }
                },
                categories: this.bookingAccounts?.map(b => this.convertBookingAccountIDToText(b))
            },
            yaxis: {
                // max: (max: number): number => parseInt((max + 10).toFixed(0), 10),
                // tickAmount: 7
            },
            fill: {
                opacity: 2
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            }
        };

        // Rental Chart
        this.chartRental = {
            chart: {
                animations: {
                    enabled: false
                },
                fontFamily: 'inherit',
                foreColor: 'inherit',
                height: '100%',
                type: 'line',
                sparkline: {
                    enabled: true
                }
            },
            colors: ['#4ADE80'],
            series: [{
                name: 'Mieteinnahmen',
                data: []
            }],
            stroke: {
                curve: 'smooth'
            },
            tooltip: {
                theme: 'dark'
            },
            xaxis: {
                type: 'category',
                categories: []
            },
            yaxis: {
                labels: {
                    formatter: (val): string => this.currencyPipe.transform(val, 'EUR')
                }
            }
        };

        // Tenants Accounts Balance expenses
        this.chartTenantsAccounts = {
            chart: {
                animations: {
                    enabled: false
                },
                fontFamily: 'inherit',
                foreColor: 'inherit',
                height: '100%',
                type: 'line',
                sparkline: {
                    enabled: true
                }
            },
            colors: ['#4f46e5'],
            series: [
                {
                    name: 'Mieterkontenstand',
                    data: []
                }
            ],
            stroke: {
                curve: 'smooth'
            },
            tooltip: {
                theme: 'dark'
            },
            xaxis: {
                type: 'category',
                categories: []
            },
            yaxis: {
                labels: {
                    formatter: (val): string => this.currencyPipe.transform(val, 'EUR')
                }
            }
        };

        // Saldo Chart
        this.chartSaldo = {
            chart: {
                animations: {
                    enabled: false
                },
                fontFamily: 'inherit',
                foreColor: 'inherit',
                height: '100%',
                type: 'line',
                sparkline: {
                    enabled: true
                }
            },
            colors: ['#dc2626'],
            series: [
                {
                    name: 'Saldo Betrag',
                    data: []
                }
            ],
            stroke: {
                curve: 'smooth'
            },
            tooltip: {
                theme: 'dark'
            },
            xaxis: {
                type: 'category',
                categories: []
            },
            yaxis: {
                labels: {
                    formatter: (val): string => this.currencyPipe.transform(val, 'EUR')
                }
            }
        };
    }
}
