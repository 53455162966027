import { NgModule } from '@angular/core';
import { DisableControlDirective } from '@shared/directives/disable-control/disable-control.directive';


@NgModule({
  declarations: [DisableControlDirective],
  exports: [
    DisableControlDirective
  ]
})
export class DisableControlModule {
}
