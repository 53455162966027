import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { BaseWidget, NgAisIndex, NgAisInstantSearch } from 'angular-instantsearch';
import { connectAutocomplete } from 'instantsearch.js/es/connectors';
import { Institution } from '../../../../modules/admin/institutions/models/institution.interface';
import { Landlord } from '../../../../modules/admin/landlords/models';

@Component({
  selector: 'app-admin-autocomplete-algolia-institutions-only-search',
  templateUrl: './autocomplete-algolia-institutions-only-search.component.html',
  styleUrls: ['./autocomplete-algolia-institutions-only-search.component.scss']
})
export class AutocompleteAlgoliaInstitutionsOnlySearchComponent extends BaseWidget implements OnInit {

  @ViewChild('query') inputElement: ElementRef;

  @Input() emphasized: boolean;
  @Input() label: string = 'Bank Institut';
  @Input() clearIconTooltip: string = 'Zurücksetzen';
  @Input() showHint: boolean = true;
  @Input() hintText: string = 'Unser System ist nahtlos mit den meisten deutschen Banken integriert';
  @Input() errorText: string = 'Bitte wählen Sie aus der Liste Ihre Bank aus.';

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onQuerySuggestionClick = new EventEmitter<Institution | null>();
  @Output() focus = new EventEmitter<void>();

  selectedInstitution: Institution;

  state: {
    query: string;
    refine: Function;
    indices: object[];
  };

  ngOnInit() {
    this.createWidget(connectAutocomplete, {});
    super.ngOnInit();
  }

  control: FormControl;
  disabled = false;

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch) {
    super('AutocompleteComponent');
    this.onQuerySuggestionClick.subscribe((value) => console.log('onQuerySuggestionClick --> ', value));
  }

  handleChange($event: KeyboardEvent) {
    this.state.refine(($event.target as HTMLInputElement).value);
  }

  trackByFn(index: number, landlord: Landlord) {
    return landlord.id; // or item.id
  }

  clear() {
    this.selectedInstitution = null;
  }

  select(option: Institution) {
    this.selectedInstitution = option;
    this.onQuerySuggestionClick.emit(option);
  }
}
