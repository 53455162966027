import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-pdf-loader',
    templateUrl: './pdf-loader.component.html',
    styleUrls: ['./pdf-loader.component.scss']
})
export class PdfLoaderComponent implements OnInit, OnChanges {

    @Input() url: string;

    loading = true;

    constructor(private cf: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.url?.currentValue !== changes?.url?.previousValue) {
            this.loading = true;
            this.url = changes?.url?.currentValue;
        }
    }

    pageRendered(e: CustomEvent): void {
        console.log('(page-rendered)', e);
        this.loading = false;
        this.cf.markForCheck();
    }

}
