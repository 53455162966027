import {Activity} from '@activities/models/activities.types';
import {Clipboard} from '@angular/cdk/clipboard';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';

import {UntypedFormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatDrawerToggleResult} from '@angular/material/sidenav';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {FuseConfirmationService} from '@fuse/services/confirmation';
import {isEqual} from 'lodash-es';
import {Subject} from 'rxjs';
import {filter, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {DunningListComponent} from "../dunning-list/dunning-list.component";
import {DunningsModularService} from "../../services/dunnings-modular.service";
import {DunningsActivitiesService} from "../../services/dunnings-activities.service";
import {Dunning, DunningTypeOptions, LastWarningTypeOptions} from "../../models/dunning.interface";
import {docData} from "@angular/fire/firestore";
import {
    NextDunningTypeHintDialogComponent
} from "../next-dunning-type-hint-dialog/next-dunning-type-hint-dialog.component";
import {DunningNotesDialogComponent} from "../notes-details/dunning-notes-dialog.component";
import {BookingService} from "../../../../services/booking.service";
import {Booking, BookingStatusOptions} from "../../../../models/booking";
import {determineDunningStatus, generatePaymentStatusActivities} from "../../helpers";


@Component({
    selector: 'transactions-details',
    templateUrl: './dunning-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    providers: [BookingService]
})
export class DunningDetailsComponent implements OnInit, OnDestroy {

    dunning: Dunning;
    dunningTypeOptions = DunningTypeOptions;
    lastWarningTypeOptions = LastWarningTypeOptions;
    bookingStatusOptions = BookingStatusOptions;

    editMode: boolean = false;
    tagsEditMode: boolean = false;

    userDisplayName: string;

    today = new Date();

    // async actions
    loading: boolean;
    deleting: boolean;
    checkingBookings: boolean;

    appTheme: string;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private clipboard: Clipboard,
        private snackbar: MatSnackBar,
        private cf: ChangeDetectorRef,
        private dunningListComponent: DunningListComponent,
        private formBuilder: UntypedFormBuilder,
        private bookingsService: BookingService,
        private dunningsModularService: DunningsModularService,
        private dunningsActivitiesService: DunningsActivitiesService,
        private fuseConfirmationService: FuseConfirmationService) {
        console.log('on new TransactionsDetailsComponent constructor');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.userDisplayName = this.dunningsModularService?.authService?.authUser?.displayName || this.dunningsModularService?.authService?.firestoreUser?.displayName;
        console.log('ngOnInit --> DunningDetailsComponent', this.route.snapshot?.data?.dunning);

        // Open the drawer
        this.dunningListComponent.drawer.open();

        this.appTheme = this.dunningListComponent.appTheme;

        if (this.route.snapshot?.data?.dunning) {
            this.dunning = this.route.snapshot?.data?.dunning;
            const propertyID: string = this.dunning?.propertyID;
            const unitID: string = this.dunning?.unitID;
            const periodID: string = this.dunning?.periodID;
            this.bookingsService.setParentPath(propertyID, unitID, periodID);
            this.dunningsModularService.setParentPathForPeriod(propertyID, unitID, periodID);
            this.dunningsActivitiesService.setParentPathForPeriod(propertyID, unitID, periodID, this.dunning?.id);
        }

        this.getDunningDocChanges();

        if (!this.dunningsModularService.selected) {
            this.dunningsModularService.onSelectedChanged$.next(this.dunning);
        }


        this.cf.markForCheck();

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        console.log('ngOnDestroy --> DunningDetailsComponent');
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Close the drawer
     */
    closeDrawer(): Promise<MatDrawerToggleResult> {
        this.cf.markForCheck();
        return this.dunningListComponent.drawer.close();
    }


    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item?.id || index;
    }

    addActivity(activity: Activity): void {
        console.log('addActivity', activity, this.dunning?.id);
        this.dunningsActivitiesService.collection()
            .add(activity)
            .then((res) => console.log('activity has been added', res))
            .catch((err) => console.error('Error', err));
    }


    getDunningDocChanges(): void {
        console.log('getTransactionDocChanges');
        this.dunningsModularService.onSelectedChanged$.pipe(
            takeUntil(this._unsubscribeAll),
            filter((dunning) => !!dunning),
            tap(() => {
                console.log('loading new transaction ...');
                this.loading = true;
                // this.cf.markForCheck();
            }),
            switchMap((dunning: Dunning) => {
                    console.log('dunning on switch', dunning);
                    const propertyID: string = dunning?.propertyID;
                    const unitID: string = dunning?.unitID;
                    const periodID: string = dunning?.periodID;
                this.bookingsService.setParentPath(propertyID, unitID, periodID);
                    this.dunningsModularService.setParentPathForPeriod(propertyID, unitID, periodID);
                    this.dunningsActivitiesService.setParentPathForPeriod(propertyID, unitID, periodID, dunning?.id);


                    // const ref = this.dunningsModularService.doc(dunning?.id);
                    // return docData(ref, { idField: 'id' }).pipe(takeUntil(this._unsubscribeAll), debounceTime(50));
                    return docData(this.dunningsModularService.doc(dunning.id), {idField: 'id'}).pipe(takeUntil(this._unsubscribeAll));
                }
            )
            // share()
        )
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap(() => {
                    console.log('switching new dunning ...');
                }))
            .subscribe((data) => {
                // this.msa = data;
                console.log(isEqual(this.dunning, data));
                this.dunning = data;
                console.log('dunning received', this.dunning);
                this.loading = false;
                this.cf.markForCheck();
                // this.createOperationsFormGroup();
            });
    }

    /**
     * Add a new note
     */
    addNewNote(): void {
        this.dialog.open(DunningNotesDialogComponent, {
            autoFocus: false,
            data: {
                dunning: this.dunning,
                user: this.dunningsModularService?.authService?.authUser,
                note: {}
            }
        });
    }

    copy(value: string) {
        this.clipboard.copy(value);
        this.snackbar.open(`${value} wurde kopiert!`, 'OK', {duration: 5000});
    }

    openNextStageHintDialog(): void {
        this.dialog.open(NextDunningTypeHintDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data: this.dunning,
            panelClass: 'fuse-confirmation-dialog-panel',
            maxHeight: '90vh'
        });
    }

    requestDelete(): void {
        this.dunningListComponent.openDeleteDunningDialog(this.dunning);
    }

    openPDF() {
        this.dunningListComponent.getPdf(this.dunning);
    }

    checkBookingsState(): void {
        if (!(this.dunning?.relatedBookings?.length > 0)) {
            return
        }
        this.checkingBookings = true;
        this.cf.markForCheck();

        const bookingsID = this.dunning.relatedBookings.map(b => b.id);
        this.bookingsService.collection(ref => ref.where('id', 'in', bookingsID))
            .get()
            .pipe(take(1))
            .subscribe((result) => {
                console.log("result of checkBookingsState --> ", result.size);
                if (result?.size > 0) {
                    const bookings = result?.docs.map(d => d.data() as Booking);
                    console.log("loaded bookings = ", bookings);
                    const dunningToUpdate: Partial<Dunning> = {
                        relatedBookings: bookings,
                        status: determineDunningStatus(bookings)
                    }

                    const firestoreOps: Promise<any> [] = [];
                    const paymentsActivities = generatePaymentStatusActivities(this.dunning?.relatedBookings, bookings);
                    // this.dunningsActivitiesService.collection().add(activity)

                    if (paymentsActivities?.length > 0) {
                        console.log("paymentsActivities --> ", paymentsActivities)
                        paymentsActivities.forEach(activity => firestoreOps.push(this.dunningsActivitiesService.collection().add(activity)))
                    }

                    firestoreOps.push(this.dunningsModularService.update(this.dunning?.id, dunningToUpdate));

                    Promise.all(firestoreOps)
                        .then(() => {
                            this.snackbar.open('Zahlungsstatus der offenen Posten erfolgreich überprüft', 'Schließen', {
                                duration: 5000,
                                panelClass: ['snackbar-success']
                            });
                        }).catch((err) => {
                        this.snackbar.open('Fehler bei der Überprüfung des Zahlungsstatus', 'Schließen', {
                            duration: 5000,
                            panelClass: ['snackbar-error']
                        });
                    }).finally(() => {
                        this.checkingBookings = false;
                        this.cf.markForCheck();
                    })
                } else {
                    this.checkingBookings = false;
                    this.cf.markForCheck();
                }
            })
    }
}
