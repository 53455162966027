import {ClipboardModule} from '@angular/cdk/clipboard';
import {CommonModule, CurrencyPipe, DatePipe, DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {NahausAlgoliaSharedModule} from '@shared/algolia/nahaus-algolia-shared.module';
import {AngularDropzoneModule} from '@shared/angular-dropzone/angular-dropzone.module';
import {ShowImgDialogComponent} from '@shared/components/show-img-dialog/show-img-dialog.component';
import {DisableControlModule} from '@shared/directives/disable-control/disable-control.module';
import {getSaver, SAVER} from '@shared/download/saver.provide';
import {AngularSvgIconModule} from 'angular-svg-icon';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
// import and register filepond file type validation plugin
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilepondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilepondPluginImagePreview from 'filepond-plugin-image-preview';
import {CountUpModule} from 'ngx-countup';
import {FilePondModule, registerPlugin} from 'ngx-filepond';
import {ImgFallbackModule} from 'ngx-img-fallback';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {ChipListEmailInputComponent} from './components/chip-list-email-input/chip-list-email-input.component';
import {NahausMainBaseComponent} from './components/nahaus-main-base/nahaus-main-base.component';
import {MouseHoverDirective} from './directives/mouse-hover.directive';
import {ShowImageDialogDirective} from './directives/show-image-dialog.directive';
import {NahausSharedPipesModule} from './pipes/nahaus-shared-pipes.module';
import {ScrollableDirective} from './directives/scrollable.directive';
import {MatStepperModule} from "@angular/material/stepper";
import {MatVerticalStepperScrollerDirective} from "@shared/directives/mat-vertical-stepper-scroller.directive";
import {
    NahausImagesPreviewDialogComponent
} from "@shared/components/files-preview/nahaus-images-preview-dialog.component";
import {PdfLoaderComponent} from "@shared/components/files-preview/pdf-loader/pdf-loader.component";
import {ImageLoaderComponent} from "@shared/components/files-preview/image-loader/image-loader.component";
import {HttpClientModule} from "@angular/common/http";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {FuseCardModule} from "@fuse/components/card";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {MatTooltipModule} from "@angular/material/tooltip";

registerPlugin(FilePondPluginFileValidateType, FilepondPluginImageEdit, FilepondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginImageExifOrientation, FilePondPluginFileRename);


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClipboardModule,
        HttpClientModule,
        // NahausAlgoliaSharedModule,
        ReactiveFormsModule,
        DisableControlModule,

        // Material
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatChipsModule,
        MatInputModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatTooltipModule,

        // 3rd Party
        ImgFallbackModule,
        CountUpModule,
        FuseCardModule,
        PdfViewerModule,

        NahausSharedPipesModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ClipboardModule,

        // Nahaus
        NahausSharedPipesModule,
        NahausAlgoliaSharedModule,
        DisableControlModule,
        MouseHoverDirective,
        ShowImageDialogDirective,
        ChipListEmailInputComponent,

        // 3rd Party
        ImgFallbackModule,
        AngularSvgIconModule,
        CountUpModule,
        FilePondModule,
        AngularDropzoneModule,
        NgxWebstorageModule,
        ScrollableDirective,
        MatVerticalStepperScrollerDirective,
    ],
    declarations: [
        MouseHoverDirective,
        NahausMainBaseComponent,
        ShowImgDialogComponent,
        ShowImageDialogDirective,
        ChipListEmailInputComponent,
        ScrollableDirective,
        MatVerticalStepperScrollerDirective,
        NahausImagesPreviewDialogComponent,
        PdfLoaderComponent,
        ImageLoaderComponent,
    ],
    providers: [
        DatePipe,
        CurrencyPipe,
        DecimalPipe,
        {provide: SAVER, useFactory: getSaver}
    ]
})
export class SharedModule {
}
