import {SelectionModel} from '@angular/cdk/collections';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {
    collectionSnapshots,
    getCountFromServer,
    limit,
    orderBy,
    query,
    startAfter,
    startAt
} from '@angular/fire/firestore';
import {PageEvent} from '@angular/material/paginator';
import {MatDrawer} from '@angular/material/sidenav';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {QueryConstraint} from '@firebase/firestore';
import {fuseAnimations} from '@fuse/animations';
import {FuseConfigService} from '@fuse/services/config';
import {FuseLoadingService} from '@fuse/services/loading';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {Transaction} from '@transactions/models/transaction.interface';
import {LocalStorage} from 'ngx-webstorage';
import {QueryDocumentSnapshot} from 'rxfire/firestore/interfaces';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {catchError, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {AppConfig} from "../../../../../../../core/config/app.config";
import {DunningsModularService} from "../../services/dunnings-modular.service";
import {Dunning, DunningTypeOptions, LastWarningTypeOptions} from "../../models/dunning.interface";
import {Property} from "../../../../../properties/models/property.interface";
import {PropertyUnit} from "../../../../../properties/features/units/models/unit.interface";
import {
    PropertyUnitPeriod
} from "../../../../../properties/features/units/features/period/models/property.unit.period.interface";
import {BookingsAccessLayerOptions} from "../../../../booking.routing";
import {DeleteDunningDialogComponent} from "../delete-dunning-dialog/delete-dunning-dialog.component";
import {MatDialog} from '@angular/material/dialog';
import {Hit, InstantSearchConfig} from "angular-instantsearch/instantsearch/instantsearch";
import {environment} from "@env/environment";
import {SearchState} from "@shared/algolia/directives/stat-listener.directive";
import {BookingStatusOptions} from "../../../../models/booking";
import {
    YoutubeDialogComponent,
    YoutubeDialogData,
    YoutubeDialogResult
} from "@shared/youtube-dialog/dialog/youtube-dialog.component";

@Component({
    selector: 'dunning-list',
    templateUrl: './dunning-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class DunningListComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('matDrawer', {static: true}) drawer: MatDrawer;
    @ViewChild(MatSort) _sort: MatSort;

    @LocalStorage('DunningListComponent.defaultPageSize', 25)
    defaultPageSize: number;

    @LocalStorage('DunningListComponent.openYoutube', true)
    openYoutube: boolean;

    drawerMode: 'side' | 'over';

    property: Property;
    unit: PropertyUnit;
    period: PropertyUnitPeriod;
    accessLayer: BookingsAccessLayerOptions;

    dunningTypeOptions = DunningTypeOptions;
    lastWarningTypeOptions = LastWarningTypeOptions;

    // pagination
    firstDocMap: Map<number, any> = new Map<number, any>();
    lastItem: any;
    page: PageEvent;

    // table source
    dataSource: MatTableDataSource<Dunning>;
    hitsSource: MatTableDataSource<Dunning>;

    selection = new SelectionModel<Dunning>(true, []);
    // displayedColumns
    displayedColumns: string[] =
        [
            // 'select',
            'process',
            'id',
            'tenantsName',
            'date',
            'dueDate',
            'status',
            'amountToPayInclFeesAndInterest',
            'buttons',
            // 'detectionStatus'
            // 'target.property'
        ];
    // indicators
    isLoading: boolean = false;

    selected: Dunning | null = null;
    // Filters
    onPageEventChanged$: BehaviorSubject<PageEvent>;
    onSortChanged$: BehaviorSubject<Sort> = new BehaviorSubject<Sort>(null);

    initialLoad: boolean;
    appTheme: string;

    bookingStatusOptions = BookingStatusOptions;

    // Algolia
    config: InstantSearchConfig;
    searchState: SearchState;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseConfigService: FuseConfigService,
        private dunningsModularService: DunningsModularService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog,
        private _fuseLoadingService: FuseLoadingService
    ) {
        this.config = {
            indexName: environment.algolia.indexName.dunnings,
            searchClient: this.dunningsModularService?.authService?.getAlgoliaSearchClient(false),
            routing: true
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.accessLayer = this.route.snapshot?.data?.accessLayer as BookingsAccessLayerOptions || BookingsAccessLayerOptions.PERIOD;
        console.log("this.accessLayer = ", this.accessLayer)
        this.property = this.route.snapshot.data?.property as Property;
        this.unit = this.route.snapshot?.data?.unit as PropertyUnit;
        this.period = this.route.snapshot?.data?.period as PropertyUnitPeriod;

        this.dunningsModularService.setParentPathForPeriod(this.property?.id, this.unit?.id, this.period?.id);

        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {
                console.log('config', config);
                this.appTheme = config?.theme.split('-')[1];
                this._changeDetectorRef.markForCheck();
                this._changeDetectorRef.detectChanges();
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Set the drawerMode if the given breakpoint is active
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                } else {
                    this.drawerMode = 'over';
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this.page = {pageSize: this.defaultPageSize, pageIndex: 0, length: 100};
        this.onPageEventChanged$ = new BehaviorSubject<PageEvent>(this.page);

        this.dunningsModularService
            .onSelectedChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((dunning) => {
                this.selected = dunning;
                console.log('onDunningSelectedChanged', this.selected);
                this._changeDetectorRef.markForCheck();
            });

        this.loadData();
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        // If the user changes the sort order...
        this._sort?.sortChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Reset back to the first page
                // this._paginator.pageIndex = 0;

                // Close the details
                this.closeDetails();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Close the details
     */
    closeDetails(): void {
        this.selected = null;
    }


    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item?.id || index;
    }

    onBackdropClicked(): void {
        // Go back to the list
        this.router.navigate(['./'], {relativeTo: this.route});

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    loadData(): void {
        console.log('loadData()');
        combineLatest(
            [
                this.onPageEventChanged$,
                this.onSortChanged$,
            ]
        ).pipe(
            tap(() => {
                console.log('loading ...');
                this._fuseLoadingService.show();
                this.isLoading = true;
                this._changeDetectorRef.markForCheck();
            }),
            switchMap(([pageEvent, sort]:
                           [PageEvent, Sort]) => {

                const queryConstraintList: QueryConstraint[] = [];
                const limitConstraintList: QueryConstraint[] = [];

                console.table([{
                    'pageEvent': pageEvent,
                    'sort': sort,
                }]);

                if (sort) {
                    // for paginating --> when the list already initialized
                    if (sort?.active === 'createdAt') {

                        queryConstraintList.push(orderBy(sort?.active, sort?.direction === 'asc' ? 'asc' : 'desc'));
                        limitConstraintList.push(limit(pageEvent?.pageSize));
                    } else {
                        queryConstraintList.push(orderBy(sort?.active, sort?.direction === 'asc' ? 'asc' : 'desc'));
                        queryConstraintList.push(orderBy('createdAt', 'desc'));
                        limitConstraintList.push(limit(pageEvent?.pageSize));
                    }
                } else {
                    // for paginating --> when the list already initialized
                    queryConstraintList.push(orderBy('createdAt', 'desc'));
                    limitConstraintList.push(limit(pageEvent?.pageSize));
                }

                // if (status) {
                //     queryConstraintList.push(where('status', '==', status));
                //     // query = query.where('status', '==', status);
                // }


                if (pageEvent.pageIndex < pageEvent.previousPageIndex) {
                    // get previous page
                    console.log('get previous page', this.firstDocMap);
                    if (this.firstDocMap) {
                        limitConstraintList.push(startAt(this.firstDocMap.get(pageEvent.pageIndex)));
                    }
                } else {
                    // get next page
                    console.log('get next page', this.lastItem);
                    if (this.lastItem) {
                        limitConstraintList.push(startAfter(this.lastItem));
                    }
                }


                const collectionQuery = this.dunningsModularService.collection();
                const ref = query(collectionQuery, ...queryConstraintList, ...limitConstraintList);
                const refCount = query(collectionQuery, ...queryConstraintList);

                getCountFromServer(refCount)
                    .then((res) => {
                        const count = res.data().count || 0;
                        console.log('getCountFromServer --> ', res, count);
                        this.page.length = count;
                    }).catch((err) => {
                    console.error('Error in getCountFromServer --> ', err);
                });

                return collectionSnapshots(ref).pipe(takeUntil(this._unsubscribeAll));
            }))
            .pipe(
                takeUntil(this._unsubscribeAll),
                catchError((error: any) => {
                    console.error('Error: ', error);
                    this.router.navigate(['fehler', '404']);
                    return error;
                }))
            .subscribe((data: QueryDocumentSnapshot<Transaction>[]) => {
                console.log('data response', data);
                this._fuseLoadingService.hide();

                if (!this.initialLoad) {
                    this.initialLoad = true;
                }

                if (data.length === 0) {
                    this.openYoutubeDialog();
                    this.dataSource = new MatTableDataSource();
                    this.firstDocMap = new Map<number, any>();
                    this.lastItem = null;
                    this.isLoading = false;
                    this._changeDetectorRef.markForCheck();
                    return;
                }

                const list = [];
                data.forEach((action) => {
                    // list.push(convertTimestamps(action.payload.doc.data()));
                    list.push(action.data());
                });

                this.dataSource = new MatTableDataSource(list);
                this.firstDocMap.set(this.page?.pageIndex ? this.page?.pageIndex : 0, data[0]);
                this.lastItem = data[data.length - 1];

                console.log('loading done', this.dataSource.data);
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            });
    }

    resetFirstAndLastItem(): void {
        this.firstDocMap = new Map<number, any>();
        this.lastItem = null;
    }

    sortMsaData($event: Sort): void {
        console.log('sortData:', $event);
        this.resetFirstAndLastItem();
        this.page = {pageSize: this.page?.pageSize, length: this.page?.length, pageIndex: 0};
        this.onPageEventChanged$.next(this.page);
        this.onSortChanged$.next($event);
    }

    goToDunning(dunning: Dunning): void {
        // this.selectedLandlord = this.lls.landlord = landlord;
        // Get the current activated route
        let route = this.route;
        while (route.firstChild) {
            route = route.firstChild;
        }

        console.log("check this.dunningsModularService?.selected?.id !== dunning?.id", this.dunningsModularService?.selected?.id)
        console.log("check this.dunningsModularService?.selected?.id !== dunning?.id", dunning.id)
        if (this.dunningsModularService?.selected?.id !== dunning?.id) {
            console.log("this.dunningsModularService?.selected?.id !== dunning?.id")
            this.dunningsModularService.selected = dunning;
        }

        // Go to dunning
        this.router.navigate([dunning?.id], {relativeTo: this.route});

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    onPageChanged($event: PageEvent): void {
        if (this.page?.pageSize !== $event?.pageSize) {
            this.resetFirstAndLastItem();
        }
        if (this.defaultPageSize !== $event?.pageSize) {
            this.defaultPageSize = $event.pageSize;
        }
        this.onPageEventChanged$.next($event);
        this.page = $event;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows(): void {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.dataSource.data);
        console.log('selection', this.selection);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Dunning, i?: number): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${i + 1}`;
    }

    getPdf(dunning: Dunning): void {
        if (dunning?.url) {
            window.open(dunning?.url, '_blank');
        }
    }

    openDeleteDunningDialog(dunning: Dunning): void {
        this.dialog.open(DeleteDunningDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data: dunning,
            panelClass: 'fuse-confirmation-dialog-panel',
            maxHeight: '90vh'
        });
    }

    onNewStat($event: SearchState): void {
        this.searchState = $event;
        this._changeDetectorRef.detectChanges();
        console.log('search state = ', this.searchState);
    }

    onNewHits(hits: Hit[] | Dunning[]): void {
        console.log('on new landlords hits', hits);
        // this.landlordHits$.next(hits as Landlord[]);
        this.hitsSource = new MatTableDataSource<Dunning>(hits as Dunning[])
        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
    }

    onNewQuery($event: {
        query: string;
        refine: Function;
        clear: Function;
        isSearchStalled: boolean;
        widgetParams: object
    }) {
        setTimeout(() => {
            this._changeDetectorRef.markForCheck();
            this._changeDetectorRef.detectChanges();
        }, 100)
    }

    openYoutubeDialog(force: boolean = false) {
        if (!this.openYoutube && !force) {
            return;
        }

        const data: YoutubeDialogData = {
            videoID: 'Nil1W2QZrho',
            title: 'Willkommen beim Mahnwesen-Modul',
            subtitle: 'Schauen Sie sich dieses kurze Video an, um zu erfahren, wie Sie Mahnungen und Zahlungserinnerungen effizient erstellen und verwalten können.'
        };

        this.dialog.open(YoutubeDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data,
            panelClass: 'fuse-confirmation-dialog-panel',
            maxHeight: '90vh'
        }).afterClosed().pipe(take(1)).subscribe((res: YoutubeDialogResult) => {
            if (res === 'IGNORE') {
                this.openYoutube = false;
            }

            if (res === 'CONTINUE') {
                this.router.navigate(['/immobilien', this.property?.id, 'einheit', this.unit?.id, 'buchungen', this.period?.id, 'mahnwesen', 'erstellen'])
            }
        });
    }
}
