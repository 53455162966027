import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConfig, Theme } from '../../core/config/app.config';

@Pipe({
  name: 'appTheme'
})
export class AppThemePipe implements PipeTransform, OnDestroy {

  config: AppConfig;
  theme: Theme;

  // RXJS
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private _fuseConfigService: FuseConfigService,
              private _changeDetectorRef: ChangeDetectorRef) {
    this._fuseConfigService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: AppConfig) => {
        console.log('config', config);
        this.config = config;
        this.theme = config?.theme;
        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  transform(src: string): Theme {
    // return this.theme;
    console.log('split', this.theme.split('-')[1]);
    return src + this.theme.split('-')[1];
  }

}
