import {Pipe, PipeTransform} from '@angular/core';
import {PublicProfileSettings} from "@settings/models/publicProfile.settings.interface";

@Pipe({
    name: 'publicProfileName'
})
export class PublicProfileNamePipe implements PipeTransform {

    transform(value: PublicProfileSettings): string {
        if (!value) {
            return 'Nicht Verfügbar';
        } else if (value?.companyName) {
            return `${value?.companyName} ${value?.companyComplement || ''}`
        } else {
            return `${value?.firstName || ''} ${value?.lastName || ''}`
        }
    }

}
