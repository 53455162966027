import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {Subject, takeUntil} from 'rxjs';
import {ActivatedRoute} from "@angular/router";
import {MatDrawer} from "@angular/material/sidenav";
import {BookingsAccessLayerOptions} from "../../booking.routing";

@Component({
    selector: 'carded-left-sidebar-2-normal-scroll',
    templateUrl: './bookings-main.component.html',
    encapsulation: ViewEncapsulation.None,
    // standalone   : true,
    // imports      : [MatIconModule, RouterLink, MatButtonModule, MatSidenavModule, DemoSidebarComponent],
})
export class BookingsMainComponent implements OnInit, OnDestroy {

    @ViewChild('matDrawer', {static: true}) drawer: MatDrawer;

    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;

    accessLayer: BookingsAccessLayerOptions;
    onlyDeposit: boolean;
    onlyUnpaid: boolean;
    bookingsAccessLayerOptions = BookingsAccessLayerOptions;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(private _fuseMediaWatcherService: FuseMediaWatcherService,
                private route: ActivatedRoute) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        console.log("BookingsMainComponent --> ", this.route.parent?.parent?.parent?.snapshot?.data)
        console.log("BookingsMainComponent --> ", this.route.parent?.parent?.snapshot?.data)
        console.log("BookingsMainComponent --> ", this.route.parent?.snapshot?.data)
        console.log("BookingsMainComponent --> ", this.route?.snapshot?.data)
        this.accessLayer = this.route.snapshot?.data?.accessLayer;
        this.onlyDeposit = this.route.snapshot?.data?.onlyDeposit;
        this.onlyUnpaid = this.route.snapshot?.data?.onlyUnpaid;

        if (this.accessLayer === this.bookingsAccessLayerOptions.ACCOUNT) {
            // Subscribe to media changes
            this._fuseMediaWatcherService.onMediaChange$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(({matchingAliases}) => {
                    // Set the drawerMode and drawerOpened if
                    if (matchingAliases.includes('lg')) {
                        this.drawerMode = 'side';
                        this.drawerOpened = true;
                    } else {
                        this.drawerMode = 'over';
                        this.drawerOpened = false;
                    }
                });
        } else {
            this.drawerMode = 'over';
            this.drawerOpened = false;
        }


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
