import {GermanAddress} from '@angular-material-extensions/google-maps-autocomplete';
import {Email, Person, PhoneNumber} from '@shared/models/person.interface';
import firebase from 'firebase/compat';
import Timestamp = firebase.firestore.Timestamp;


/**
 * @author Anthony Nahas
 * @since 12.19
 * @updated 26.09.22
 * @version 1.3
 */
export interface Landlord extends Person {
  type?: LandlordType;
  companyName?: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  emails?: Email[];
  phoneNumbers?: PhoneNumber[];
  address?: GermanAddress;
  notes?: string;
  // Algolia
  _highlightResult?: any;
  landlordID?: number;
}

export type Title = 'M' | 'F' | 'NO_INPUT';

export enum LandlordTypeOptions {
  PRIVATE = 'private',
  COMMERCIAL = 'commercial',
}

export type LandlordType = LandlordTypeOptions.PRIVATE | LandlordTypeOptions.COMMERCIAL

export type Landlords = Landlord[];


