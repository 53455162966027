import {Pipe, PipeTransform} from '@angular/core';
import {Booking} from "../models/booking";
import {Timestamp} from "firebase/firestore";
import {DateTime} from "luxon";

@Pipe({
    name: 'canCreateDunning'
})
export class CanCreateDunningPipe implements PipeTransform {

    transform(booking: Booking): boolean {
        const today = new Date();
        const dueDate: Date = (booking?.date as Timestamp)?.toDate();
        const maxDueDate = DateTime.fromJSDate(dueDate).plus({day: 1}).startOf("day").toJSDate();
        return today > maxDueDate && booking?.saldo < 0;
    }

}
