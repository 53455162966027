import {NgModule} from '@angular/core';
import {AddressPipe} from './address.pipe';
import {AppThemePipe} from './app-theme.pipe';
import {BoolToStringPipe} from './bool-to-string.pipe';
import {CategorySVGPipe} from './category-svg.pipe';
import {CategoryTypeSVGPipe} from './category-type-svg.pipe';
import {ErrorColorPipe} from './error-color.pipe';
import {FormAutoValidatePipe} from './form-auto-validate.pipe';
import {FormatAddressPipe} from './format-address.pipe';
import {HasCustomClaimPipe} from './has-custom-claim.pipe';
import {HasPermissionPipe} from './has-permission.pipe';
import {HoverDirective} from './hover.directive';
import {IsAdminPipe} from './is-admin.pipe';
import {LandlordNamePipe} from './landlord-name.pipe';
import {PermissionTextPipe} from './permission-text.pipe';
import {ToDatePipe} from './to-date.pipe';
import {ContactNamePipe} from './contact-name.pipe';
import {ToThumbPipe} from './to-thumb.pipe';
import {NahausImageExistsPipe} from './nahaus-image-exists.pipe';
import {PublicProfileNamePipe} from './public-profile-name.pipe';
import {CurrentSchemePipe} from './current-scheme.pipe';
import {MathAbsPipe} from './math-abs.pipe';
import {BookingArtTextPipe} from './booking-art-text.pipe';
import {FormatAddressCSVPipe} from './format-address-csv.pipe';
import {ExpenseTypeTextPipe} from './expense-type-text.pipe';


@NgModule({
    declarations: [
        BoolToStringPipe,
        ToDatePipe,
        ErrorColorPipe,
        CategorySVGPipe,
        CategoryTypeSVGPipe,
        FormAutoValidatePipe,
        HoverDirective,
        AppThemePipe,
        FormatAddressPipe,
        HasCustomClaimPipe,
        HasPermissionPipe,
        IsAdminPipe,
        LandlordNamePipe,
        ContactNamePipe,
        AddressPipe,
        PermissionTextPipe,
        ToThumbPipe,
        NahausImageExistsPipe,
        CurrentSchemePipe,
        MathAbsPipe,
        NahausImageExistsPipe,
        PublicProfileNamePipe,
        BookingArtTextPipe,
        FormatAddressCSVPipe,
        ExpenseTypeTextPipe
    ],
    exports: [
        BoolToStringPipe,
        ToDatePipe,
        ErrorColorPipe,
        CategorySVGPipe,
        CategoryTypeSVGPipe,
        FormAutoValidatePipe,
        AppThemePipe,
        FormatAddressPipe,
        HasCustomClaimPipe,
        HasPermissionPipe,
        IsAdminPipe,
        LandlordNamePipe,
        ContactNamePipe,
        AddressPipe,
        PermissionTextPipe,
        ToThumbPipe,
        NahausImageExistsPipe,
        PublicProfileNamePipe,
        NahausImageExistsPipe,
        CurrentSchemePipe,
        MathAbsPipe,
        BookingArtTextPipe,
        FormatAddressCSVPipe,
        ExpenseTypeTextPipe,

    ],
    providers: [
        ToDatePipe
    ]
})
export class NahausSharedPipesModule {
}
