import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'isAdmin'
})
export class IsAdminPipe implements PipeTransform {

  constructor(private auth: AuthService) {
  }

  transform(redirect: boolean = false): Observable<boolean> {
    return this.auth.isAdmin(false);
  }

}
