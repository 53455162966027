import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {Query} from '@angular/fire/compat/firestore';
import {fuseAnimations} from '@fuse/animations';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {debounceTime, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Activity} from "@activities/models/activities.types";
import {Dunning} from "../../models/dunning.interface";
import {DunningsModularService} from "../../services/dunnings-modular.service";
import {DunningsActivitiesService} from "../../services/dunnings-activities.service";

@Component({
    selector: 'dunning-activities',
    templateUrl: './dunning-activities.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class DunningActivitiesComponent implements OnInit, OnDestroy {

    @Input()
    dunning: Dunning;

    loading: boolean;
    activities: Activity[];
    // activities$: Observable<Activity[]>;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(private dunningsActivitiesService: DunningsActivitiesService,
                private dunningsModularService: DunningsModularService,
                private cf: ChangeDetectorRef) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // ------------------------^-----------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the activities
        this.getActivitiesChanges();

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        console.log('ngOnDestroy --> DunningActivitiesComponent');
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getActivitiesChanges(): void {
        console.log('getActivitiesChanges');
        this.dunningsModularService.onSelectedChanged$.pipe(
            takeUntil(this._unsubscribeAll),
            tap(() => {
                console.log('loading new dunning activities ...');
                this.loading = true;
                this.cf.markForCheck();
            }),
            switchMap((dunning: Dunning) => {
                console.log('dunning on switch', dunning);
                this.dunning = dunning;
                const propertyID = this.dunning?.propertyID;
                const unitID = this.dunning?.unitID;
                const periodID = this.dunning?.periodID;
                console.log(`propertyID ${propertyID} / unitID ${unitID} / periodID ${periodID}`);
                this.dunningsActivitiesService.setParentPathForPeriod(propertyID, unitID, periodID, this.dunning?.id);
                    return this.dunningsActivitiesService.collection(ref => {
                        let query: Query = ref;

                        query = query
                            .orderBy('date', 'desc');
                        // .limit(15);

                        return query;
                    }).valueChanges().pipe(takeUntil(this._unsubscribeAll), debounceTime(50));
                }
            )
        )
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap(() => {
                    console.log('switching new dunning ...');
                }))
            .subscribe((data) => {
                this.activities = data;
                console.log('dunning activities received', this.activities);
                this.loading = false;
                this.cf.markForCheck();
            });
    }

    /**
     * Returns whether the given dates are different days
     *
     * @param current
     * @param compare
     */
    isSameDay(current: Date, compare: Date): boolean {
        return moment(current, moment.ISO_8601).isSame(moment(compare, moment.ISO_8601), 'day');
    }

    /**
     * Get the relative format of the given date
     *
     * @param date
     */
    getRelativeFormat(date: Date): string {
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'day').startOf('day');

        // Is today?
        if (moment(date, moment.ISO_8601).isSame(today, 'day')) {
            return 'Heute';
        }

        // Is yesterday?
        if (moment(date, moment.ISO_8601).isSame(yesterday, 'day')) {
            return 'Gestern';
        }

        return moment(date, moment.ISO_8601).fromNow();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item?.id || index;
    }
}
