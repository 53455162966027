import { GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import { Pipe, PipeTransform } from '@angular/core';
import { formatAddressfromCSV } from '../../helpers/address';

@Pipe({
  name: 'formatAddressCSV'
})
export class FormatAddressCSVPipe implements PipeTransform {

  transform(value: GermanAddress): unknown {
    return formatAddressfromCSV(value);
  }

}
