import {Route} from '@angular/router';
import {
    BookingAccountsDashboardComponent
} from './components/booking-accounts-dashboard/booking-accounts-dashboard.component';
import {
    BookingAccountsForPeriodResolverResolver
} from './components/booking-accounts-dashboard/resolvers/booking-accounts-for-period-resolver.resolver';
import {
    BookingPeriodDetailsListComponent
} from './components/booking-period-details-list/booking-period-details-list.component';
import {DunningListComponent} from "./features/dunning/components/dunning-list/dunning-list.component";
import {DunningDetailsComponent} from "./features/dunning/components/dunning-details/dunning-details.component";
import {DunningCreateComponent} from "./features/dunning/components/dunning-create/dunning-create.component";
import {CanDeactivateDunningDetails} from "./features/dunning/guards/dunning-details.guard";
import {DunningsResolver} from "./features/dunning/resolvers/dunnings.resolver";
import {PublicProfileSettingsResolver} from '@settings/resolvers/publicProfile.settings.resolver';
import {LogoSettingsResolver} from '@settings/resolvers/logo.settings.resolver';
import {PermissionOptions} from "@users/models/user.interface";
import {PermissionsGuard} from "../../../guards/permissions.guard";
import {BookingsMainComponent} from "./components/bookings-main/bookings-main.component";
import {BookingBuiltInDateRangeFilterOptions, BookingStatusOptions} from "./models/booking";

export enum BookingsAccessLayerOptions {
    PERIOD = "PERIOD",
    PROPERTY = "PROPERTY",
    ACCOUNT = "ACCOUNT",
}

export type BookingsAccessLayer =
    BookingsAccessLayerOptions.PERIOD
    | BookingsAccessLayerOptions.PROPERTY
    | BookingsAccessLayerOptions.ACCOUNT

export const routes: Route[] = [
    // {
    //     path: '',
    //     component: BookingPeriodDetailsListComponent,
    //     data: {
    //         permission: PermissionOptions.BOOKINGS_READ
    //     },
    //     resolve: {
    //         publicProfile: PublicProfileSettingsResolver,
    //         logo: LogoSettingsResolver
    //     },
    //     canActivate: [PermissionsGuard],
    // },


    {
        path: 'sollstellung',
        component: BookingsMainComponent,
        data: {
            permission: PermissionOptions.BOOKINGS_READ
        },
        resolve: {
            publicProfile: PublicProfileSettingsResolver,
            logo: LogoSettingsResolver
        },
        canActivate: [PermissionsGuard],
        children: [
            {
                path: '',
                redirectTo: 'alle',
                pathMatch: 'full'
            },
            {
                path: '',
                component: BookingPeriodDetailsListComponent,
                data: {
                    // accessLayer: BookingsAccessLayerOptions.ACCOUNT,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'alle',
                component: BookingPeriodDetailsListComponent,
                data: {
                    // accessLayer: BookingsAccessLayerOptions.ACCOUNT,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'bezahlt',
                component: BookingPeriodDetailsListComponent,
                data: {
                    statusFilter: BookingStatusOptions.PAID,
                    // accessLayer: BookingsAccessLayerOptions.ACCOUNT,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'teil_bezahlt',
                component: BookingPeriodDetailsListComponent,
                data: {
                    statusFilter: BookingStatusOptions.PARTIALLY_PAID,
                    // accessLayer: BookingsAccessLayerOptions.ACCOUNT,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'unbezahlt',
                component: BookingPeriodDetailsListComponent,
                data: {
                    statusFilter: BookingStatusOptions.NOT_PAID,
                    // accessLayer: BookingsAccessLayerOptions.ACCOUNT,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'erwartet',
                component: BookingPeriodDetailsListComponent,
                data: {
                    statusFilter: BookingStatusOptions.EXPECTED,
                    // accessLayer: BookingsAccessLayerOptions.ACCOUNT,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'thisMonth',
                component: BookingPeriodDetailsListComponent,
                data: {
                    dateFilter: BookingBuiltInDateRangeFilterOptions.THIS_MONTH,
                    // accessLayer: BookingsAccessLayerOptions.ACCOUNT,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'lastMonth',
                component: BookingPeriodDetailsListComponent,
                data: {
                    dateFilter: BookingBuiltInDateRangeFilterOptions.LAST_MONTH,
                    // accessLayer: BookingsAccessLayerOptions.ACCOUNT,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
        ]
    },
    {
        path: 'op',
        component: BookingsMainComponent,
        data: {
            onlyUnpaid: true,
            permission: PermissionOptions.BOOKINGS_READ
        },
        resolve: {
            publicProfile: PublicProfileSettingsResolver,
            logo: LogoSettingsResolver
        },
        canActivate: [PermissionsGuard],
        children: [
            {
                path: '',
                redirectTo: 'alle',
                pathMatch: 'full'
            },
            {
                path: '',
                component: BookingPeriodDetailsListComponent,
                data: {
                    onlyUnpaid: true,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'alle',
                component: BookingPeriodDetailsListComponent,
                data: {
                    // onlyUnpaid: true,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'unbezahlt',
                component: BookingPeriodDetailsListComponent,
                data: {
                    statusFilter: BookingStatusOptions.NOT_PAID,
                    // onlyUnpaid: true,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'erwartet',
                component: BookingPeriodDetailsListComponent,
                data: {
                    statusFilter: BookingStatusOptions.EXPECTED,
                    // onlyUnpaid: true,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'thisMonth',
                component: BookingPeriodDetailsListComponent,
                data: {
                    dateFilter: BookingBuiltInDateRangeFilterOptions.THIS_MONTH,
                    // accessLayer: BookingsAccessLayerOptions.ACCOUNT,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'lastMonth',
                component: BookingPeriodDetailsListComponent,
                data: {
                    dateFilter: BookingBuiltInDateRangeFilterOptions.LAST_MONTH,
                    // accessLayer: BookingsAccessLayerOptions.ACCOUNT,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
        ]
    },

    {
        path: 'kaution',
        component: BookingsMainComponent,
        data: {
            onlyDeposit: true,
            permission: PermissionOptions.BOOKINGS_READ
        },
        resolve: {
            publicProfile: PublicProfileSettingsResolver,
            logo: LogoSettingsResolver
        },
        canActivate: [PermissionsGuard],
        children: [
            {
                path: '',
                redirectTo: 'alle',
                pathMatch: 'full'
            },
            {
                path: '',
                component: BookingPeriodDetailsListComponent,
                data: {
                    onlyDeposit: true,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'alle',
                component: BookingPeriodDetailsListComponent,
                data: {
                    onlyDeposit: true,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'bezahlt',
                component: BookingPeriodDetailsListComponent,
                data: {
                    statusFilter: BookingStatusOptions.PAID,
                    onlyDeposit: true,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'teil_bezahlt',
                component: BookingPeriodDetailsListComponent,
                data: {
                    statusFilter: BookingStatusOptions.PARTIALLY_PAID,
                    onlyDeposit: true,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'unbezahlt',
                component: BookingPeriodDetailsListComponent,
                data: {
                    statusFilter: BookingStatusOptions.NOT_PAID,
                    onlyDeposit: true,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'erwartet',
                component: BookingPeriodDetailsListComponent,
                data: {
                    statusFilter: BookingStatusOptions.EXPECTED,
                    onlyDeposit: true,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'thisMonth',
                component: BookingPeriodDetailsListComponent,
                data: {
                    dateFilter: BookingBuiltInDateRangeFilterOptions.THIS_MONTH,
                    // accessLayer: BookingsAccessLayerOptions.ACCOUNT,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'lastMonth',
                component: BookingPeriodDetailsListComponent,
                data: {
                    dateFilter: BookingBuiltInDateRangeFilterOptions.LAST_MONTH,
                    // accessLayer: BookingsAccessLayerOptions.ACCOUNT,
                    permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
        ]
    },
    // {
    //   path: 'details',
    //   component: BookingPeriodDetailsListComponent,
    //   resolve: {
    //     property: PropertyByIdFromParentDeep2Resolver,
    //     unit: UnitsResolverDeep2Resolver,
    //     period: PeriodByIDDeep2Resolver
    //   }
    // },
    // {
    //   path: 'kaution/details',
    //   component: BookingPeriodDetailsListComponent,
    //   resolve: {
    //     property: PropertyByIdFromParentDeep2Resolver,
    //     unit: UnitsResolverDeep2Resolver,
    //     period: PeriodByIDDeep2Resolver
    //   },
    //   data: {
    //     onlyDeposit: true
    //   }
    // },

    {
        path: 'mahnwesen/erstellen',
        component: DunningCreateComponent,
        resolve: {
            publicProfile: PublicProfileSettingsResolver,
            logo: LogoSettingsResolver
        },
        data: {
            permission: PermissionOptions.BOOKINGS_WRITE
        },
        canActivate: [PermissionsGuard],
    },
    {
        path: 'mahnwesen',
        component: DunningListComponent,
        data: {
            permission: PermissionOptions.BOOKINGS_READ
        },
        canActivateChild: [PermissionsGuard],
        children: [
            {
                path: ':dunningID',
                component: DunningDetailsComponent,
                canDeactivate: [CanDeactivateDunningDetails],
                resolve: {
                    dunning: DunningsResolver
                },
            }
        ]
    },
    // {
    //     path: 'kaution',
    //     component: BookingPeriodDetailsListComponent,
    //     data: {
    //         onlyDeposit: true,
    //         permission: PermissionOptions.BOOKINGS_READ
    //     },
    //     canActivate: [PermissionsGuard],
    // },
    {
        path: 'buchungskonten',
        component: BookingAccountsDashboardComponent,
        resolve: {
            bookingAccounts: BookingAccountsForPeriodResolverResolver
        },
        data: {
            permission: PermissionOptions.BOOKINGS_READ
        },
        canActivate: [PermissionsGuard],
    }
];
