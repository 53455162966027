import {Pipe, PipeTransform} from '@angular/core';
import {convertTimestamp} from 'app/helpers';
import firebase from 'firebase/compat';
import Timestamp = firebase.firestore.Timestamp;

@Pipe({
    name: 'toDate',
})
export class ToDatePipe implements PipeTransform {
  transform(value: Date | Timestamp): Date {
    // @ts-ignore
    const d = convertTimestamp(value);
    // console.log('d', d);
    // @ts-ignore
    return d;
  }
}
