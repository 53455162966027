import {PublicProfileSettings} from '@settings/models/publicProfile.settings.interface';
import {Landlord} from '../../landlords/models';
import {Manager} from "../../managers/models";
import {CustomerLogo} from "@settings/models/logo.settings.interface";

export const getPublicProfileData = (publicProfile: PublicProfileSettings | null, landlord: Landlord, manager?: Manager) => {
    if (!!publicProfile) {
        return publicProfile;
    } else if (!!manager?.id) {
        const publicProfileFromLandlord: PublicProfileSettings = {
            id: manager.id,
            companyName: manager?.companyName,
            // companyComplement: 'Unternehemen',
            firstName: manager?.firstName,
            lastName: manager?.lastName,
            address: manager?.address,
            sender: manager?.companyName ? manager?.companyName : `${manager?.firstName} ${manager?.lastName}`
        };
        return publicProfileFromLandlord;
    } else {
        const publicProfileFromLandlord: PublicProfileSettings = {
            id: landlord?.id,
            companyName: landlord?.companyName,
            // companyComplement: 'Unternehemen',
            firstName: landlord?.firstName,
            lastName: landlord?.lastName,
            address: landlord?.address,
            sender: landlord?.companyName ? landlord?.companyName : `${landlord?.firstName} ${landlord?.lastName}`
        };
        return publicProfileFromLandlord;
    }
};

export const getPublicProfileDataWithLogo = (publicProfile: PublicProfileSettings | null, landlord: Landlord, manager: Manager, logo: CustomerLogo) => {
    if (!!publicProfile) {
        publicProfile.logo = logo;
        return publicProfile;
    } else if (!!manager?.id) {
        const publicProfileFromLandlord: PublicProfileSettings = {
            logo,
            id: manager.id,
            companyName: manager?.companyName,
            // companyComplement: 'Unternehemen',
            firstName: manager?.firstName,
            lastName: manager?.lastName,
            address: manager?.address,
            sender: manager?.companyName ? manager?.companyName : `${manager?.firstName} ${manager?.lastName}`
        };
        return publicProfileFromLandlord;
    } else {
        const publicProfileFromLandlord: PublicProfileSettings = {
            logo,
            id: landlord?.id,
            companyName: landlord?.companyName,
            // companyComplement: 'Unternehemen',
            firstName: landlord?.firstName,
            lastName: landlord?.lastName,
            address: landlord?.address,
            sender: landlord?.companyName ? landlord?.companyName : `${landlord?.firstName} ${landlord?.lastName}`
        };
        return publicProfileFromLandlord;
    }
};
