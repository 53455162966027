import {Timestamp} from 'firebase/firestore';
import {BookingPayment} from "./bookingPayment";
import {GermanAddress} from "@angular-material-extensions/google-maps-autocomplete";
import {Dunning} from "../features/dunning/models/dunning.interface";

/**
 * @author Anthony Nahas
 * @since 20.03.23
 * @updated 01.11.2023
 * @version 1.1
 */
export interface Booking {
    id?: string;
    customerID?: string;
    createdAt?: Timestamp | Date;
    updatedAt?: Timestamp | Date;
    propertyID?: string;
    unitID?: string;
    periodID?: string;
    type?: BookingType; // Einzahlung oder Auszahlung
    // Sollstellung
    date?: Timestamp | Date; // when the tenant should pay - due Date
    amount?: number; // what the tenant should pay
    text?: string; // what kind of payment should the tenant pay

    // difference between haben and soll
    // every day should a schedule function runs and if saldo > 0 --> create a Mahnung doc
    saldo?: number; // habenValue - sollValue = saldo --> this would be calculated every time via cloud function
    haben?: number; // what the tenant paid
    art?: BookingArt;
    status?: BookingStatus;
    paid?: boolean; // whether its paid or not

    // properties related to match transaction
    h_id?: number;
    w_id?: number;
    unitLabel?: string;
    address?: GermanAddress;
    tenantsName?: string; // Name of the tenants seperated with comma
    paymentReference?: string; // this is needed to match a transaction with a booking

    payments?: Array<Partial<BookingPayment>>;
    markAsPaid?: boolean; // on create booking --> add automatically payment when this field is true

    lastDunning?: Partial<Dunning>;
}

export type Bookings = Booking[];

export enum BookingTypeOptions {
    PAYMENT = 'PAYMENT',
    PAYOUT = 'PAYOUT',
}

export type BookingType = BookingTypeOptions.PAYMENT | BookingTypeOptions.PAYOUT

export enum BookingArtOptions {
    WARM_MIETE = 'WARM_MIETE',
    KALT_MIETE = 'KALT_MIETE',
    KAUTION = 'KAUTION',
    BK = 'BETRIEBSKOSTEN', // Betriebskosten
    HK = 'HEIZKOSTEN', // Heizkosten
    DUNNING_FEES = 'DUNNING_FEES', // Mahngebühren
    SP = 'SP', // Sonderposten
    CUSTOM = 'CUSTOM',
    GUTHABEN = 'GUTHABEN', // credits
    INTEREST_EXPENSES = 'INTEREST_EXPENSES', // Zinsaufwendungen für kurzfristige Verbindlichkeiten - Sie tragen die Nummern 2110 (SKR 03) beziehungsweise 7310 (SKR 04).
}

export type BookingArt =
    BookingArtOptions.KALT_MIETE
    | BookingArtOptions.WARM_MIETE
    | BookingArtOptions.KAUTION
    | BookingArtOptions.BK
    | BookingArtOptions.HK
    | BookingArtOptions.DUNNING_FEES
    | BookingArtOptions.SP
    | BookingArtOptions.CUSTOM
    | BookingArtOptions.GUTHABEN
    | BookingArtOptions.INTEREST_EXPENSES

export enum BookingStatusOptions {
    PAID = 'PAID',
    NOT_PAID = 'NOT_PAID',
    PARTIALLY_PAID = 'PARTIALLY_PAID',
    EXPECTED = 'EXPECTED',
}

export type BookingStatus =
    BookingStatusOptions.PAID
    | BookingStatusOptions.NOT_PAID
    | BookingStatusOptions.PARTIALLY_PAID
    | BookingStatusOptions.EXPECTED;

export enum BookingBuiltInDateRangeFilterOptions {
    THIS_MONTH = "THIS_MONTH",
    LAST_MONTH = "LAST_MONTH"
}
