import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  pdfMake: any;

  async loadPdfMaker(): Promise<any> {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.pdfMake.vfs;
    }
  }

  async create(docDef: any): Promise<any> {
    await this.loadPdfMaker();
    return this.pdfMake.createPdf(docDef);
  }

  async open(docDef: any): Promise<any> {
    await this.loadPdfMaker();
    this.pdfMake.createPdf(docDef).open();
  }

  async download(docDef: any, fileName?: string): Promise<any> {
    await this.loadPdfMaker();
    this.pdfMake.createPdf(docDef).download(fileName);
  }

  async print(docDef: any): Promise<any> {
    await this.loadPdfMaker();
    this.pdfMake.createPdf(docDef).print();
  }

  async getBuffer(docDef: any): Promise<any> {
    await this.loadPdfMaker();
    const pdfDocGenerator = await this.create(docDef);
    return new Promise((resolve, reject) => {
      pdfDocGenerator.getBuffer((buffer) => {
        return resolve(buffer);
      });
    });
  }

}
