// import { canActivate, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import {emailVerified, redirectLoggedInTo, redirectUnauthorizedTo} from '@angular/fire/auth-guard';
import {AuthPipe} from '@angular/fire/auth-guard/auth-guard';
import {canActivate, hasCustomClaim} from '@angular/fire/compat/auth-guard';
import {Route} from '@angular/router';
import {ComingSoonGuard} from '@coming-soon/guards/coming-soon.guard';
import {environment} from '@env/environment';
import {CanAccessHandoversGuard} from '@handover/guards/can-access-handovers.guard';
import {CanAccessMetersGuard} from '@meters/guards/can-access-meters.guard';
import {CanAccessMsaGuard} from '@msa/guards/can-access-msa.guard';
import {BannedToUpgradeGuard} from '@quota/guards/banned-to-upgrade.guard';
import {IsSubscriberCustomerGuard} from '@shared/guards/is-subscriber-customer.guard';
import {CanAccessUsersGuard} from '@users/guards/can-access-users.guard';
import {CanAccessVacanciesGuard} from '@vacancies/guards/can-access-vacancies.guard';
import {InitialDataResolver} from 'app/app.resolvers';
import {LayoutComponent} from 'app/layout/layout.component';
import {pipe} from 'rxjs';
import {map} from 'rxjs/operators';
import {IsMasterUserGuard} from './guards/is-master-user.guard';
import {PermissionsGuard} from './guards/permissions.guard';
import {CanAccessContractsGuard} from './modules/admin/contracts/guards/can-access-contracts.guard';
import {CanAccessLettersGuard} from './modules/admin/letters/guards/can-access-letters.guard';
import {PermissionOptions} from './modules/admin/users/models/user.interface';
import {BookingsAccessLayerOptions} from "./modules/admin/booking/booking.routing";

// const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToLogin = (): AuthPipe => redirectUnauthorizedTo(['anmelden']);
const redirectUnauthorizedTo404 = (): AuthPipe => redirectUnauthorizedTo(['fehler/404']);
const redirectLoggedInToMainApp = (): AuthPipe => redirectLoggedInTo(['immobilien']);
const redirectUnverifiedTo = (redirect: any[]): any => pipe(emailVerified, map(emailVerified => emailVerified || !environment.production || redirect));
const redirectUnverifiedEmailsToConfirm = (): AuthPipe => redirectUnverifiedTo(['bestaetigung-erforderlich']);
const canAccessMsa = (next) => hasCustomClaim(PermissionOptions.MSA_READ + 2);
// const isEmailVerified = () => emailVerified();
// const adminOnly = () => hasCustomClaim('admin');

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

  // Redirect empty path to '/dashboards/project'
  { path: '', pathMatch: 'full', redirectTo: 'dashboards' },

  // Redirect signed in user to the '/dashboards/project'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboards' },

  // Auth routes for guests
  {
    path: '',
    // canActivate: [NoAuthGuard],
    // canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'bestaetigung-erforderlich',
        loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule),
      },
      {
        path: 'referral-sign-in',
        loadChildren: () => import('app/modules/auth/referral-sign-in/referral-sign-in.module').then(m => m.ReferralSignInModule),
        ...canActivate(redirectLoggedInToMainApp),
      },
      {
        path: 'anmelden',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule),
        ...canActivate(redirectLoggedInToMainApp),
      },
      {
        path: 'abmelden',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule),
      },
      {
        path: 'registrieren',
        loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule),
        // data: {
        //   // This 👇 will be used as the page seo data
        //   NgaoxSeo: {
        //     title: 'Registrieren Sie sich jetzt bei Nahaus für eine effektive Immobilienverwaltung',
        //     description: 'Nahaus Immobilienverwaltung Software bietet eine leistungsstarke und sichere Immobiliencloud-Lösung. Verwalten Sie Ihre Immobilien einfach und effizient online und behalten Sie stets den Überblick. Registrieren Sie sich jetzt kostenlos!',
        //     siteName: 'Nahaus.de'
        //   }
        // }
      },
      {
        path: 'unlock-session',
        loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule),
      },
      {
        path: 'willkommen',
        loadChildren: () => import('app/modules/auth/welcome/welcome.module').then(m => m.WelcomeModule),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      // {
      //   path: 'home',
      //   loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule),
      //   canLoad: [ComingSoonGuard]
      // },
      // Pages without authentication
      {
        path: 'konto-geloescht',
        loadChildren: () => import('app/modules/admin/pages/account-deleted/account-deleted.module').then(m => m.AccountDeletedModule),
      },
      {
        path: 'coming-soon',
        loadChildren: () => import('app/modules/admin/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule)
      },
    ]
  },

  // Admin routes
  {
    path: '',
    canActivate: [ComingSoonGuard, BannedToUpgradeGuard],
    ...canActivate(redirectUnauthorizedToLogin),
    // ...canActivate(redirectUnverifiedEmailsToConfirm),
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver
    },
    children: [
      // SETTINGS
      {
        path: 'einstellungen',
        loadChildren: () => import('app/modules/admin/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'abonnement',
        loadChildren: () => import('app/modules/admin/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
        canLoad :[IsMasterUserGuard],
        canActivate :[IsMasterUserGuard],
      },
      // CORE FEATURES
      {
        path: 'dashboards',
        loadChildren: () => import('app/modules/admin/dashboards/project/project.module').then(m => m.ProjectModule),
        canLoad:[BannedToUpgradeGuard]
        // canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
      },
      {
        path: 'immobilien',
        loadChildren: () => import('app/modules/admin/properties/properties.module').then(m => m.PropertiesModule),
        canLoad: [BannedToUpgradeGuard, IsSubscriberCustomerGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, IsSubscriberCustomerGuard, PermissionsGuard],
        // canActivateChild: [PermissionsGuard],
        data: {
          permission: PermissionOptions.PROPERTIES_READ
        }
      },
      {
        path: 'eigentuemer',
        loadChildren: () => import('app/modules/admin/landlords/landlords.module').then(m => m.LandlordsModule),
        data: {
          permission: PermissionOptions.LANDLORDS_READ
        },
        canLoad: [BannedToUpgradeGuard, IsSubscriberCustomerGuard],
        canActivate: [BannedToUpgradeGuard, IsSubscriberCustomerGuard],
      },
      {
        path: 'verwalter', // managers
        loadChildren: () => import('app/modules/admin/managers/managers.module').then(m => m.ManagersModule),
        canLoad: [BannedToUpgradeGuard, IsSubscriberCustomerGuard], // IsOwnerOrAdminGuard
        canActivate: [BannedToUpgradeGuard, IsSubscriberCustomerGuard], // IsOwnerOrAdminGuard
      },

      // ############# VERMIETUNG #############

      // BASIC FEATURES
      {
        path: 'msa',
        redirectTo: 'mieterselbstauskunft'
      },
      {
        path: 'mieterselbstauskunft',
        loadChildren: () => import('app/modules/admin/msa/msa.module').then(m => m.MsaModule),
        data: { permission: PermissionOptions.MSA_READ },
        canLoad: [BannedToUpgradeGuard, CanAccessMsaGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, CanAccessMsaGuard, PermissionsGuard],
        // data: { permission: PermissionOptions.MSA_READ },
        // canLoad: [NahausReadAccessPermissionGuard]
        // ...canActivate(canAccessMsa)
      },
      {
        path: 'mietersuche',
        loadChildren: () => import('app/modules/admin/tenants/tenants.module').then(m => m.TenantsModule),
        data: { permission: PermissionOptions.TENANTS_READ },
        canLoad: [BannedToUpgradeGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, PermissionsGuard],
      },
      {
        path: 'leerstand',
        loadChildren: () => import('app/modules/admin/vacancies/vacancies.module').then(m => m.VacanciesModule),
        data: { permission: PermissionOptions.VACANCIES_READ },
        canLoad: [BannedToUpgradeGuard, CanAccessVacanciesGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, PermissionsGuard],
      },
      {
        path: 'ein-auszug',
        loadChildren: () => import('app/modules/admin/handover/handover.module').then(m => m.HandoverModule),
        data: { permission: PermissionOptions.PERIODS_READ },
        canLoad: [BannedToUpgradeGuard, CanAccessHandoversGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, PermissionsGuard],
      },
      // PRO FEATURES
      {
        path: 'mietvertraege',
        loadChildren: () => import('app/modules/admin/contracts/contracts.module').then(m => m.ContractsModule),
        data: { permission: PermissionOptions.CONTRACTS_READ },
        canLoad: [BannedToUpgradeGuard, CanAccessContractsGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, PermissionsGuard],
      },
      {
        path: 'anschreiben',
        loadChildren: () => import('app/modules/admin/letters/letters.module').then(m => m.LettersModule),
        canLoad: [BannedToUpgradeGuard, CanAccessLettersGuard],
        canActivate: [BannedToUpgradeGuard, CanAccessLettersGuard],
        // canActivate: [BannedToUpgradeGuard,CanAccessLettersGuard],
      },

      // ############# BUCHHALTUNG #############
      // CORE FEATURES
      {
        path: 'bankkonten',
        loadChildren: () => import('app/modules/admin/bankAccounts/bank-accounts.module').then(m => m.BankAccountsModule),
        data: { permission: PermissionOptions.BANK_ACCOUNTS_READ },
        canLoad: [BannedToUpgradeGuard, IsSubscriberCustomerGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, IsSubscriberCustomerGuard, PermissionsGuard],
      },

      // ############# ADVANCED FEATURES #############

      // ENTERPRISE FEATURES
      {
        path: 'benutzer',
        loadChildren: () => import('app/modules/admin/users/users.module').then(m => m.UsersModule),
        data: { permission: PermissionOptions.USERS_READ },
        canLoad: [BannedToUpgradeGuard, CanAccessUsersGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, CanAccessUsersGuard, PermissionsGuard],
      },
      {
        path: 'zaehler',
        loadChildren: () => import('app/modules/admin/meters/meters.module').then(m => m.MetersModule),
        canLoad: [BannedToUpgradeGuard, CanAccessMetersGuard, PermissionsGuard],
        canActivate:[BannedToUpgradeGuard, CanAccessMetersGuard, PermissionsGuard],
        data: { permission: PermissionOptions.METERS_READ },
      },
      {
        path: 'dienstleistungen',
        loadChildren: () => import('app/modules/admin/serviceProviders/service-providers.module').then(m => m.ServiceProvidersModule),
        // canLoad: [ComingSoonGuard]
      },
      {
        path: 'versicherungen',
        loadChildren: () => import('app/modules/admin/insurance/insurance.module').then(m => m.InsuranceModule),
        // canLoad: [ComingSoonGuard]
      },
      {
        path: 'files',
        loadChildren: () => import('app/modules/admin/file-manager/file-manager.module').then(m => m.FileManagerModule),
        canActivate :[BannedToUpgradeGuard],
      },
      {
        path: 'kontakte',
        loadChildren: () => import('app/modules/admin/contacts/contacts.module').then(m => m.ContactsModule),
        canActivate :[BannedToUpgradeGuard],
      },
        {
            path: 'mailbox',
            loadChildren: () => import('app/modules/admin/mailbox/mailbox.module').then(m => m.MailboxModule)
        },
        {
          path: 'chat',
          loadChildren: () => import('app/modules/admin/chatAI/chat.routes')
        },

      // {
      //   path: 'vermoegen',
      //   loadChildren: () => import('app/modules/admin/assets/assets.module').then(m => m.AssetsModule)
      // },

      // pricing
      {
        path: 'preise',
        loadChildren: () => import('app/modules/admin/pages/pricing/modern/modern.module').then(m => m.PricingModernModule),
        canLoad :[IsMasterUserGuard],
        canActivate :[IsMasterUserGuard],
      },
      {
        path: 'upgrade',
        loadChildren: () => import('app/modules/admin/pages/pricing/modern/modern.module').then(m => m.PricingModernModule),
        canLoad :[IsMasterUserGuard],
        canActivate :[IsMasterUserGuard],
      },
      {
        path: 'abonnement/bestaetigt/BASIC',
        redirectTo:'abonnement/bestaetigt/basic',
      },
      {
        path: 'abonnement/bestaetigt/basic',
        loadChildren: () => import('app/modules/admin/pages/basic-subscription-succeeded/basic-subscription-succeeded.module').then(m => m.BasicSubscriptionSucceededModule),
      },
      {
        path: 'abonnement/bestaetigt/starter',
        loadChildren: () => import('app/modules/admin/pages/basic-subscription-succeeded/basic-subscription-succeeded.module').then(m => m.BasicSubscriptionSucceededModule),
      },
      {
        path: 'abonnement/bestaetigt/PRO',
        redirectTo:'abonnement/bestaetigt/pro',
      },
      {
        path: 'abonnement/bestaetigt/pro',
        loadChildren: () => import('app/modules/admin/pages/pro-subscription-succeeded/pro-subscription-succeeded.module').then(m => m.ProSubscriptionSucceededModule),
      },
      {
        path: 'abonnement/zahlungsmethode-hinzugefuegt',
        loadChildren: () => import('app/modules/admin/pages/payment-method-added/payment-method-added.module').then(m => m.PaymentMethodAddedModule),
      },
      {
        path: 'vermieter-bescheinigung',
        loadChildren: () => import('app/modules/admin/landlord-certificate/landlord-certificate.module').then(m => m.LandlordCertificateModule),
        canActivate :[BannedToUpgradeGuard],
      },
      {
        path: 'support',
        loadChildren: () => import('app/modules/admin/help-center/help-center.module').then(m => m.HelpCenterModule)
      },
      {
        path: 'staffelmiete',
        loadChildren: () => import('app/modules/admin/graduated-rent/graduated-rent.module').then(m => m.GraduatedRentModule),
        canActivate :[BannedToUpgradeGuard],
      },
      {
        path: 'transaktionen',
        loadChildren: () => import('app/modules/admin/transactions/transactions.module').then(m => m.TransactionsModule),
        canActivate :[BannedToUpgradeGuard],
      },
      {
        path: 'aufgaben',
        loadChildren: () => import('app/modules/admin/tasks/tasks.module').then(m => m.TasksModule),
        canActivate :[BannedToUpgradeGuard],
      },
      {
        path: 'buchungen',
        loadChildren: () => import('app/modules/admin/booking/booking.module').then(m => m.BookingModule),
        data : {
            accessLayer: BookingsAccessLayerOptions.ACCOUNT
        },
        canActivate :[BannedToUpgradeGuard],
      },
      {
        path: 'buchungen/op',
        loadChildren: () => import('app/modules/admin/booking/booking.module').then(m => m.BookingModule),
        data : {
            accessLayer: BookingsAccessLayerOptions.ACCOUNT
        },
        canActivate :[BannedToUpgradeGuard],
      },
      {
        path: 'notes',
        loadChildren: () => import('app/modules/admin/notes/notes.module').then(m => m.NotesModule)
      },
        {
            path: 'empfehlungsbonus',
            loadChildren: () => import('app/modules/admin/pages/affiliates/affiliates.module').then(m => m.AffiliatesModule)
        },
      {
         path: 'ticketing',
         loadChildren: () => import('app/modules/admin/scrumboards/scrumboards.module').then(m => m.ScrumboardsModule)
      },
        {
            path: 'anleitungen',
            loadChildren: () => import('app/modules/admin/instructions/instructions.module').then(m => m.InstructionsModule)
        },
        {
            path: 'ausgaben',
            loadChildren: () => import('app/modules/admin/expenses/expenses.module').then(m => m.ExpensesModule)
        },
        {
            path: 'buchungskonten',
            loadChildren: () => import('app/modules/admin/bookingsAccounts/bookings-accounts.module').then(m => m.BookingsAccountsModule)
        },
        // Invoice
        {path: 'invoice', children: [
                {path: '', loadChildren: () => import('app/modules/admin/invoice/invoice.module').then(m => m.InvoiceModule)},
                {path: 'printable', children: [
                        {path: 'compact', loadChildren: () => import('app/modules/admin/invoice/printable/compact/compact.routes')},
                        {path: 'modern', loadChildren: () => import('app/modules/admin/invoice/printable/modern/modern.routes')}
                    ]}
            ]},
      // {
      //   path: 'kalendar',
      //   loadChildren: () => import('app/modules/admin/calendar/calendar.module').then(m => m.CalendarModule)
      // },
      // Error
      {
        path: 'fehler', children: [
          {
            path: '401',
            loadChildren: () => import('app/modules/admin/pages/error/error-401/error-401.module').then(m => m.Error401Module),
          },
          {
            path: '403',
            loadChildren: () => import('app/modules/admin/pages/error/error-403-access-denied/error-403.module').then(m => m.Error403Module),
          },
          {
            path: '404',
            loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module),
          },
          {
            // 426 - Upgrade required
            path: '426',
            loadChildren: () => import('app/modules/admin/pages/error/error-426/error-426.module').then(m => m.Error426Module),
          },
          {
            // 427 - Upgrade required - landlords used up
            path: '427',
            loadChildren: () => import('app/modules/admin/pages/error/error-427-landlords-used-up/error-427-landlords-used-up.module').then(m => m.Error427LandlordsUsedUpModule),
          },
          {
            // 428 - Upgrade required - properties used up
            path: '428',
            loadChildren: () => import('app/modules/admin/pages/error/error-428-properties-used-up/error-428-properties-used-up.module').then(m => m.Error428PropertiesUsedUpModule),
          },
          {
            // 429 - Upgrade required - units used up
            path: '429',
            loadChildren: () => import('app/modules/admin/pages/error/error-429-units-used-up/error-429-units-used-up.module').then(m => m.Error429UnitsUsedUpModule),
          },
          {
            // 430 - Upgrade to PRO required - module is locked
            path: '430',
            loadChildren: () => import('app/modules/admin/pages/error/error-430-upgrade-required/error-430-upgrade-required.module').then(m => m.Error430UpgradeRequiredModule),
          },
          {
            // 431 - Upgrade to ENTERPRISE required - module is locked
            path: '431',
            loadChildren: () => import('app/modules/admin/pages/error/error-431-upgrade-to-enterprise/error-431-upgrade-to-enterprise.module').then(m => m.Error431UpgradeToEnterpriseModule),
          },
          {
            // 432 - Upgrade required - users used up
            path: '432',
            loadChildren: () => import('app/modules/admin/pages/error/error-432-users-used-up/error-432-users-used-up.module').then(m => m.Error432UsersUsedUpModule),
          },
          {
            path: '500',
            loadChildren: () => import('app/modules/admin/pages/error/error-500/error-500.module').then(m => m.Error500Module),
          }
        ]
      },


      // 404 & Catch all
      {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module),
      },
      { path: '**', redirectTo: '404-not-found' }
    ]
  }
];
