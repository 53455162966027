import {GermanAddress} from '@angular-material-extensions/google-maps-autocomplete';
import {NahausImage} from '@shared/models/image.interface';
import {Timestamp} from 'firebase/firestore';
import {BankAccount} from '../../../../bankAccounts/models';
import {Landlord} from '../../../../landlords/models';
import {ManagementType} from '../../../models/property.interface';
import {ManagementUnitPeriod} from '../features/management/models/managementUnitPeriod';
import {PropertyUnitPeriod} from '../features/period/models/property.unit.period.interface';

/**
 * @author Anthony Nahas
 * @since 06.01.2020
 * @version 1.92
 * @updated 1.09.23
 */
export interface PropertyUnit {
  id?: string;
  customerID?: string;
  propertyID?: string;
  vacanyID?: string;
  h_id?: number; // Haus ID
  w_id?: number; // Wohnung ID -> 1
  w_id_text?: string; // Wohnung ID als text --> W1
  mea?: number; // number - Wohnungseigentumsanteil
  landlord?: Landlord; // only for property MIET UND WEG Verwaltung AND unit MIET VERWALTUNG
  bankAccount?: BankAccount; // only for property MIET UND WEG Verwaltung AND unit MIET VERWALTUNG
  landlordID?: string; // Eigentümer ID
  label?: string;
  summary?: string;
  address?: Partial<GermanAddress>;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  managementType?: ManagementType;
  category?: PropertyUnitCategory;
  categoryType?: PropertyUnitCategoryType;
  garageType?: GarageType;
  area?: number; // Fläche
  rooms?: number;
  floor?: number;
  numberOfPeriods?: number; // anzahl von Vermietungen oder Leerstand
  numberOfManagements?: number; // Anzahl von Eigentümer Verwaltung
  currentPeriod?: PropertyUnitPeriod;
  currentManagement?: ManagementUnitPeriod;
  areaDetails?: {
    length?: number;
    width?: number;
    height?: number;
  };
  division?: {
    livingRoomsNumber?: number;
    bedRoomsNumber?: number;
    bathRoomsNumber?: number;
    kitchensNumber?: number;
    kitchenetteNumber?: number;
    hallwayNumber?: number;
    wcNumber?: number;
    atticNumber?: number; // Mansarde
    balconiesNumber?: number;
    basementsNumber?: number;
    floorSpaceNumber?: number; // Bodenraum
    kitchenType?: KitchenType;
  };
  features?: {
    hasKitchen?: boolean;
    hastGuestToilet?: boolean;
    hasTerrace?: boolean;
    hasBalcony?: boolean;
    hasBasement?: boolean;
    hasStorageRoom?: boolean;
    hasGarden?: boolean;
    hasWG?: boolean;
    hasFreeParking?: boolean;
  };
  desc?: {
    furnishing?: string;
    location?: string;
    others?: string;
  };
  facility?: {
    lastKnownRenovation?: Timestamp;
    heaterType: HeaterType;
    lastHeaterMaintenance: Date | Timestamp;
    hasWaterHeater?: boolean;
    knownIssues: []
  };
  totalPrice?: number;
  img?: NahausImage;
  imgs?: Array<any>;
  docs?: Array<any>;
  notes?: string;
  isLocked?: boolean; // ob die Immobilie gesperrt --> keiner kann das sehen oder suchen
  isDeprecated?: boolean; // das wäre der Fall wenn z.B: von einer 4 Zimmer WOhnung 2 mal 2 Zimmer Wohnung daraus gemacht wurde
  // Leerstand List
  isAddedToVacancyList?: boolean;
}


export enum PropertyUnitCategory {
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
}

export enum PropertyUnitCategoryType {
  APARTMENT = 'APARTMENT',
  FURNISHED_APARTMENT = 'FURNISHED_APARTMENT',
  HOUSE = 'HOUSE',
  BUSINESS = 'BUSINESS',
  LAND = 'LAND',
  GARAGE = 'GARAGE',
  PARKING = 'PARKING',
  PHOTOVOLTAICS = 'PHOTOVOLTAICS',
}


export enum HeaterType {
  ETAGENHEIZUNG = 'ETAGENHEIZUNG',
  OFENHEIZUNG = 'OFENHEIZUNG',
  ZENTRALHEIZUNG = 'ZENTRALHEIZUNG',
  ELEKTRO_HEIZUNG = 'ELEKTRO_HEIZUNG',
  FERNWÄRME = 'FERNWÄRME',
  FUßBODENHEIZUNG = 'FUßBODENHEIZUNG',
  GASHEIZUNG = 'GASHEIZUNG',
  NACHTSPEICHERÖFEN = 'NACHTSPEICHERÖFEN',
  ÖL_HEIZUNG = 'ÖL_HEIZUNG',
  SOLAR_HEIZUNG = 'SOLAR_HEIZUNG',
  WÄRMEPUMPE = 'WÄRMEPUMPE',
}

export enum KitchenType {
  KÜCHE = 'KÜCHE',
  WOHNKÜCHE = 'WOHNKÜCHE',
  KOCHNISCHE = 'KOCHNISCHE',
}


export enum GarageType {
  GARAGE = 'GARAGE',
  DOPPEL_GARAGE = 'DOPPEL_GARAGE',
  TIEFGARAGE = 'TIEFGARAGE',
  PARKHAUS = 'PARKHAUS',
  CARPORT = 'CARPORT',
}


export enum Condition {
  ERSTBEZUG = 'ERSTBEZUG',
  NEUWERTIG = 'NEUWERTIG',
  SANIERT = 'SANIERT',
  MORERNISIERT = 'MORERNISIERT',
  VOLLSTÄNDIG_RENOVIERT = 'VOLLSTÄNDIG_RENOVIERT',
  GEPFLEGT = 'GEPFLEGT',
  NACH_VEREINBARUNG = 'NACH_VEREINBARUNG',
  ABBRUCHREIF = 'ABBRUCHREIF',
}


export type Units = PropertyUnit[];
