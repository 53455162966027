import { Pipe, PipeTransform } from '@angular/core';
import { PropertyUnitCategoryType } from '../../modules/admin/properties/features/units/models/unit.interface';

@Pipe({
  name: 'categoryTypeSVG'
})
export class CategoryTypeSVGPipe implements PipeTransform {

  transform(value: PropertyUnitCategoryType, args: PropertyUnitCategoryType): any {
    switch (args) {
      case PropertyUnitCategoryType.APARTMENT:
        return 'assets/icons/apartment/architecture-and-city.svg';
      case PropertyUnitCategoryType.FURNISHED_APARTMENT:
        return 'assets/icons/living_room/sofa.svg';
      case PropertyUnitCategoryType.HOUSE:
        return 'assets/icons/house/house.svg';
      case PropertyUnitCategoryType.BUSINESS:
        return 'assets/icons/commercial/shopper.svg';
      case PropertyUnitCategoryType.LAND:
        return 'assets/icons/land/land.svg';
      case PropertyUnitCategoryType.GARAGE:
        return 'assets/icons/garage/garage.svg';
      case PropertyUnitCategoryType.PARKING:
        return 'assets/icons/parking/parking_5.svg';
      case PropertyUnitCategoryType.PHOTOVOLTAICS:
        return 'assets/icons/unit_category_type/solar.svg';
      default:
        return 'assets/icons/error/error.svg';
    }
  }

}
