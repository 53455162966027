import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Booking} from '../../models/booking';
import {BookingService} from '../../services/booking.service';

@Component({
    selector: 'delete-period-dialog',
    templateUrl: './delete-booking-dialog.component.html',
    styles: [
        /* language=SCSS */
        `
      .fuse-confirmation-dialog-panel {
        @screen md {
          @apply w-128;
        }

        .mat-mdc-dialog-container {

          .mat-mdc-dialog-surface {
            padding: 0 !important;
          }
        }
      }
    `
    ],
    encapsulation: ViewEncapsulation.None,
    providers: [BookingService]
})
export class DeleteBookingDialogComponent implements OnInit {

    booking: Booking;
    isDeleting: boolean;

    /**
     * Constructor
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Booking,
        public matDialogRef: MatDialogRef<DeleteBookingDialogComponent>,
        private snackbar: MatSnackBar,
        private bookingService: BookingService
    ) {
        console.log('data', data);
        if (!data) {
            // todo 26.10.21 --> show error
            this.snackbar.open('Etwas ist schief gelaufen, bitte versuchen Sie es später erneut oder wenden Sie sich an den Support', 'OK', {duration: 10000});
            this.matDialogRef.close('cancelled');
        } else {
            this.booking = data;
            this.bookingService.setParentPath(this.booking?.propertyID, this.booking?.unitID, this.booking?.periodID);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    delete(): void {
        this.isDeleting = true;
        this.bookingService
            .doc(this.booking?.id)
            .delete()
            .then(() => {
                this.matDialogRef.close();
                this.snackbar.open(`Die Buchung ${this.booking?.text} wurde erfolgreich gelöscht!`, 'OK', {duration: 5000});
            })
            .catch((err) => {
                console.error('Error:', err);
                this.snackbar.open('Etwas ist schief gelaufen, bitte versuchen Sie es später erneut oder wenden Sie sich an den Support', 'OK', {duration: 10000});
            })
            .finally(() => this.isDeleting = false);
    }
}
