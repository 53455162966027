import { GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import { Pipe, PipeTransform } from '@angular/core';
import { formatAddress } from '../../helpers/address';

@Pipe({
  name: 'formatAddress'
})
export class FormatAddressPipe implements PipeTransform {

  transform(value: GermanAddress): unknown {
    return formatAddress(value);
  }

}
