import {DatePipe} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {fuseAnimations} from '@fuse/animations';
import {NextRentalBookingDatePipe} from '@shared/pipes/next-rental-booking-date/next-rental-booking-date.pipe';
import {Timestamp} from 'firebase/firestore';
import {DateTime} from 'luxon';
import {Subject, take, takeUntil} from 'rxjs';
import {convertTimestamp} from '../../../../../helpers';
import {
    Costs,
    PeriodPaymentStrategyOptions,
    PeriodPaymentStrategyType,
    PropertyUnitPeriod
} from '../../../properties/features/units/features/period/models/property.unit.period.interface';
import {Booking, BookingArtOptions, Bookings, BookingStatusOptions, BookingTypeOptions} from '../../models/booking';
import {BookingService} from '../../services/booking.service';
import {BookingPaymentsService} from '../../services/bookingPayments.service';
import {
    CostChangesService
} from "../../../properties/features/units/features/period/features/costChanges/services/costChanges.service";

export interface GenerateBookingDialog {
    propertyID: string;
    unitID: string;
    period: PropertyUnitPeriod;
}

@Component({
    selector: 'generate-booking-dialog',
    templateUrl: './generate-booking-dialog.component.html',
    styles: [
        `
            .fuse-confirmation-dialog-panel {

                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {

                    .mat-mdc-dialog-surface {
                        padding: 0 !important;
                    }
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [BookingService, BookingPaymentsService, CostChangesService]
})
export class GenerateBookingDialogComponent implements OnInit, OnDestroy {

    today = new Date();

    bookingArtOptions = BookingArtOptions;

    propertyID: string;
    unitID: string;
    period: PropertyUnitPeriod;

    costChanges: Costs[];

    minDate: Date;
    maxDate: Date;

    loading: boolean;
    saving: boolean;
    showError: boolean;
    errorMsg: string;

    showProportionalRentHint: boolean;
    showHintOfActivatedAutoSoll: boolean;
    isAutoSollActivated: boolean;

    bookingFormGroup: FormGroup;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: GenerateBookingDialog,
                private matDialogRef: MatDialogRef<GenerateBookingDialogComponent>,
                private formBuilder: FormBuilder,
                private bookingService: BookingService,
                private bookingPaymentsService: BookingPaymentsService,
                private costChangesService: CostChangesService,
                private snackbar: MatSnackBar,
                private datePipe: DatePipe,
                private nextRentalBookingDatePipe: NextRentalBookingDatePipe) {
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.propertyID = this.data?.propertyID;
        this.unitID = this.data?.unitID;
        this.period = this.data?.period;

        if (!(this.propertyID && this.unitID && this.period)) {
            this.snackbar.open('Etwas ist schief gelaufen, bitte versuchen Sie es später erneut oder wenden Sie sich an den Support', 'OK', {duration: 10000});
            this.matDialogRef.close('cancelled');
        }

        this.minDate = (this.period?.rental?.startDate as Timestamp)?.toDate();
        this.maxDate = this.period?.rental?.endDate ? (this.period?.rental?.endDate as Timestamp)?.toDate() : null;
        this.isAutoSollActivated = this.period?.costs?.strategy?.autoSoll;

        console.log('CreateBookingDialogComponent --> propertyID --> ', this.propertyID);
        console.log('CreateBookingDialogComponent --> unitID --> ', this.unitID);
        console.log('CreateBookingDialogComponent --> period --> ', this.period);

        this.bookingService.setParentPath(this.propertyID, this.unitID, this.period?.id);
        this.costChangesService.setParentPath(this.propertyID, this.unitID, this.period?.id);
        // this.createBookingFG();

        this.bookingFormGroup = this.formBuilder.group({
            startDate: this.formBuilder.control(null, Validators.required),
            endDate: this.formBuilder.control(null, Validators.required),
            autoBook: this.formBuilder.control(false)
        });

        this.bookingFormGroup.get('startDate')?.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((startDate: Date) => {
            if (!startDate) {
                this.showProportionalRentHint = false;
                return;
            }

            this.showProportionalRentHint = startDate?.getDate() !== 1;
        });

        this.bookingFormGroup.get('endDate')?.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((endDate: Date) => {
            if (!this.isAutoSollActivated || !endDate) {
                return;
            }

            const nextRentalBookingDate = this.nextRentalBookingDatePipe.calculateNextDueDate(this.minDate, this.period?.costs?.strategy?.type);
            console.log('nextRentalBookingDate --> ', nextRentalBookingDate);

            this.showHintOfActivatedAutoSoll = endDate > nextRentalBookingDate;
        });

        this.loadCostsChanges();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    createBookingFG(booking?: Booking): FormGroup {
        return this.formBuilder.group({
            id: this.bookingService.createID(),
            customerID: this.bookingService.authService?.customerID || null,
            createdAt: this.today,
            propertyID: this.propertyID,
            unitID: this.unitID,
            periodID: this.period?.id,
            type: BookingTypeOptions.PAYMENT,
            status: BookingStatusOptions.EXPECTED,
            paid: false,
            date: [booking?.date ? convertTimestamp(booking?.date) : this.suggestDate(), Validators.required],
            amount: [booking?.amount, [Validators.required, booking?.type === BookingTypeOptions.PAYOUT ? Validators.max(0) : Validators.min(0)]],
            saldo: booking?.saldo,
            text: [booking?.text, Validators.required],
            art: [this.bookingArtOptions.WARM_MIETE, Validators.required]
            // tenantsName: this.booking?.tenantsName || this.period?.tenantsName
        });
    }

    suggestDate(): Date {
        const today = DateTime.fromJSDate(this.today);
        const day = today.day;
        return day >= 15 ? today.endOf('month').plus({day: 1}).toJSDate() : today.startOf('month').toJSDate();
    }

    generate(): void {
        if (this.bookingFormGroup.invalid) {
            this.snackbar.open('Bitte überprüfen Sie Ihre Eingaben!', 'OK', {duration: 5000});
            this.bookingFormGroup.markAllAsTouched();
            return;
        }

        this.showError = false;
        this.errorMsg = null;
        this.saving = true;

        this.bookingFormGroup.disable();


        const start = this.bookingFormGroup.get('startDate')?.value as Date;
        const end = this.bookingFormGroup.get('endDate')?.value as Date;
        const autoBook = this.bookingFormGroup.get('autoBook')?.value as boolean;
        const paymentStrategyType = this.period?.costs?.strategy?.type;
        // const rentAmount = this.period?.costs?.total;

        const bookings = this.generateBookings(start, end, paymentStrategyType, autoBook);
        console.log('bookings = ', bookings);

        if (bookings?.length > 0) {
            const batch = this.bookingService.batch();
            // const paymentBatch = this.bookingPaymentsService.batch();
            // bookings.forEach(booking => {
            //   batch.set(this.bookingService.doc(booking?.id).ref, booking, { merge: true });
            // });

            const isCommercial = this.period?.isCommercial;
            const dayOf = this.period?.costs?.strategy?.dayOf || 1;

            for (let i = 0; i < bookings.length; i++) {
                const booking: Booking = bookings[i];
                if (i !== 0 || (i === 0 && (booking.date as Date).getDate() === 1)) {
                    booking.date = DateTime.fromJSDate(booking.date as Date).set({day: dayOf}).toJSDate();
                }

                // if (isCommercial) {
                //     booking.amount *= 1.19;
                // }
                if (autoBook) {
                    booking.markAsPaid = true;
                }

                batch.set(this.bookingService.doc(booking?.id).ref, booking, {merge: true});

                // add payment to the booking
                // if (autoBook) {
                // const payment: BookingPayment = {
                //     id: this.bookingPaymentsService.createID(),
                //     customerID: this.bookingPaymentsService.authService?.customerID || null,
                //     createdAt: this.today,
                //     propertyID: this.propertyID,
                //     unitID: this.unitID,
                //     periodID: this.period?.id,
                //     bookingID: booking?.id,
                //     date: booking.date,
                //     amount: booking?.amount,
                //     // text: bookingPayment?.text || this.booking?.text,
                //     art: booking?.art
                // };
                // const paymentParentPath = `properties/${this.propertyID}/units/${this.unitID}/periods/${this.period?.id}/bookings/${booking.id}`;
                // paymentBatch.set(this.bookingPaymentsService.docWithParent(paymentParentPath, payment.id).ref, payment, {merge: true});
                // this.bookingPaymentsService.setParentPath(this.property?.id, this.unit?.id, this.period?.id, this.booking?.id);
                // }
            }

            batch.commit()
                .then(() => {
                    this.matDialogRef.close();
                    this.snackbar.open('Sollstellungen wurden erfolgreich für den eingegebenen Zeitraum generiert.', 'OK', {duration: 5000});
                })
                // .then(() => setTimeout(() => paymentBatch.commit().then(() => {
                //
                // }), autoBook ? 5000 : 0))
                .catch((err) => {
                    console.error("Error while committing the batch --> ", err)
                    this.errorMsg = `Fehler: ${err}`;
                    this.showError = true;
                    this.saving = false;
                })
                .finally(() => {
                    this.bookingFormGroup.enable();
                });

        } else {
            // this.snackbar.open('Für den eingegebenen Zeitraum wurden keine automatischen Sollstellungen generiert. Bitte überprüfen Sie die eingegebenen Daten und versuchen Sie es erneut.', 'OK', { duration: 5000 });
            this.errorMsg = 'Für den eingegebenen Zeitraum wurden keine automatischen Sollstellungen generiert. Bitte überprüfen Sie die eingegebenen Daten und versuchen Sie es erneut.';
            this.showError = true;
            this.saving = false;
            this.bookingFormGroup.enable();
        }
    }

    getRentAmount(dueDate: Date): number {
        if (this.costChanges?.length > 0) {
            for (const costChange of this.costChanges) {
                const startDate: Date = (costChange?.startDate as Timestamp)?.toDate();
                if (dueDate >= startDate) {
                    return costChange?.total;
                }
            }
        }
        return this.period?.costs?.total;
    }

    getPaymentReference(dueDate: Date): string {
        if (this.costChanges?.length > 0) {
            for (const costChange of this.costChanges) {
                const startDate: Date = (costChange?.startDate as Timestamp)?.toDate();
                if (dueDate >= startDate) {
                    return costChange?.paymentReference || null;
                }
            }
        }
        return this.period?.costs?.paymentReference || null;
    }

    generateBookings(startDate: Date, currentDate: Date, paymentStrategyType: PeriodPaymentStrategyType, autoBook: boolean): any[] {
        const bookings: Bookings = [];
        const dueDates = this.getDueDates(startDate, currentDate, paymentStrategyType);

        for (let dueDate of dueDates) {
            let amount = this.getRentAmount(dueDate);

            const isCommercial = this.period?.isCommercial;

            if (isCommercial) {
                amount *= 1.19;
            }

            // Proportional amount for first month
            if (dueDate.getFullYear() === startDate.getFullYear() && dueDate.getMonth() === startDate.getMonth()) {
                const daysInMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate();
                const startDay = startDate.getDate();
                const proportion = (daysInMonth - startDay + 1) / daysInMonth; // +1 because starting day is included
                amount *= proportion;
            }

            const dateText = this.datePipe.transform(dueDate, 'MM/yyyy');
            const booking: Booking = {
                date: dueDate,
                paid: autoBook,
                status: autoBook ? BookingStatusOptions.PAID : BookingStatusOptions.NOT_PAID,
                amount: amount,
                saldo: amount * -1,
                text: `Miete ${dateText} | Automatische Sollstellung`,
                paymentReference: this.getPaymentReference(dueDate)
                // tenantsName: this.period?.tenantsName,
            };
            const bookingData: Booking = this.createBookingFG(booking).getRawValue() as Booking;

            bookings.push(bookingData);
        }

        return bookings;
    }

    getDueDates(startDate: Date, currentDate: Date, paymentStrategyType: PeriodPaymentStrategyType) {
        const dueDates = [];
        let tempDate = new Date(startDate.getTime());

        while (tempDate <= currentDate) {
            dueDates.push(new Date(tempDate.getTime())); // push a copy of tempDate
            this.incrementDate(tempDate, paymentStrategyType);
        }

        return dueDates;
    }

    incrementDate(date: Date, paymentStrategyType: PeriodPaymentStrategyType) {
        switch (paymentStrategyType) {
            case PeriodPaymentStrategyOptions.MONTHLY:
                date.setMonth(date.getMonth() + 1);
                break;
            case PeriodPaymentStrategyOptions.QUARTERLY:
                date.setMonth(date.getMonth() + 3);
                break;
            case PeriodPaymentStrategyOptions.HALF_YEARLY:
                date.setMonth(date.getMonth() + 6);
                break;
            case PeriodPaymentStrategyOptions.YEARLY:
                date.setFullYear(date.getFullYear() + 1);
                break;
        }
    }

    loadCostsChanges(): void {
        this.loading = true;

        this.costChangesService.collection(query => query.orderBy('startDate', 'desc')).get().pipe(take(1))
            .subscribe((querySnapshot) => {
                console.log("result on loadCostsChanges received");

                if (querySnapshot?.size > 0) {
                    this.costChanges = querySnapshot.docs.map(d => d.data() as Costs)
                    console.log("costChanges.size > 0 --> ", this.costChanges);
                }

                this.loading = false;
            })
    }

}
