import {AgmCoreModule} from '@agm/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {NgOptimizedImage, registerLocaleData} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {getApp, initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {AngularFireModule} from '@angular/fire/compat';
import {
    AngularFireAnalyticsModule,
    APP_NAME,
    APP_VERSION,
    ScreenTrackingService,
    UserTrackingService
} from '@angular/fire/compat/analytics';

import {LANGUAGE_CODE, USE_DEVICE_LANGUAGE, USE_EMULATOR as USE_AUTH_EMULATOR} from '@angular/fire/compat/auth';
import {AngularFirestoreModule, USE_EMULATOR as USE_FIRESTORE_EMULATOR} from '@angular/fire/compat/firestore';
import {
    AngularFireFunctionsModule,
    REGION,
    USE_EMULATOR as USE_FUNCTIONS_EMULATOR
} from '@angular/fire/compat/functions';
import {AngularFirePerformanceModule, PerformanceMonitoringService} from '@angular/fire/compat/performance';
import {AngularFireStorageModule, USE_EMULATOR as USE_STORAGE_EMULATOR} from '@angular/fire/compat/storage';
import {
    connectFirestoreEmulator,
    initializeFirestore,
    persistentLocalCache,
    persistentMultipleTabManager,
    provideFirestore
} from '@angular/fire/firestore';
import {getStorage, provideStorage} from '@angular/fire/storage';
import '@angular/localize/init';
import {MAT_CHIPS_DEFAULT_OPTIONS} from '@angular/material/chips';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ExtraOptions, PreloadAllModules, RouterModule} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '@env/environment';
import {FuseModule} from '@fuse';
import {FuseMockApiModule} from '@fuse/lib/mock-api';
import {FuseConfigModule} from '@fuse/services/config';
import {AuthEffects} from '@nahausStore/effects/auth.effects';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MyCustomPaginatorIntl} from '@shared/classes/MyCustomPaginatorIntl';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {NgAisModule} from 'angular-instantsearch';
import {AppComponent} from 'app/app.component';
import {appConfig} from 'app/core/config/app.config';
import {CoreModule} from 'app/core/core.module';
import {LayoutModule} from 'app/layout/layout.module';
import {mockApiServices} from 'app/mock-api';


import 'moment/locale/de';
import {NgxCurrencyModule} from 'ngx-currency';
// import filepond module
import {MomentModule} from 'ngx-moment';
import {QuillModule} from 'ngx-quill';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {appRoutes} from './app.routing';
import {appReducer} from './store/reducers/app.reducer';

registerLocaleData(localeDe);

// AoT requires an exported function for factories
export const HttpLoaderFactory = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http);

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    initialNavigation: 'enabledBlocking'
};


const googleMapsParams = {
    apiKey: environment.GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
    language: 'de',
    region: 'DE'
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        NgOptimizedImage,

        // Angular Fire
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule.enablePersistence(),
        AngularFireFunctionsModule,
        AngularFireStorageModule,
        AngularFirePerformanceModule,
        AngularFireAnalyticsModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => {
                const firestore =
                    initializeFirestore(getApp(), {
                        localCache: persistentLocalCache({
                            tabManager: persistentMultipleTabManager()
                        }),
                    });

                // persistentMultipleTabManager();
                if (environment.useEmulators) {
                    connectFirestoreEmulator(firestore, 'localhost', 8080);
                }

                return firestore;
            }
        ),
        // provideFirestore(() => {
        //     const firestore = getFirestore();
        //
        //     // persistentMultipleTabManager();
        //     if (environment.useEmulators) {
        //         connectFirestoreEmulator(firestore, 'localhost', 8080);
        //     }
        //
        //     return firestore;
        // }),
        provideAuth(() => getAuth()),
        provideStorage(() => getStorage()),
        // provideAppCheck(() =>
        //   initializeAppCheck(undefined, {
        //     provider: new ReCaptchaV3Provider(environment.reCaptchaV3Key),
        //     isTokenAutoRefreshEnabled: true
        //   })
        // ),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules
        TranslateModule.forRoot({
            useDefaultLang: true,
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgAisModule.forRoot(),
        MomentModule.forRoot(),
        NgxWebstorageModule.forRoot(),
        QuillModule.forRoot(),
        AgmCoreModule.forRoot(googleMapsParams),
        NgxCurrencyModule,
        GoogleTagManagerModule.forRoot({
            id: environment.GTM_ID
        }),
        // Material
        MatSnackBarModule,

        // Ngrx
        StoreModule.forRoot({app: appReducer}),
        EffectsModule.forRoot([AuthEffects]),
        StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        PerformanceMonitoringService,
        ScreenTrackingService,
        UserTrackingService,
        {provide: APP_NAME, useValue: environment.customer},
        {provide: APP_VERSION, useValue: environment.version},
        // { provide: USE_DEVICE_LANGUAGE, useValue: false },
        {provide: REGION, useValue: 'europe-west3'},
        {provide: LANGUAGE_CODE, useValue: 'de'},
        {provide: USE_DEVICE_LANGUAGE, useValue: true},
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
        {provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl},
        {
            provide: MAT_CHIPS_DEFAULT_OPTIONS,
            useValue: {
                separatorKeyCodes: [ENTER, COMMA]
            }
        },
        // firebase emulators
        {provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined},
        {provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined},
        {provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined},
        {provide: USE_STORAGE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9199] : undefined}
        // { provide: USE_DATABASE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9000] : undefined },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
