import { Pipe, PipeTransform } from '@angular/core';
import { Landlord, LandlordTypeOptions } from '../../modules/admin/landlords/models';

@Pipe({
  name: 'landlordName'
})
export class LandlordNamePipe implements PipeTransform {

  transform(landlord: Landlord): string {
    return landlord?.displayName || this.runNameLogic(landlord);
  }

  runNameLogic(landlord: Landlord): string {
    switch (landlord?.type) {
      case LandlordTypeOptions.COMMERCIAL:
        return landlord?.companyName;
      case LandlordTypeOptions.PRIVATE:
        return `${landlord?.firstName} ${landlord?.lastName}`;
      default:
        return 'Unbekannt';
    }
  }

}
