import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {FuseLoadingService} from '@fuse/services/loading';
import {LogoSettingsService} from '@settings/services/logoSettings.service';
import {catchError, Observable, of, Subject, takeUntil} from 'rxjs';
import {finalize, map} from 'rxjs/operators';

import {
    defaultChunkUploadSize,
    defaultConcurrentUploadLimit,
    defaultFileSizeUnit,
    defaultMaxFileLimit,
    defaultMaxFileSize,
    FileSizeTypes,
    FileStatus,
    SizeUnits
} from '../models/constants';
import {DropZoneFile, ValidatorFunction} from '../models/file.model';

@Directive({
    selector: 'angular-core'
})
export class AngularDropzoneBase implements OnInit, OnDestroy {


    @Input() logoPath: string;

    @Input() multiple = true;
    @Input() validateFunctions: ValidatorFunction[] = [];
    @Input() keepInvalidFiles = true;
    @Input() maxFileLimit = defaultMaxFileLimit;
    @Input() maxFileSize = defaultMaxFileSize;
    @Input() fileSizeUnit: FileSizeTypes = defaultFileSizeUnit;
    @Input() concurrentUploadLimit = defaultConcurrentUploadLimit;
    @Input() allowedFormats: string[] = [];
    @Input() autoUpload = true;
    @Input() chunkUploadSize = defaultChunkUploadSize;

    @Output() uploaded = new EventEmitter<{ currentFile: DropZoneFile, allFiles: DropZoneFile[] }>();
    @Output() uploaded2 = new EventEmitter<{ filePath?: string, downloadLink: string }>();
    files: DropZoneFile[] = [];
    fileStatus = FileStatus;
    displayUnit: FileSizeTypes = defaultFileSizeUnit;
    allowedFormatsString: string = '';
    @ViewChildren('file') fileRow = new QueryList<ElementRef<HTMLDivElement>>();
    @ViewChild('dropzoneContainer', {static: true}) container!: ElementRef<HTMLDivElement>;
    avatarEditMode = false;
    uploadPercent: Observable<number>;
    private dragEnterCounter = 0;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(public cdRef: ChangeDetectorRef,
                public storage: AngularFireStorage,
                public logoSettingsService: LogoSettingsService,
                public fuseLoadingService: FuseLoadingService) {
    }

    @HostListener('dragenter', ['$event']) onDragEnter(el: DragEvent) {
        this.dragEnterCounter++;
        if (!this.container.nativeElement.classList.contains('drag-over')) {
            this.container.nativeElement.classList.add('drag-over');
        }
    }

    @HostListener('dragleave', ['$event']) onDragLeave(el: DragEvent) {
        this.dragEnterCounter--;
        if (this.dragEnterCounter === 0) {
            this.container.nativeElement.classList.remove('drag-over');

        }
    }

    @HostListener('drop', ['$event']) onDrop(el: DragEvent) {
        el.stopPropagation();
        this.dragEnterCounter = 0;
        this.container.nativeElement.classList.remove('drag-over');
        this.onBrowseFiles(el);
        return false;
    }

    @HostListener('dragover', ['$event']) onDragOver(el: DragEvent) {
        return false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // if (!this.uploadAPI) {
        //   throw ('Endpoint is not provided');
        // }
        this.displayUnit = this.fileSizeUnit;
        this.chunkUploadSize = this.chunkUploadSize * SizeUnits[this.fileSizeUnit];
        this.allowedFormats.forEach(format => {
            if (format.includes('MIME:')) {
                this.allowedFormatsString += `${format.replace('MIME:', '')},`;
            } else {
                this.allowedFormatsString += `.${format.toLowerCase()},`;
            }
        });
        if (this.maxFileSize) {
            this.validateFunctions.push(
                {
                    fn: (item) =>
                        item.file.size / SizeUnits[this.fileSizeUnit] <= this.maxFileSize,
                    errorMessage: 'File size is larger than expected.'
                }
            );
        }
        if (this.allowedFormats.length > 0) {
            this.validateFunctions.push(
                {
                    fn: (item) => {
                        const fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi;
                        const extension = item.file.name.toLowerCase().match(fileExtensionPattern) ?? [];
                        if (extension.length === 0) {
                            return false;
                        }
                        let isValid = false;
                        this.allowedFormats.some(format => {
                            if (format.includes('MIME:')) {
                                isValid = !!(item.file.type.match(new RegExp(/\w+\/[-+.\w]+/g)));
                                return isValid;
                            } else {
                                isValid = format.toLowerCase() === extension[0].replace('.', '');
                                return isValid;
                            }
                        });
                        return isValid;
                    }
                    , errorMessage: 'File type is not supported.'
                }
            );
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    onBrowseFiles(event: Event | DragEvent) {
        let inputFiles: FileList | undefined | null;
        if ('dataTransfer' in event) {
            inputFiles = event.dataTransfer?.files;
        } else {
            inputFiles = (<HTMLInputElement>event.target).files;
        }
        if (inputFiles) {
            for (let i = 0; i < inputFiles.length; i++) {
                this.files.push(new DropZoneFile(inputFiles[i]));
                this.analyseFile(this.files.length - 1);
            }
        }
    }

    analyseFile(index: number) {
        if (this.checkMaxUploadCount(index)) {
            if (this.validateFile(index)) {
                if (this.checkConcurrentUpload(index)) {
                    if (this.autoUpload) {
                        this.upload(index);
                    }
                }
            }
        }
    }

    validateFile(index: number): boolean {
        this.validateFunctions.forEach(item => {
            if (!item.fn(this.files[index])) {
                this.files[index].error.push(item.errorMessage);
            }
        });

        if (this.files[index].error.length === 0) {
            return true;
        } else {
            if (this.keepInvalidFiles) {
                this.files[index].status = FileStatus.Unsupported;
                this.bringRowToTheView(index);
            } else {
                this.files.splice(index, 1);
            }
            return false;
        }
    }

    bringRowToTheView(index: number) {
        this.cdRef.detectChanges();
        this.fileRow.get(index)?.nativeElement.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
    }

    checkMaxUploadCount(index: number): boolean {
        if (this.maxFileLimit !== -1) {
            const remainingLimit = this.maxFileLimit - this.files.filter(f => f.status !== FileStatus.Unsupported && f.status !== FileStatus.Canceled).length;
            if (remainingLimit < 0) {
                this.files[index].status = FileStatus.Unsupported;
                if (this.keepInvalidFiles) {
                    this.bringRowToTheView(index);
                    this.files[index].error.push(`Cannot upload more than ${this.maxFileLimit} files.`);
                } else {
                    this.files.splice(index, 1);
                }
                return false;
            }
        }
        return true;
    }

    checkConcurrentUpload(index: number): boolean {
        if (this.concurrentUploadLimit > 0) {
            this.concurrentUploadLimit--;
            return true;
        }
        this.files[index].status = FileStatus.Pending;
        this.cdRef.detectChanges();
        return false;
    }

    upload(index: number) {
        this.fuseLoadingService.show();
        this.files[index].status = FileStatus.Uploading;
        const file = this.files[index].file;
        console.log('target file --> ', file);
        this.uploadFileAsync(this.logoPath, file)
            .then((res) => {
                console.log('res on upload --> ', res);
                this.uploaded2.emit({downloadLink: res, filePath: this.logoPath});
                this.files[index].status = FileStatus.Completed;
                this.uploaded.emit({currentFile: this.files[index], allFiles: this.files});
                this.cdRef.detectChanges();
            })
            .catch((err) => {
                console.error('Error --> ', err);
            })
            .finally(() => {
                this.fuseLoadingService.hide();
                this.cdRef.detectChanges();
            });

        return;

    }

    // pick first Pending item from files array
    startNewUpload() {
        const pendingUploadIndex = this.files.findIndex(f => f.status === FileStatus.Pending);
        if (pendingUploadIndex !== -1) {
            if (this.checkConcurrentUpload(pendingUploadIndex)) {
                this.upload(pendingUploadIndex);
            }
        } else if (this.files.filter(f => f.status === FileStatus.Uploading).length === 0) {
            this.cdRef.detectChanges();
        }
    }

    onCancel(index: number) {
        this.files[index].status = FileStatus.Canceled;
        this.files[index].loaded = 0;
    }

    onReset() {
        this.concurrentUploadLimit += this.files.filter(f => f.status === FileStatus.Ready).length;
        this.files = [];
    }

    onRestart(index: number) {
        this.files[index].error = [];
        this.files[index].status = FileStatus.Pending;
        if (this.concurrentUploadLimit > 0) {
            this.startNewUpload();
        }
    }

    onStartUpload() {
        this.files.forEach((f, index) => {
            if (f.status === this.fileStatus.Ready) {
                this.upload(index);
            }
        });
    }

    onToggleSizeUnit() {
        switch (this.displayUnit) {
            case 'KB':
                this.displayUnit = 'MB';
                break;
            case 'MB':
                this.displayUnit = 'GB';
                break;
            case 'GB':
                this.displayUnit = 'KB';
                break;
            default:
                this.displayUnit = 'MB';
                break;
        }
    }

    uploadFileAsync(filePath: string, buffer: any): Promise<any> {
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, buffer);
        this.uploadPercent = task.percentageChanges();
        // observe percentage changes
        // this.uploadPercent = task.percentageChanges();
        // get notified when the download URL is available
        return new Promise((resolve, reject) => {
            task.snapshotChanges().pipe(
                takeUntil(this._unsubscribeAll),
                catchError((err) => {
                    console.error('Error: ', err);
                    reject(err);
                    return of(null);
                }),
                finalize(() => {
                    // this.downloadURL = fileRef.getDownloadURL()
                    fileRef
                        .getDownloadURL()
                        .pipe(
                            takeUntil(this._unsubscribeAll),
                            map((url: string) => {
                                this.logoSettingsService.doc()
                                    .set({url, path: filePath})
                                    .finally(() => url);
                                return url;
                            })
                        )
                        .subscribe((url) => {
                            console.log('download URL --> ', url);
                            return resolve(url);
                        });
                })
            ).subscribe();
        });
    }
}
