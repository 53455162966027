import {NahausImage} from '@shared/models/image.interface';
import firebase from 'firebase/compat';
import {AppConfig} from '../../../../core/config/app.config';
import Timestamp = firebase.firestore.Timestamp;

/**
 * Interface to map a user
 *
 * @author Anthony Nahas
 * @since 11.19
 * @version 1.4
 * @updated 25.09.22
 */
export interface User {
    id?: string;
    uid?: string;
    createdAt?: Timestamp;
    updatedAt?: Timestamp;
    // firstName?: string; // deprecated 25.09.22 --> use only displayName
    // lastName?: string; // deprecated 25.09.22 --> use only displayName
    displayName?: string;
    email?: string;
    phoneNumber?: string;
    address?: string;
    avatar?: NahausImage;
    disabled?: boolean;
    providerID?: string;
    birthday?: Date | Timestamp;
    notes?: string;
    // role und permissions
    customClaims?: UserCustomClaims;
    role?: UserRole;
    roles?: string[]; // deprecated since nov. 2021
    permissions?: NahausModulesPermissions;
    status?: UserStatus;
    settings?: UserSettings;

    // prevent sending an email when user is deleted
    silentDelete?: boolean; // default -> false
    emailVerified?: boolean; // whether email verified
}

export interface UserCustomClaims {
    customerID: string;
    master: boolean;
    stripeRole?: NahausTier;
}

export type Users = User[];

export enum NahausTiersOptions {
    // 'FREE' = 'FREE',
    'STARTER' = 'STARTER',
    'PRO' = 'PRO',
    'ENTERPRISE' = 'ENTERPRISE',
}

export type NahausTier =
// NahausTiersOptions.FREE
    | NahausTiersOptions.STARTER
    | NahausTiersOptions.PRO
    | NahausTiersOptions.ENTERPRISE;

export enum UserStatusOptions {
    'ONLINE' = 'ONLINE',
    'AWAY' = 'AWAY',
    'BUSY' = 'BUSY',
    'NOT_VISIBLE' = 'NOT_VISIBLE',
    'OFFLINE' = 'OFFLINE',
}

export type UserStatus =
    UserStatusOptions.ONLINE
    | UserStatusOptions.AWAY
    | UserStatusOptions.BUSY
    | UserStatusOptions.NOT_VISIBLE
    | UserStatusOptions.OFFLINE;

export enum UserRoleOptions {
    'EMPLOYEE' = 'EMPLOYEE',
    'ADMIN' = 'ADMIN',
    'OWNER' = 'OWNER',
    'NONE' = 'NONE',
}

export type UserRole = UserRoleOptions.EMPLOYEE | UserRoleOptions.ADMIN | UserRoleOptions.OWNER | UserRoleOptions.NONE;

export const UserRoleList = [
    {
        label: 'Mitarbeiter',
        value: UserRoleOptions.EMPLOYEE,
        description: 'Der zugewiesene Benutzer hat spezifische Berechtigungen erhalten, die ihm den Zugriff nur auf die für ihn freigegebenen Module erlauben. In diesen Modulen darf er je nach zugewiesenem Rechteset Aktionen wie Lesen, Schreiben und/oder Löschen ausführen.',
        short: 'Zugriff nur auf zugelassene Module.'
    },
    {
        label: 'Admin',
        value: UserRoleOptions.ADMIN,
        description: 'Ein Administrator-Benutzer erhält vollumfängliche Berechtigungen innerhalb des Systems. Dies ermöglicht eine umfassende Kontrolle und Verwaltung aller Funktionalitäten und Prozesse.',
        short: 'Vollständige Kontrolle über alle Systemfunktionalitäten.'
    },
    {
        label: 'Master',
        value: UserRoleOptions.OWNER,
        description: 'Der Master-Benutzer stellt das höchste Berechtigungsniveau innerhalb des Systems dar und verfügt über uneingeschränkte Zugriffsrechte. Als initiales Benutzerkonto ist er essentiell für die Gesamtverwaltung und kann nicht innerhalb der Benutzerverwaltung gelöscht werden.',
        short: 'Uneingeschränkte Zugriffsrechte und essentiell für die Gesamtverwaltung.'
    }
];

export enum PermissionOptions {
    // MSA
    'MSA_READ' = 'MSA_READ',
    'MSA_WRITE' = 'MSA_WRITE',
    'MSA_DELETE' = 'MSA_DELETE',
    // landlords
    'LANDLORDS_READ' = 'LANDLORDS_READ',
    'LANDLORDS_WRITE' = 'LANDLORDS_WRITE',
    'LANDLORDS_DELETE' = 'LANDLORDS_DELETE',
    // properties
    'PROPERTIES_READ' = 'PROPERTIES_READ',
    'PROPERTIES_WRITE' = 'PROPERTIES_WRITE',
    'PROPERTIES_DELETE' = 'PROPERTIES_DELETE',
    // units
    'UNITS_READ' = 'UNITS_READ',
    'UNITS_WRITE' = 'UNITS_WRITE',
    'UNITS_DELETE' = 'UNITS_DELETE',
    // vermietungen
    'PERIODS_READ' = 'PERIODS_READ',
    'PERIODS_WRITE' = 'PERIODS_WRITE',
    'PERIODS_DELETE' = 'PERIODS_DELETE',
    // tenants
    'TENANTS_READ' = 'TENANTS_READ',
    'TENANTS_WRITE' = 'TENANTS_WRITE',
    'TENANTS_DELETE' = 'TENANTS_DELETE',

    // Nebenkosten
    'NK_READ' = 'NK_READ',
    'NK_WRITE' = 'NK_WRITE',
    'NK_DELETE' = 'NK_DELETE',
    'NK_DELIVER' = 'NK_DELIVER',

    // WEG-INVOICE
    'WEG_INVOICE_READ' = 'WEG_INVOICE_READ',
    'WEG_INVOICE_WRITE' = 'WEG_INVOICE_WRITE',
    'WEG_INVOICE_DELETE' = 'WEG_INVOICE_DELETE',
    'WEG_INVOICE_DELIVER' = 'WEG_INVOICE_DELIVER',

    // contracts
    'CONTRACTS_READ' = 'CONTRACTS_READ',
    'CONTRACTS_WRITE' = 'CONTRACTS_WRITE',
    'CONTRACTS_DELETE' = 'CONTRACTS_DELETE',
    // meters
    'METERS_READ' = 'METERS_READ',
    'METERS_WRITE' = 'METERS_WRITE',
    'METERS_DELETE' = 'METERS_DELETE',
    // meters readings
    'METERS_READINGS_READ' = 'METERS_READINGS_READ',
    'METERS_READINGS_WRITE' = 'METERS_READINGS_WRITE',
    'METERS_READINGS_DELETE' = 'METERS_READINGS_DELETE',
    // meters re-registrations
    'METERS_RE_REGISTRATIONS_READ' = 'METERS_RE_REGISTRATIONS_READ',
    'METERS_RE_REGISTRATIONS_WRITE' = 'METERS_RE_REGISTRATIONS_WRITE',
    'METERS_RE_REGISTRATIONS_DELETE' = 'METERS_RE_REGISTRATIONS_DELETE',
    // service providers
    // 'SERVICE_PROVIDERS_READ' = 'SERVICE_PROVIDERS_READ',
    // 'SERVICE_PROVIDERS_WRITE' = 'SERVICE_PROVIDERS_WRITE',
    // 'SERVICE_PROVIDERS_DELETE' = 'SERVICE_PROVIDERS_DELETE',
    // vacancies
    'VACANCIES_READ' = 'VACANCIES_READ',
    'VACANCIES_WRITE' = 'VACANCIES_WRITE',
    'VACANCIES_DELETE' = 'VACANCIES_DELETE',
    // bankAccounts
    'BANK_ACCOUNTS_READ' = 'BANK_ACCOUNTS_READ',
    'BANK_ACCOUNTS_WRITE' = 'BANK_ACCOUNTS_WRITE',
    'BANK_ACCOUNTS_DELETE' = 'BANK_ACCOUNTS_DELETE',
    // LOANS
    'LOANS_READ' = 'LOANS_READ',
    'LOANS_WRITE' = 'LOANS_WRITE',
    'LOANS_DELETE' = 'LOANS_DELETE',
    // ASSETS
    'ASSETS_READ' = 'ASSETS_READ',
    'ASSETS_WRITE' = 'ASSETS_WRITE',
    'ASSETS_DELETE' = 'ASSETS_DELETE',

    // USERS
    'USERS_READ' = 'USERS_READ',
    'USERS_WRITE' = 'USERS_WRITE',
    'USERS_DELETE' = 'USERS_DELETE',

    // CONTACTS
    'CONTACTS_READ' = 'CONTACTS_READ',
    'CONTACTS_WRITE' = 'CONTACTS_WRITE',
    'CONTACTS_DELETE' = 'CONTACTS_DELETE',

    // TASKS
    'TASKS_READ' = 'TASKS_READ',
    'TASKS_WRITE' = 'TASKS_WRITE',
    'TASKS_DELETE' = 'TASKS_DELETE',

    // NOTES
    'NOTES_READ' = 'NOTES_READ',
    'NOTES_WRITE' = 'NOTES_WRITE',
    'NOTES_DELETE' = 'NOTES_DELETE',

    // BOARDS
    'BOARDS_READ' = 'BOARDS_READ',
    'BOARDS_WRITE' = 'BOARDS_WRITE',
    'BOARDS_DELETE' = 'BOARDS_DELETE',

    // TRANSACTIONS
    'TRANSACTIONS_READ' = 'TRANSACTIONS_READ',
    'TRANSACTIONS_WRITE' = 'TRANSACTIONS_WRITE',
    'TRANSACTIONS_DELETE' = 'TRANSACTIONS_DELETE',

    // BOOKINGS
    'BOOKINGS_READ' = 'BOOKINGS_READ',
    'BOOKINGS_WRITE' = 'BOOKINGS_WRITE',
    'BOOKINGS_DELETE' = 'BOOKINGS_DELETE',

}

export type NahausModulesPermissions = NahausModulesPermission[];

export type NahausModulesPermission =
    PermissionOptions.MSA_READ |
    PermissionOptions.MSA_WRITE |
    PermissionOptions.MSA_DELETE |
    // LANDLORDS
    PermissionOptions.LANDLORDS_READ |
    PermissionOptions.LANDLORDS_WRITE |
    PermissionOptions.LANDLORDS_DELETE |
    // PROPERTIES
    PermissionOptions.PROPERTIES_READ |
    PermissionOptions.PROPERTIES_WRITE |
    PermissionOptions.PROPERTIES_DELETE |
    // UNITS
    PermissionOptions.UNITS_READ |
    PermissionOptions.UNITS_WRITE |
    PermissionOptions.UNITS_DELETE |
    // PERIODS
    PermissionOptions.PERIODS_READ |
    PermissionOptions.PERIODS_WRITE |
    PermissionOptions.PERIODS_DELETE |
    // TENANTS
    PermissionOptions.TENANTS_READ |
    PermissionOptions.TENANTS_WRITE |
    PermissionOptions.TENANTS_DELETE |
    // NK
    PermissionOptions.NK_READ |
    PermissionOptions.NK_WRITE |
    PermissionOptions.NK_DELETE |
    PermissionOptions.NK_DELIVER |

    // WEG_INVOICE
    PermissionOptions.WEG_INVOICE_READ |
    PermissionOptions.WEG_INVOICE_WRITE |
    PermissionOptions.WEG_INVOICE_DELETE |
    PermissionOptions.WEG_INVOICE_DELIVER |
    // CONTRACTS
    PermissionOptions.CONTRACTS_READ |
    PermissionOptions.CONTRACTS_WRITE |
    PermissionOptions.CONTRACTS_DELETE |
    // METERS
    PermissionOptions.METERS_READ |
    PermissionOptions.METERS_WRITE |
    PermissionOptions.METERS_DELETE |
    // METERS_READINGS
    PermissionOptions.METERS_READINGS_READ |
    PermissionOptions.METERS_READINGS_WRITE |
    PermissionOptions.METERS_READINGS_DELETE |
    // METERS_RE_REGISTRATIONS
    PermissionOptions.METERS_RE_REGISTRATIONS_READ |
    PermissionOptions.METERS_RE_REGISTRATIONS_WRITE |
    PermissionOptions.METERS_RE_REGISTRATIONS_DELETE |
    // SERVICE_PROVIDERS
    // PermissionOptions.SERVICE_PROVIDERS_READ |
    // PermissionOptions.SERVICE_PROVIDERS_WRITE |
    // PermissionOptions.SERVICE_PROVIDERS_DELETE |
    // VACANCIES
    PermissionOptions.VACANCIES_READ |
    PermissionOptions.VACANCIES_WRITE |
    PermissionOptions.VACANCIES_DELETE |
    // BANK_ACCOUNTS
    PermissionOptions.BANK_ACCOUNTS_READ |
    PermissionOptions.BANK_ACCOUNTS_WRITE |
    PermissionOptions.BANK_ACCOUNTS_DELETE |
    // LOANS
    PermissionOptions.LOANS_READ |
    PermissionOptions.LOANS_WRITE |
    PermissionOptions.LOANS_DELETE |
    // ASSETS
    PermissionOptions.ASSETS_READ |
    PermissionOptions.ASSETS_WRITE |
    PermissionOptions.ASSETS_DELETE |

// USERS
    PermissionOptions.USERS_READ |
    PermissionOptions.USERS_WRITE |
    PermissionOptions.USERS_DELETE |

// CONTACTS
    PermissionOptions.CONTACTS_READ |
    PermissionOptions.CONTACTS_WRITE |
    PermissionOptions.CONTACTS_DELETE |
// BOARD
    PermissionOptions.BOARDS_READ |
    PermissionOptions.BOARDS_WRITE |
    PermissionOptions.BOARDS_DELETE |
    // NOTES
    PermissionOptions.NOTES_READ |
    PermissionOptions.NOTES_WRITE |
    PermissionOptions.NOTES_DELETE |
    // TASKS
    PermissionOptions.TASKS_READ |
    PermissionOptions.TASKS_WRITE |
    PermissionOptions.TASKS_DELETE |

    // TRANSACTIONS
    PermissionOptions.TRANSACTIONS_READ |
    PermissionOptions.TRANSACTIONS_WRITE |
    PermissionOptions.TRANSACTIONS_DELETE |

    // BOOKINGS
    PermissionOptions.BOOKINGS_READ |
    PermissionOptions.BOOKINGS_WRITE |
    PermissionOptions.BOOKINGS_DELETE;

export enum PermissionTypeOptions {
    'READ' = 'READ',
    'WRITE' = 'WRITE',
    'DELETE' = 'DELETE',
    'DELIVER' = 'DELIVER',
}

export type PermissionType =
    PermissionTypeOptions.READ
    | PermissionTypeOptions.WRITE
    | PermissionTypeOptions.DELETE
    | PermissionTypeOptions.DELIVER;

export interface PermissionGroup {
    groupName: string;
    permissions: { value: string; type: PermissionType }[];
}

export const permissionsGroup: PermissionGroup[] =
    [
        {
            groupName: 'PROPERTIES',
            permissions:
                [
                    {
                        value: PermissionOptions.PROPERTIES_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.PROPERTIES_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.PROPERTIES_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'UNITS',
            permissions:
                [
                    {
                        value: PermissionOptions.UNITS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.UNITS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.UNITS_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'PERIODS',
            permissions:
                [
                    {
                        value: PermissionOptions.PERIODS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.PERIODS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.PERIODS_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'TENANTS',
            permissions:
                [
                    {
                        value: PermissionOptions.TENANTS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.TENANTS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.TENANTS_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'NK',
            permissions:
                [
                    {
                        value: PermissionOptions.NK_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.NK_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.NK_DELIVER,
                        type: PermissionTypeOptions.DELIVER
                    },
                    {
                        value: PermissionOptions.NK_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'CONTRACTS',
            permissions:
                [
                    {
                        value: PermissionOptions.CONTRACTS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.CONTRACTS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.CONTRACTS_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'METERS',
            permissions:
                [
                    {
                        value: PermissionOptions.METERS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.METERS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.METERS_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'METERS_READINGS',
            permissions:
                [
                    {
                        value: PermissionOptions.METERS_READINGS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.METERS_READINGS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.METERS_READINGS_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'METERS_RE_REGISTRATIONS',
            permissions:
                [
                    {
                        value: PermissionOptions.METERS_RE_REGISTRATIONS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.METERS_RE_REGISTRATIONS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.METERS_RE_REGISTRATIONS_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        // {
        //   groupName: 'SERVICE_PROVIDERS',
        //   permissions:
        //     [
        //       {
        //         value: PermissionOptions.SERVICE_PROVIDERS_READ,
        //         type: PermissionTypeOptions.READ
        //       },
        //       {
        //         value: PermissionOptions.SERVICE_PROVIDERS_WRITE,
        //         type: PermissionTypeOptions.WRITE
        //       },
        //       {
        //         value: PermissionOptions.SERVICE_PROVIDERS_DELETE,
        //         type: PermissionTypeOptions.DELETE
        //       }
        //     ]
        // },
        {
            groupName: 'VACANCIES',
            permissions:
                [
                    {
                        value: PermissionOptions.VACANCIES_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.VACANCIES_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.VACANCIES_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'BANK_ACCOUNT',
            permissions:
                [
                    {
                        value: PermissionOptions.BANK_ACCOUNTS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.BANK_ACCOUNTS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.BANK_ACCOUNTS_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'LANDLORDS',
            permissions:
                [
                    {
                        value: PermissionOptions.LANDLORDS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.LANDLORDS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.LANDLORDS_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'MSA',
            permissions:
                [
                    {
                        value: PermissionOptions.MSA_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.MSA_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.MSA_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'LOAN',
            permissions:
                [
                    {
                        value: PermissionOptions.LOANS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.LOANS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.LOANS_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'ASSETS',
            permissions:
                [
                    {
                        value: PermissionOptions.ASSETS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.ASSETS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.ASSETS_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'USERS',
            permissions:
                [
                    {
                        value: PermissionOptions.USERS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.USERS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.USERS_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'TASKS',
            permissions:
                [
                    {
                        value: PermissionOptions.TASKS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.TASKS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.TASKS_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'NOTES',
            permissions:
                [
                    {
                        value: PermissionOptions.NOTES_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.NOTES_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.NOTES_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        {
            groupName: 'CONTACTS',
            permissions:
                [
                    {
                        value: PermissionOptions.CONTACTS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.CONTACTS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    {
                        value: PermissionOptions.CONTACTS_DELETE,
                        type: PermissionTypeOptions.DELETE
                    }
                ]
        },
        // {
        //     groupName: 'TRANSACTIONS',
        //     permissions:
        //         [
        //             {
        //                 value: PermissionOptions.TRANSACTIONS_READ,
        //                 type: PermissionTypeOptions.READ
        //             },
        //             {
        //                 value: PermissionOptions.TRANSACTIONS_WRITE,
        //                 type: PermissionTypeOptions.WRITE
        //             },
        // {
        //     value: PermissionOptions.TRANSACTIONS_DELETE,
        //     type: PermissionTypeOptions.DELETE
        // }
        //         ]
        // },
        {
            groupName: 'BOOKINGS',
            permissions:
                [
                    {
                        value: PermissionOptions.BOOKINGS_READ,
                        type: PermissionTypeOptions.READ
                    },
                    {
                        value: PermissionOptions.BOOKINGS_WRITE,
                        type: PermissionTypeOptions.WRITE
                    },
                    // {
                    //     value: PermissionOptions.BOOKINGS_DELETE,
                    //     type: PermissionTypeOptions.DELETE
                    // }
                ]
        },
        // {
        //     groupName: 'BOARDS',
        //     permissions:
        //         [
        //             {
        //                 value: PermissionOptions.USERS_READ,
        //                 type: PermissionTypeOptions.READ
        //             },
        //             {
        //                 value: PermissionOptions.USERS_WRITE,
        //                 type: PermissionTypeOptions.WRITE
        //             },
        //             {
        //                 value: PermissionOptions.USERS_DELETE,
        //                 type: PermissionTypeOptions.DELETE
        //             }
        //         ]
        // }
    ];

export interface UserSettings {
    appearance?: AppConfig;
}
