import { Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export interface SearchState {
  hitPerPage: number;
  nbHits: number;
  nbPages: number;
  page: number;
  processingTimeMS: number;
  query: string;
}

@Directive({
  selector: '[appStatListener]'
})
export class StatListenerDirective implements OnChanges {


  @Input() state: SearchState;

  @Output()
  onNewStat: EventEmitter<SearchState> = new EventEmitter<SearchState>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('StatListenerDirective on change', changes);
    this.onNewStat.next(changes.state.currentValue);
  }

}
