<!-- Item wrapper -->
<div
    [class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
    [ngClass]="item.classes?.wrapper"
    class="fuse-vertical-navigation-item-wrapper">

    <!-- Item with an internal link -->
    <ng-container *ngIf="item.link && !item.externalLink && !item.function && !item.disabled">
        <a
            [fragment]="item.fragment ?? null"
            [matTooltip]="item.tooltip || ''"
            [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
            [preserveFragment]="item.preserveFragment ?? false"
            [queryParamsHandling]="item.queryParamsHandling ?? null"
            [queryParams]="item.queryParams ?? null"
            [routerLinkActiveOptions]="isActiveMatchOptions"
            [routerLinkActive]="'fuse-vertical-navigation-item-active'"
            [routerLink]="[item.link]"
            class="fuse-vertical-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with an external link -->
    <ng-container *ngIf="item.link && item.externalLink && !item.function && !item.disabled">
        <a
            [href]="item.link"
            [matTooltip]="item.tooltip || ''"
            [target]="item.target || '_self'"
            class="fuse-vertical-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with a function -->
    <ng-container *ngIf="!item.link && item.function && !item.disabled">
        <div
            (click)="item.function(item)"
            [matTooltip]="item.tooltip || ''"
            [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
            class="fuse-vertical-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item with an internal link and function -->
    <ng-container *ngIf="item.link && !item.externalLink && item.function && !item.disabled">
        <a
            (click)="item.function(item)"
            [fragment]="item.fragment ?? null"
            [matTooltip]="item.tooltip || ''"
            [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
            [preserveFragment]="item.preserveFragment ?? false"
            [queryParamsHandling]="item.queryParamsHandling ?? null"
            [queryParams]="item.queryParams ?? null"
            [routerLinkActiveOptions]="isActiveMatchOptions"
            [routerLinkActive]="'fuse-vertical-navigation-item-active'"
            [routerLink]="[item.link]"
            class="fuse-vertical-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with an external link and function -->
    <ng-container *ngIf="item.link && item.externalLink && item.function && !item.disabled">
        <a
            (click)="item.function(item)"
            [href]="item.link"
            [matTooltip]="item.tooltip || ''"
            [target]="item.target || '_self'"
            class="fuse-vertical-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </a>
    </ng-container>

    <!-- Item with a no link and no function -->
    <ng-container *ngIf="!item.link && !item.function && !item.disabled">
        <div
            [matTooltip]="item.tooltip || ''"
            [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
            class="fuse-vertical-navigation-item">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

    <!-- Item is disabled -->
    <ng-container *ngIf="item.disabled">
        <div
            [matTooltip]="item.tooltip || ''"
            class="fuse-vertical-navigation-item fuse-vertical-navigation-item-disabled">
            <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
        </div>
    </ng-container>

</div>

<!-- Item template -->
<ng-template #itemTemplate>

    <!-- Icon -->
    <!-- Icon PNG-->
    <ng-container *ngIf="item?.png">
        <img [ngClass]="item.classes?.icon"
             [src]="item.png"
             class="fuse-vertical-navigation-item-icon w-6">
    </ng-container>
    <ng-container *ngIf="item.icon">
        <mat-icon
            [ngClass]="item.classes?.icon"
            [svgIcon]="item.icon"
            class="fuse-vertical-navigation-item-icon"></mat-icon>
    </ng-container>

    <!-- Title & Subtitle -->
    <div class="fuse-vertical-navigation-item-title-wrapper">
        <div class="fuse-vertical-navigation-item-title">
            <ng-container *ngIf="this.item?.translate?.enabled; else defaultTemplate">
                <ng-container
                    *transloco="let t; read: this.item?.translate?.scope; scope: this.item?.translate?.scope">
                    <span [ngClass]="item.classes?.title">
                        {{ t(this.item?.title) }}
                    </span>
                </ng-container>
            </ng-container>
            <ng-template #defaultTemplate>
                <span [ngClass]="item.classes?.title">
                {{ this.item?.title }}
                </span>
            </ng-template>
        </div>
        <ng-container *ngIf="item.subtitle">
            <div class="fuse-vertical-navigation-item-subtitle">
                <ng-container *ngIf="this.item?.translate?.enabled; else defaultTemplate">
                    <ng-container
                        *transloco="let t; read: this.item?.translate?.scope; scope: this.item?.translate?.scope">
                    <span [ngClass]="item.classes?.subtitle">
                        {{ t(this.item?.subtitle) }}
                    </span>
                    </ng-container>
                </ng-container>
                <ng-template #defaultTemplate>
                    <span [ngClass]="item.classes?.subtitle">
                        {{ this.item?.subtitle }}
                    </span>
                </ng-template>
            </div>
        </ng-container>
    </div>

    <!-- Badge -->
    <ng-container *ngIf="item.badge">
        <div class="fuse-vertical-navigation-item-badge">
            <div
                [ngClass]="item.badge.classes"
                class="fuse-vertical-navigation-item-badge-content">
                <ng-container *ngIf="this.item?.badge.translate?.enabled; else defaultTemplate">
                    <ng-container
                        *transloco="let t; read: this.item?.translate?.scope; scope: this.item?.translate?.scope">
                <span>
                    {{ t(this.item?.badge?.title) }}
                </span>
                    </ng-container>
                </ng-container>
                <ng-template #defaultTemplate>
                <span>
                    {{ this.item?.badge?.title }}
                </span>
                </ng-template>
            </div>
        </div>
    </ng-container>

</ng-template>
