import { Pipe, PipeTransform } from '@angular/core';
import {
  PeriodPaymentStrategyOptions,
  PeriodPaymentStrategyType,
  PropertyUnitPeriod
} from '../../../modules/admin/properties/features/units/features/period/models/property.unit.period.interface';

@Pipe({
  name: 'nextRentalBookingDate'
})
export class NextRentalBookingDatePipe implements PipeTransform {

  transform(period: PropertyUnitPeriod): Date {
    const startDate: Date = period?.rental?.startDate as Date;
    const paymentStrategyType: PeriodPaymentStrategyType = period?.costs?.strategy?.type;
    return this.calculateNextDueDate(startDate, paymentStrategyType);
  }

  calculateDueMonths(startMonth: number, paymentStrategyType: PeriodPaymentStrategyType): number[] {
    let dueMonths: number[] = [];

    switch (paymentStrategyType) {
      case PeriodPaymentStrategyOptions.QUARTERLY: // für vierteljährlich
        for (let i = 0; i < 4; i++) {
          dueMonths.push((startMonth + i * 3) % 12);
        }
        break;
      case PeriodPaymentStrategyOptions.HALF_YEARLY: // für halbjährlich
        for (let i = 0; i < 2; i++) {
          dueMonths.push((startMonth + i * 6) % 12);
        }
        break;
      case PeriodPaymentStrategyOptions.YEARLY: // für jährlich
        dueMonths.push(startMonth);
        break;
      default: // für monatlich
        for (let i = 0; i < 12; i++) {
          dueMonths.push(i);
        }
        break;
    }

    return dueMonths;
  }

  calculateNextDueDate(startDate: Date, paymentStrategyType: PeriodPaymentStrategyType): Date {
    const startMonth = startDate.getMonth();
    const startYear = startDate.getFullYear();
    let dueMonths: number[] = this.calculateDueMonths(startMonth, paymentStrategyType);

    let currentDate = new Date();
    let currentMonth = currentDate.getMonth();
    let currentDay = currentDate.getDate();
    let currentYear = currentDate.getFullYear();

    dueMonths.sort((a, b) => a - b);

    let nextDueMonth;
    let nextDueYear;

    if (startDate > currentDate) {
      // Wenn das Startdatum in der Zukunft liegt, ist das nächste Fälligkeitsdatum das Startdatum
      nextDueMonth = startMonth;
      nextDueYear = startYear;
    } else {
      // Finde den nächsten Monat, der fällig ist
      nextDueMonth = dueMonths.find(month => month > currentMonth);

      // Wenn kein Monat in diesem Jahr gefunden wird, ist der nächste fällige Monat der erste Monat im nächsten Jahr
      if (nextDueMonth === undefined) {
        nextDueMonth = dueMonths[0];
        nextDueYear = currentYear + 1;
      } else {
        nextDueYear = currentYear;
      }

      // Wenn das Startdatum in der Vergangenheit liegt und wir uns in einem Fälligkeitsmonat befinden und es der 1. Tag ist, sollte das nächste Fälligkeitsdatum ein Jahr später sein
      if ((startMonth < currentMonth || (startMonth === currentMonth && currentDay > 1)) && dueMonths.includes(currentMonth)) {
        // nextDueYear++;
      }

      // Wenn wir uns in einem Fälligkeitsmonat befinden und es nach dem 1. Tag ist, setzen wir das nächste Fälligkeitsdatum auf den nächsten Monat
      if (dueMonths.includes(currentMonth) && currentDay > 1 && paymentStrategyType !== PeriodPaymentStrategyOptions.YEARLY) {
        nextDueMonth = dueMonths[(dueMonths.indexOf(currentMonth) + 1) % dueMonths.length];
        if (nextDueMonth < currentMonth) {
          nextDueYear++;
        }
      }
    }

    let nextDueDate = new Date(nextDueYear, nextDueMonth, 1);
    return nextDueDate;
  }

}
