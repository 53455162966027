import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NgControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-chip-list-email-input',
  templateUrl: './chip-list-email-input.component.html',
  styleUrls: ['./chip-list-email-input.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ChipListEmailInputComponent
    }
  ]
})
export class ChipListEmailInputComponent implements ControlValueAccessor, MatFormFieldControl<string[]>, DoCheck, OnDestroy {
  private static nextId: number = 0;
  public emailInputValue: string = '';
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  showCC: boolean = true;
  showBCC: boolean = true;

  @Output() onShowCcClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() onShowBccClicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() public maxValueLength: number = 15;

  @Input() showCcAndBCC: boolean;
  @HostBinding('attr.aria-describedby') public describedBy: string = '';
  @ViewChild('emailInputControl') public emailInputControl: NgControl;
  public handleOnChange: (v: any) => void = (v: any) => {
    return;
  };
  public handleOnTouched: () => void = () => {
    return;
  };

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }

    this.focusMonitor
      .monitor(this.elementRef.nativeElement, true)
      .subscribe((origin: FocusOrigin) => {
        this.focused = !!origin;
        this.stateChanges.next();
      });
  }

  private _placeholder: string = null;

  private _required: boolean = false;

  private _disabled: boolean = false;

  public _value: string[] = null;

  @Input()
  get value(): string[] {
    return this._value;
  }

  set value(value: string[]) {
    this._value = value;
    this.handleOnChange(value);
    this.stateChanges.next();
  }

  public stateChanges: Subject<void> = new Subject<void>();
  @HostBinding()
  public id: string = `app-chiplist-input-${ChipListEmailInputComponent.nextId++}`;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  public focused: boolean = false;

  get empty(): boolean {
    return Array.isArray(this.value) ? this.value.length === 0 : !this.value;
  }

  @HostBinding('class.floating') get shouldLabelFloat(): boolean {
    return this.focused || !this.empty || !!this.emailInputValue;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(required: boolean) {
    this._required = !!required;
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = !!value;
    this.stateChanges.next();
  }

  public errorState: boolean = false;

  public setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  public onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      const matChipInput: HTMLElement = this.elementRef.nativeElement.querySelector(
        'input'
      );

      if (matChipInput) {
        matChipInput.focus();
      }
    }
  }

  public ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  public writeValue(value: any): void {
    this.value = Array.isArray(value) ? value : null;
  }

  public registerOnChange(fn: (v: any) => void): void {
    this.handleOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.handleOnTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  public add(event: MatChipInputEvent): void {
    const input: HTMLInputElement = event.input;
    const value: string = event.value ? event.value.trim() : event.value;

    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.setErrors(null);
      this.ngControl.control.updateValueAndValidity();
    }

    if (this.emailInputControl.valid) {
      if (value) {
        const fieldValue: string[] = this.value ? [...this.value] : [];
        fieldValue.push(value);

        this.value = fieldValue;
      }

      if (input) {
        input.value = '';
      }
    } else if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.setErrors({ email: true });
      this.ngControl.control.markAsTouched();
    }
  }

  public remove(val: string): void {
    const value: string[] = this.value ? [...this.value] : [];
    const index: number = value.indexOf(val);

    if (index >= 0) {
      value.splice(index, 1);
      this.value = value;
    }
  }
}

export function emailArrayValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let isPassed: boolean = true;

    if (Array.isArray(control.value)) {
      for (const email of control.value) {
        const innerControl: FormControl = new FormControl(
          email,
          Validators.email
        );
        if (innerControl.errors && innerControl.errors.email) {
          isPassed = false;
          break;
        }
      }
    } else {
      isPassed = false;
    }

    return isPassed ? null : { emailArray: true };
  };
}

export function requiredArrayValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isPassed: boolean =
      Array.isArray(control.value) && control.value.length > 0;
    return isPassed ? null : { required: true };
  };
}

