<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<ng-container *transloco="let t; read: 'layout'">
	<!-- Navigation -->
	<fuse-vertical-navigation
			[mode]="isScreenSmall ? 'over' : 'side'"
			[name]="'mainNavigation'"
			[navigation]="navigation.default"
			[opened]="!isScreenSmall"
			class="dark bg-gray-900 print:hidden">
		<!-- Navigation header hook -->
		<ng-container fuseVerticalNavigationContentHeader>
			<div class="flex items-center w-full p-4 pl-6">
				<!-- Logo -->
				<div class="flex items-center justify-center">
					<img
							class="w-8"
							src="assets/logo/logo_single.svg">
				</div>
				<!-- Components -->
				<div class="flex items-center ml-auto">
					<!--				<notifications></notifications>-->
					<user [showAvatar]="false"></user>
				</div>
			</div>
			<!-- User -->
			<div class="flex flex-col items-center w-full p-4">
				<div class="relative w-24 h-24">
					<img
							[src]="this.firebaseUser?.avatar?.url ?? 'assets/illustrations/profile_pic/default/undraw_profile_pic.svg'"
							alt="User avatar"
							class="w-full h-full rounded-full">
					<!--				<mat-icon-->
					<!--						*ngIf="!this.firebaseUser?.avatar?.url"-->
					<!--						[svgIcon]="'heroicons_solid:user-circle'"-->
					<!--						class="icon-size-24"></mat-icon>-->
				</div>
				<div class="flex flex-col items-center justify-center w-full mt-6">
					<ng-container *ngIf="(this.authUser$ | async) as user">
						<div @zoomIn
						     class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
							{{user?.displayName}}
							<!--					{{this.firebaseUser?.displayName || (this.firebaseUser?.firstName + ' ' + this.firebaseUser?.lastName) }}-->
						</div>
						<div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
							<!--					{{this.firebaseUser?.email}}-->
							{{user?.email}}
						</div>
					</ng-container>
					<ng-container *ngIf="this.role$ | async as role; else trialOver">
						<ng-container [ngSwitch]="role">
							<div *ngSwitchCase="'STARTER'" @zoomIn
							     class=" mt-2 px-2 bg-blue-600 text-on-warn rounded font-semibold">
								{{role}}
							</div>
							<div *ngSwitchCase="'PRO'" @zoomIn
							     class=" mt-2 px-2 bg-primary text-on-primary rounded font-semibold">
								{{role}}
							</div>
							<div *ngSwitchCase="'ENTERPRISE'" @zoomIn
							     class=" mt-2 px-2 bg-accent text-on-accent rounded font-semibold">
								{{role}}
							</div>
							<div *ngSwitchDefault @zoomIn class=" mt-2 px-2 bg-green-600 text-on-warn rounded">
								{{ t('free') }}
							</div>

						</ng-container>
						<!--					<div class=" mt-2 px-2 bg-green-600 text-on-warn rounded">-->
						<!--						Kostenlos-->
						<!--					</div>-->
					</ng-container>
					<ng-template #trialOver>
						<div @zoomIn
						     class=" mt-2 px-2 bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50 rounded font-semibold">
							{{ t('trialExpired') }}
						</div>
					</ng-template>
					<div *ngIf="this.trialSubscription && !this.willTrialBeExtended" @zoomIn
					     class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium mt-4">
						<ng-container *ngIf="this.trialSubscription?.trial_end | toDate as trialEnd">
						<span *ngIf="trialEnd | amDifference: this.today :'days' : false as daysLeft"
						      class="font-extrabold">Noch {{daysLeft}} {{daysLeft | i18nPlural: {
                          '=0': 'Tage',
                          '=1': 'Tag',
                          'other': 'Tage'
                        } }}</span>
						</ng-container>
					</div>
				</div>
			</div>
		</ng-container>
		<!-- Navigation footer hook -->
		<ng-container fuseVerticalNavigationContentFooter>
			<div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
				<img
						class="max-w-36"
						src="assets/logo/logo1.svg">
				<span class="text-xs">{{this.env?.version}}</span>
			</div>
		</ng-container>
	</fuse-vertical-navigation>

	<!-- Wrapper -->
	<div class="flex flex-col flex-auto w-full min-w-0">

		<!-- Header -->
		<div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
			<!-- Navigation toggle button -->
			<button
					(click)="toggleNavigation('mainNavigation')"
					mat-icon-button>
				<mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
			</button>

			<!--		<ng-container *ngIf="this.tier$ | async as tier">-->
			<!--			<a *ngIf="tier !== 'PRO' && tier !== 'ENTERPRISE'" class="ml-2" color="primary" mat-raised-button-->
			<!--			   routerLink="/upgrade">-->
			<!--				Upgrade-->
			<!--			</a>-->
			<!--		</ng-container>-->

			<ng-container *ngIf="this.env?.pool">
				<ng-container *ngIf="this.tier$ | async as tier; else trialOverAlert">
					<!--	if trial end date exists on a subscription-->
					<!-- Info -->
					<fuse-alert *ngIf="this.trialSubscription" @zoomIn [type]="'info'" class="hidden sm:block ml-2">
						<ng-container *ngIf="this.trialSubscription?.trial_end | toDate as trialEnd">
						<span fuseAlertTitle>Sie befinden sich momentan in einer Testversion unserer <span
								class="font-extrabold">{{this.trialSubscription?.role || 'PRO'}}</span>-Version<span
								*ngIf="!this.willTrialBeExtended">, die am {{ trialEnd  | date:'shortDate' }}
							endet
						<span *ngIf="trialEnd | amDifference: this.today :'days' : false as daysLeft"
						      class="font-extrabold">(noch {{daysLeft}} {{daysLeft | i18nPlural: {
                          '=0': 'Tage',
                          '=1': 'Tag',
                          'other': 'Tage'
                        } }})</span>.</span></span>
						</ng-container>
					</fuse-alert>

					<ng-container
							*ngIf="this.trialSubscription && !this.willTrialBeExtended && !this.trialSubscription.role === this.tiersOptions.ENTERPRISE && this.isMasterUser">
						<a @zoomIn class="ml-2" color="primary"
						   mat-raised-button routerLink="/upgrade">
							{{ t('subscribeToProVersion') }}
						</a>
					</ng-container>

				</ng-container>
			</ng-container>

			<ng-template #trialOverAlert>

				<!-- Info -->
				<fuse-alert @zoomIn [type]="'error'" class="hidden sm:block ml-2">
					<span fuseAlertTitle>{{ t('proTrialExpired') }}</span>
				</fuse-alert>


				<ng-container *ngIf="this.isMasterUser">
					<a @zoomIn class="ml-2" color="warn"
					   mat-raised-button routerLink="/upgrade">
						{{ t('subscribeToProVersion') }}
					</a>
				</ng-container>
			</ng-template>

			<!-- Components -->
			<div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
                               				<languages></languages>
				<fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
				<!--			<search [appearance]="'bar'"></search>-->
				<!--			<shortcuts></shortcuts>-->
				<!--			<messages></messages>-->
				<button (click)="quickChat.toggle()"
				        *ngIf="!this.env?.production && this.env?.showQuickChat"
				        class="lg:hidden"
				        mat-icon-button>
					<mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
				</button>
			</div>
		</div>

		<!-- Content -->
		<div class="flex flex-col flex-auto">
			<!-- *ngIf="true" hack is required here for router-outlet to work correctly.
				 Otherwise, layout changes won't be registered and the view won't be updated! -->
			<router-outlet *ngIf="true"></router-outlet>
		</div>

		<!-- Footer -->
		<!--	<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">-->
		<!--		<span class="font-medium text-secondary">Nahaus.de &copy; {{currentYear}}</span>-->
		<!--	</div>-->

	</div>

	<!-- Quick chat -->
	<quick-chat #quickChat="quickChat"
	            *ngIf="!this.env?.production && this.env?.showQuickChat"></quick-chat>
</ng-container>
