import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-image-loader',
    templateUrl: './image-loader.component.html',
    styleUrls: ['./image-loader.component.scss']
})
export class ImageLoaderComponent implements OnInit, OnChanges {

    @Input() url: string;

    loading = true;

    constructor() {
    }

    ngOnInit(): void {
    }

    onImageLoad($event: any): void {
        this.loading = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.url?.currentValue !== changes?.url?.previousValue) {
            this.loading = true;
            this.url = changes?.url?.currentValue;
        }
    }

}
