import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DunningsModularService} from "../services/dunnings-modular.service";
import {Dunning} from "../models/dunning.interface";
import {AbstractFirestoreModularDocResolver} from "@shared/services/abstracts/AbstractFirestoreModularDocResolver";


@Injectable()
export class DunningsResolver extends AbstractFirestoreModularDocResolver<Dunning> {

    paramKeyID = 'dunningID';

    constructor(router: Router, service: DunningsModularService) {
        super(router, service);
    }
}
