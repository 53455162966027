import {CustomerLogo} from '@settings/models/logo.settings.interface';
import {PublicProfileSettings} from '@settings/models/publicProfile.settings.interface';
import {cloneDeep} from 'lodash-es';
import {Letter} from "@letters/models/letter.interface";
import {PropertyUnit} from "../../../../properties/features/units/models/unit.interface";
import {Dunning, DunningTypeOptions} from "../models/dunning.interface";

export interface StandardDunningData {
    content: any;
    dunning: Dunning,
    letter: Letter;
    date: string;
    unit?: PropertyUnit;
    signatureRequired?: boolean;
    tenantsName?: string;
    publicProfile: PublicProfileSettings;
}

const buildTenantsNameBlock = (tenantsName: string): any => {
    if (tenantsName) {
        return {
            text: tenantsName,
            // bold: true,
            color: '#000000',
            alignment: 'left'
        };
    } else {
        return {
            text: 'Bitte ergänzen Sie den Namen des Mieters',
            bold: true,
            background: '#ec2323',
            alignment: 'left'
        };
    }
};

const cloneDataContent = (content: any): any => cloneDeep(content) || '';

const buildContent = (dunning: Dunning, publicProfile: PublicProfileSettings, letter: Letter, content: any, date: string, unit?: PropertyUnit, tenantsName?: string): any[] => [
    {
        columns: [
            putLogo(publicProfile?.logo),
            // renderLogo(),
            createPublicProfileHeader(publicProfile)
        ]
    },
    {
        margin: [0, publicProfile?.logo ? 0 : 28, 0, 5],
        columns: [
            {
                stack: [
                    {
                        text: getMailSignHead(publicProfile),
                        color: '#000000',
                        bold: true,
                        fontSize: 7,
                        alignment: 'left'
                    },
                    // {
                    //   text: 'Wohgemeinschaft',
                    //   // bold: true,
                    //   color: '#333333',
                    //   alignment: 'left'
                    // },
                    buildTenantsNameBlock(tenantsName || unit?.currentPeriod?.tenantsName),
                    {
                        text: `${letter?.property?.address?.streetName} ${letter?.property?.address?.streetNumber}`,
                        // bold: true,
                        // fontSize: 9,
                        color: '#000000',
                        alignment: 'left'
                    },
                    {
                        text: `${letter?.property?.address?.postalCode} ${letter?.property?.address?.locality?.long}`,
                        // bold: true,
                        // fontSize: 9,
                        color: '#000000',
                        alignment: 'left'
                    }
                ]
            },
            {
                stack: [
                    {
                        // text: `Unser Zeichen: H${unit?.h_id}/W${unit?.w_id}`,
                        text: `Unser Zeichen: H${letter?.property?.h_id}${unit ? '/W' + unit?.w_id : ''}`,
                        bold: true,
                        color: '#333333',
                        // fontSize: 9,
                        alignment: 'right',
                        width: 100
                    },
                    {
                        text: `Datum: ${date}`,
                        // text: 'Datum: 01.01.01',
                        bold: true,
                        color: '#333333',
                        // fontSize: 9,
                        alignment: 'right',
                        width: 100
                    },
                    {
                        // text: 'Mahnungs-ID',
                        text: `${dunning?.type === DunningTypeOptions.PAYMENT_REMINDER ? 'Referenz-ID' : 'Mahnungs-ID'}: ${dunning?.refID}`,
                        bold: true,
                        // fontSize: 9,
                        alignment: 'right',
                        color: '#333333',
                        width: 100
                    }
                ]
            }
        ]
    },
    '\n\n',
    {
        width: '100%',
        text: `${letter?.matter?.title || letter?.content?.subject}`,
        bold: true,
        margin: [0, 40, 0, 0],
        fontSize: 13
    },
    '\n\n',
    cloneDataContent(content || '')
];

const buildLetter = (data: StandardDunningData): any[] => {
    console.log('buildLetter', data);
    const contentList: any[] = [];
    if (!!data?.unit) {
        console.log('data unit exists in standard letter', data?.unit);
        contentList.push(...buildContent(data?.dunning, data?.publicProfile, data?.letter, data?.content, data?.date, data?.unit, data?.tenantsName));
    } else {
        console.log('data unit exists in standard letter', data?.unit);
        for (let j = 0; j < data?.letter?.units?.length; j++) {
            console.log('iterating over the units of the letters', j);
            contentList.push(...buildContent(data?.dunning, data?.publicProfile, data?.letter, data?.content, data?.date, data.letter.units[j], data?.tenantsName));
            if (j !== data?.letter?.units?.length - 1) {
                contentList.push({text: '', pageBreak: 'before'});
            }
        }
    }

    console.log('contentList', contentList);
    return contentList;
};

const getName = (profile: PublicProfileSettings): string => {
    return !!profile?.companyName ? profile?.companyName : `${profile?.firstName} ${profile?.lastName}`;
};

const getMailSignHead = (profile: PublicProfileSettings): string => {
    return `${getName(profile)} ${profile?.companyComplement ?? ''} - ${profile?.address?.streetName} ${profile?.address?.streetNumber}, ${profile?.address?.postalCode} ${profile?.address?.locality?.long}`;
};

const createPublicProfileHeader = (profile: PublicProfileSettings) => {
    const profileHeaderRows = [];
    profileHeaderRows.push({
        text: getName(profile),
        color: '#333333',
        width: '*',
        fontSize: 16,
        bold: true,
        alignment: 'right',
        pageMargins: [0, 10, 0, 0]
    });

    if (!!profile?.companyComplement) {
        profileHeaderRows.push(
            {
                text: profile?.companyComplement,
                color: '#333333',
                width: '*',
                // fontSize: 9,
                bold: true,
                alignment: 'right'
            }
        );
    }
    profileHeaderRows.push(
        {
            text: `${profile?.address?.streetName} ${profile?.address?.streetNumber}`,
            color: '#333333',
            width: '*',
            // fontSize: 9,
            alignment: 'right'
        },
        {
            text: `${profile?.address?.postalCode} ${profile?.address?.locality?.long}`,
            color: '#333333',
            width: '*',
            // fontSize: 9,
            alignment: 'right',
            margin: [0, 0, 0, 15]
        }
    );
    return profileHeaderRows;
};

const putLogo = (logo: CustomerLogo) => {
    if (!!logo) {
        return {
            image: 'logo',
            fit: [90, 90],
            absolutePosition: {x: 64, y: 20}
        };
    }
};

const getImageDef = (logo: CustomerLogo) => {
    if (!!logo && !!logo?.url) {
        return {logo: logo?.url};
    }
};

export const STANDARD_DUNNING = (data: StandardDunningData): any => {
    console.log('STANDARD_LETTER --> data', data);
    const margin = 70.8;
    const top = 20;
    console.log('margin margin', margin - top);
    return {
        pageMargins: [margin, margin - top, 56.7, margin],
        content: buildLetter(data),
        footer: {
            text: `${data?.signatureRequired ? '' : 'Dieses Schreiben wurde maschinell erstellt und ist ohne Unterschrift gültig'}`,
            color: '#000000',
            fontSize: 7,
            alignment: 'left',
            margin: [margin, 20, 0, 5]
        },
        // styles: {
        //   notesTitle: {
        //     fontSize: 10,
        //     bold: true,
        //     margin: [0, 50, 0, 3]
        //   },
        //   notesText: {
        //     fontSize: 10
        //   }
        // },
        // images: {
        //   // in browser is supported loading images via url (https or http protocol) (minimal version: 0.1.67)
        //   logo: data?.publicProfile?.logo?.url,
        //   // logo: 'https://picsum.photos/seed/picsum/200/300',
        // },
        images: getImageDef(data?.publicProfile?.logo),
        defaultStyle: {
            // columnGap: 20,
            pageSize: 'A4',
            pageMargins: [margin, 60, 56.7, 60]
            // font: 'Quicksand',
        }
    };
};
