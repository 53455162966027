import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { BaseWidget, NgAisIndex, NgAisInstantSearch } from 'angular-instantsearch';
import { connectAutocomplete } from 'instantsearch.js/es/connectors';

@Component({
  selector: 'app-algolia-autocomplete',
  templateUrl: './algolia-autocomplete.component.html',
  styleUrls: ['./algolia-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlgoliaAutocompleteComponent extends BaseWidget implements OnInit {

  @ContentChild(TemplateRef) public template?: TemplateRef<any>;

  @Input() formFieldClass: string;
  @Input() placeholder: string = 'Suchen...';
  @Input() matOptionStyles: string;
  @Input() label: string;
  @Input() hint: string;

  // @ts-ignore
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onQuerySuggestionClick = new EventEmitter<any>();


  state: {
    query: string;
    refine: Function;
    indices: object[];
  };

  ngOnInit(): void {
    this.createWidget(connectAutocomplete, {});
    super.ngOnInit();
  }

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('AutocompleteComponent');
  }

  public handleChange($event: KeyboardEvent): void {
    this.state.refine(($event.target as HTMLInputElement).value);
  }

}
