import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { Observable } from 'rxjs';
import { NahausModulesPermission } from '../../modules/admin/users/models/user.interface';

@Pipe({
  name: 'hasPermission'
})
export class HasPermissionPipe implements PipeTransform {

  constructor(private auth: AuthService) {
  }

  transform(value: NahausModulesPermission): Observable<boolean> {
    return this.auth?.hasPermissionOrRedirect(value, false);
  }

}
