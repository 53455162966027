import { Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Hit } from 'angular-instantsearch/instantsearch/instantsearch';

@Directive({
  selector: '[appHitsListener]'
})
export class HitsListenerDirective implements OnChanges {

  @Input()
  hits: Hit[];

  @Input()
  results: {};

  @Output()
  onNewHits: EventEmitter<Hit[]> = new EventEmitter<Hit[]>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('HitsListenerDirective on change', changes.hits);
    if (changes.hits) {
      if (changes.hits.currentValue.length > 0) {
        this.onNewHits.next(changes.hits.currentValue);
      } else {
        this.onNewHits.next(null);
      }
    }

  }

}
