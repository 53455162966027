import {Pipe, PipeTransform} from '@angular/core';
import {BookingArt, BookingArtOptions} from "../../modules/admin/booking/models/booking";

@Pipe({
    name: 'bookingArtText'
})
export class BookingArtTextPipe implements PipeTransform {

    transform(value: BookingArt): string {
        switch (value) {
            case BookingArtOptions.WARM_MIETE:
                return 'Warm Miete';
            case BookingArtOptions.KALT_MIETE:
                return 'Kalt Miete';
            case BookingArtOptions.BK:
                return 'Betriebskosten';
            case BookingArtOptions.HK:
                return 'Heizkosten';
            case BookingArtOptions.KAUTION:
                return 'Kaution';
            case BookingArtOptions.DUNNING_FEES:
                return 'Mahngebühren';
            case BookingArtOptions.SP:
                return 'Sonderposten';
            case BookingArtOptions.INTEREST_EXPENSES:
                return 'Zinsaufwendungen';
            default:
                'Unbekannt';
        }
        return null;
    }

}
