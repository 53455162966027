import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {fuseAnimations} from '@fuse/animations';
import {firstValueFrom, Subject, take} from 'rxjs';
import {convertTimestamp} from '../../../../../helpers';
import {
    PropertyUnitPeriod
} from '../../../properties/features/units/features/period/models/property.unit.period.interface';
import {Booking, BookingStatus, BookingStatusOptions, BookingTypeOptions} from '../../models/booking';
import {BookingPayment} from '../../models/bookingPayment';
import {BookingPaymentsService} from '../../services/bookingPayments.service';
import {PeriodsService} from "../../../properties/features/units/features/period/services/periods.service";

export interface CreateBookingPaymentDialog {
    propertyID: string;
    unitID: string;
    period: PropertyUnitPeriod;
    booking: Booking;
    requestedStatus?: BookingStatus;
}

@Component({
    selector: 'fuse-confirmation-dialog',
    templateUrl: './create-booking-payment-dialog.component.html',
    styles: [
        `
            .fuse-confirmation-dialog-panel {

                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {

                    .mat-mdc-dialog-surface {
                        padding: 0 !important;
                    }
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [BookingPaymentsService]
})
export class CreateBookingPaymentDialogComponent implements OnInit, OnDestroy {

    today = new Date();

    propertyID: string;
    unitID: string;
    period: PropertyUnitPeriod;
    booking: Booking;

    bookingTypeOptions = BookingTypeOptions;

    loading: boolean;
    saving: boolean;
    showError: boolean;
    errorMsg: string;
    bookingPaymentFormGroup: FormGroup;
    bookingPaymentsFormArray: FormArray;
    loadingBookingsPayments: boolean;
    deletingPaymentID: string;
    deletingPayment: boolean;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: CreateBookingPaymentDialog,
                private matDialogRef: MatDialogRef<CreateBookingPaymentDialogComponent>,
                private formBuilder: FormBuilder,
                private bookingPaymentsService: BookingPaymentsService,
                private periodsService: PeriodsService,
                private snackbar: MatSnackBar) {
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.booking = this.data?.booking;

        this.propertyID = this.data?.propertyID || this.booking?.propertyID;
        this.unitID = this.data?.unitID || this.booking?.unitID;
        this.period = this.data?.period;

        // this.loading = true;
        // return;

        if (!this.period) {
            console.log("period is not available", this.booking)
            this.loading = true;
            firstValueFrom(this.periodsService.docWithParent(`properties/${this.propertyID}/units/${this.unitID}`, this.booking?.periodID).get())
                .then((res) => {
                    if (res.exists) {
                        this.period = res.data() as PropertyUnitPeriod;
                        console.log("period received --> ", this.period);
                        this.loading = false;
                        this.init();
                    } else {
                        this.snackbar.open('2Etwas ist schief gelaufen, bitte versuchen Sie es später erneut oder wenden Sie sich an den Support (Die Vermietung konnte nicht geladen werden)', 'OK', {duration: 10000});
                        this.matDialogRef.close('cancelled');
                    }
                })
                .catch((err) => {
                    console.error("Error --> ", err);
                    this.snackbar.open('3Etwas ist schief gelaufen, bitte versuchen Sie es später erneut oder wenden Sie sich an den Support', 'OK', {duration: 10000});
                    this.matDialogRef.close('cancelled');
                })
        } else {
            this.init();
        }
    }

    init(): void {
        if (!(this.propertyID && this.unitID) && this.booking?.periodID) {
            this.snackbar.open('1Etwas ist schief gelaufen, bitte versuchen Sie es später erneut oder wenden Sie sich an den Support', 'OK', {duration: 10000});
            this.matDialogRef.close('cancelled');
        }

        console.log('CreateBookingDialogComponent --> propertyID --> ', this.propertyID);
        console.log('CreateBookingDialogComponent --> unitID --> ', this.unitID);
        console.log('CreateBookingDialogComponent --> period --> ', this.period);
        console.log('CreateBookingDialogComponent --> booking --> ', this.booking);

        this.bookingPaymentsService.setParentPath(this.propertyID, this.unitID, this.period?.id, this.booking?.id);
        // this.createBookingPaymentFG();
        this.bookingPaymentsFormArray = this.formBuilder.array([]);
        this.bookingPaymentFormGroup = this.formBuilder.group({
            payments: this.bookingPaymentsFormArray
        });
        this.loadData();
        this.listenOnChanges();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    createBookingPaymentFG(bookingPayment?: BookingPayment): FormGroup {
        return this.formBuilder.group({
            id: bookingPayment?.id || this.bookingPaymentsService.createID(),
            customerID: bookingPayment?.customerID || this.bookingPaymentsService.authService?.customerID || null,
            createdAt: bookingPayment?.createdAt || this.today,
            propertyID: bookingPayment?.propertyID || this.propertyID,
            unitID: bookingPayment?.unitID || this.unitID,
            periodID: bookingPayment?.periodID || this.period?.id,
            bookingID: bookingPayment?.bookingID || this.booking?.id,
            date: [bookingPayment?.date ? convertTimestamp(bookingPayment.date) : this.today, Validators.required],
            amount: [bookingPayment?.amount || (this.data?.requestedStatus === BookingStatusOptions.PAID ? this.booking?.amount : null), Validators.required],
            text: bookingPayment?.text || this.booking?.text,
            art: this.booking?.art
        });
    }

    listenOnChanges(): void {
        this.loadingBookingsPayments = true;
        this.bookingPaymentsService.collection(query => query.orderBy('date', 'asc'))
            .get()
            .pipe(take(1))
            .subscribe((data) => {
                if (data?.size > 0) {
                    data.forEach(doc => this.bookingPaymentsFormArray.push(this.createBookingPaymentFG(doc?.data() as BookingPayment)));
                } else {
                    this.bookingPaymentsFormArray.push(this.createBookingPaymentFG());
                }
                this.loadingBookingsPayments = false;
            });
    }

    addPayment(bookingPayment?: BookingPayment): void {
        // const paymentsFormArry = this.
        this.bookingPaymentsFormArray.push(this.createBookingPaymentFG(bookingPayment));
    }

    isPaymentBeingRemoved(index: number): boolean {
        const bookingPayment: BookingPayment = this.bookingPaymentsFormArray.at(index).getRawValue() as BookingPayment;
        return bookingPayment?.id === this.deletingPaymentID;
    }

    removePayment(index: number): void {
        const bookingPayment: BookingPayment = this.bookingPaymentsFormArray.at(index).getRawValue() as BookingPayment;
        this.deletingPaymentID = bookingPayment?.id;
        this.deletingPayment = true;

        // check if it exists in firestore
        this.bookingPaymentsService.doc(bookingPayment?.id)
            .get()
            .pipe(take(1))
            .subscribe(res => {
                if (res?.exists) {
                    res.ref.delete().then(() => this.finalizeRemovePayment(index));
                } else {
                    this.finalizeRemovePayment(index);
                }
            });
    }

    finalizeRemovePayment(index: number) {
        this.deletingPaymentID = null;
        this.deletingPayment = false;
        this.bookingPaymentsFormArray.removeAt(index);
    }

    loadData(): void {

    }

    createBookingPayments(): void {

        if (this.bookingPaymentsFormArray.invalid) {
            this.snackbar.open('Bitte überprüfen Sie Ihre Eingaben!', 'OK', {duration: 5000});
            this.bookingPaymentsFormArray.markAllAsTouched();
            return;
        }

        const payments: BookingPayment[] = this.bookingPaymentsFormArray?.getRawValue() as BookingPayment[];
        console.log('payments form formarray = ', payments);

        if (!(payments?.length > 0)) {
            this.matDialogRef.close(undefined);
            return;
        }

        // return;

        this.showError = false;
        this.errorMsg = null;
        this.saving = true;

        const promiseList: Promise<void>[] = payments.map((payment) => this.bookingPaymentsService.doc(payment?.id).set(payment, {merge: true}));
        Promise
            .all(promiseList)
            .then(() => {
                this.snackbar.open('Ihre Buchung wurde erfolgreich in die Sollstellung übertragen.', 'OK', {duration: 5000});
            })
            .catch((err) => {
                this.errorMsg = err;
                this.showError = true;
            })
            .finally(() => {
                this.saving = false;
                this.matDialogRef.close('confirmed');
            });
    }

}
