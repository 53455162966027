import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { FuseLoadingService } from '@fuse/services/loading';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

declare var rewardful: any;
declare var Rewardful: any;

// declare var window.Rewardful: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   *
   */
  constructor(private translate: TranslateService,
              private router: Router,
              private _fuseLoadingService: FuseLoadingService,
              private gtmService: GoogleTagManagerService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('de');

    // push GTM data layer for every visited page
    // if (environment.pool) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {

      const gtmTag = {
        event: 'page',
        pageName: event.urlAfterRedirects
      };

      // console.log('pushTag --> ', gtmTag);

      this.gtmService.pushTag(gtmTag);

    });
    // }


    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this._fuseLoadingService.show();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this._fuseLoadingService.hide();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit(): void {
    rewardful('ready', () => {
      console.log('Rewardful Ready!');
      if (Rewardful.referral) {
        // The current website visitor is a referral from an affiliate.
        // @ts-ignore
        console.log('rewardful 1 = ', window.Rewardful && window.Rewardful.referral || ('checkout_' + (new Date).getTime()));
        // @ts-ignore
        console.log('rewardful 2 = ', window.Rewardful, window.Rewardful.referral);
        console.log('rewardful 3 = ', Rewardful);
        console.log('rewardful 4 = ', rewardful);
        // rewardful('convert', { email: 'test@nahaus.de' });
      } else {
        console.log('no referral found');
      }
    });
    // console.log('current lang', this.translate.currentLang);
    // console.log('AppComponent ngOnInit');
    // console.log('rewardful = ', rewardful);
    // // @ts-ignore
    // console.log('rewardful 1 = ', window.Rewardful && window.Rewardful.referral || ('checkout_' + (new Date).getTime()));

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    console.log('AppComponent ngOnDestroy');
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
