import { Pipe, PipeTransform } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Pipe({
  name: 'toColorPalette'
})
export class ToColorPalettePipe implements PipeTransform {

  transform(hasError: boolean, color: ThemePalette = 'primary'): ThemePalette {
    return hasError ? 'warn' : color;
  }

}
