import {Injectable} from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Router} from '@angular/router';
import {environment} from '@env/environment';
import {FuseConfigService} from '@fuse/services/config';
import {Store} from '@ngrx/store';
import {NahausQuota} from '@quota/models';
import {NahausSubscription} from '@subscriptions/models/nahaus-subscription.interface';
import {
    NahausModulesPermission,
    NahausModulesPermissions,
    NahausTier,
    NahausTiersOptions,
    PermissionOptions,
    User,
    UserRole,
    UserRoleOptions
} from '@users/models/user.interface';
import {SearchClient} from 'algoliasearch/dist/algoliasearch-lite';
import algoliasearch from 'algoliasearch/lite';
import firebase from 'firebase/compat';
import {pick} from 'lodash-es';
import {
    BehaviorSubject,
    combineLatest,
    concatMap,
    firstValueFrom,
    from,
    mergeMap,
    Observable,
    of,
    Subject,
    take
} from 'rxjs';
import {debounceTime, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {AppConfig} from '../../core/config/app.config';
import {
    bannedToUpgrade,
    receivedCustomerID,
    resetUserFirestore,
    userNahausRoleReceived,
    userReceivedFromFirebaseAuthentication,
    userReceivedFromFirestore
} from '../../store/actions/app.actions';
import IdTokenResult = firebase.auth.IdTokenResult;

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    SUPPORT_CUSTOMER_ID: string | null = 'null';

    currentUser$: Observable<User>;
    user$: Observable<firebase.User> = this.auth.authState;
    authUser$: BehaviorSubject<firebase.User> = new BehaviorSubject<firebase.User>(null);
    firestoreUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    tier$: BehaviorSubject<NahausTier> = new BehaviorSubject<NahausTier>(undefined);
    customerID$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    permissions$: BehaviorSubject<NahausModulesPermissions> = new BehaviorSubject<NahausModulesPermissions>(null);
    userRole$: BehaviorSubject<UserRole> = new BehaviorSubject<UserRole>(null);
    isSubscribedUser$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    quota$: BehaviorSubject<NahausQuota> = new BehaviorSubject(null);
    idToken$: BehaviorSubject<IdTokenResult> = new BehaviorSubject(null);
    algoliaClient$: BehaviorSubject<SearchClient> = new BehaviorSubject(null);
    trialSubscription$: BehaviorSubject<NahausSubscription> = new BehaviorSubject<NahausSubscription>(null);

    claims: {
        [key: string]: any;
    };

    // RXJS
    unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(public auth: AngularFireAuth,
                public afs: AngularFirestore,
                public fns: AngularFireFunctions,
                private store: Store,
                private fuseConfigService: FuseConfigService,
                private router: Router,
                private analytics: AngularFireAnalytics) {
        console.log('AuthService on constructor');
        this.initUserListener();
        // const callable = this.fns.httpsCallable('institutionsPool-getAllInstitutionsFromNordigen');
        // callable({}).subscribe(() => console.log(''));

        // this.auth.sendPasswordResetEmail()
    }

    private _authUser: firebase.User;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get authUser(): firebase.User {
        return this._authUser;
    }

    set authUser(value: firebase.User) {
        this._authUser = value;
        this.authUser$.next(value);
        // if (!!value) {
        this.store.dispatch(userReceivedFromFirebaseAuthentication({user: pick(value, ['uid', 'displayName', 'email', 'emailVerified', 'photoURL', 'tenantID', 'providerID'])}));
        if (!value) {
            this.firestoreUser = null;
        } else {
            // this.analytics.setUserId(value?.uid); // firebase analytics will do it automatically
        }
        // }
    }

    private _firestoreUser: User;

    get firestoreUser(): User {
        return this._firestoreUser;
    }

    set firestoreUser(value: User) {
        this._firestoreUser = value;
        this.firestoreUser$.next(value);
        this.permissions$.next(value?.permissions ?? []);
        this.userRole$.next(value?.role ?? UserRoleOptions.NONE);
        if (!value) {
            this.store.dispatch(resetUserFirestore());
        } else {
            this.store.dispatch(userReceivedFromFirestore({user: value}));
        }
    }

    private _customerID: string;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get customerID(): string {
        return this._customerID;
    }

    set customerID(value: string) {
        this._customerID = value;
        this.customerID$.next(value);
        this.store.dispatch(receivedCustomerID({customerID: value}));
    }

    private _tier: NahausTier;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get tier(): NahausTier {
        return this._tier;
    }

    set tier(value: NahausTier) {
        console.log('set tier --> ', value);
        this._tier = value;
        this.store.dispatch(userNahausRoleReceived({role: value}));
        this.tier$.next(value);
        this.isSubscribedUser$.next([NahausTiersOptions.STARTER, NahausTiersOptions.PRO, NahausTiersOptions.ENTERPRISE].includes(this.tier));
        this.analytics.setUserProperties({tier: value});
        if (![NahausTiersOptions.STARTER, NahausTiersOptions.PRO, NahausTiersOptions.ENTERPRISE].includes(this.tier)) {
            this.router.navigate(['/upgrade']);
        }
    }

    private _isSubscribedUser: boolean;

    get isSubscribedUser(): boolean {
        return [NahausTiersOptions.STARTER, NahausTiersOptions.PRO, NahausTiersOptions.ENTERPRISE].includes(this.tier);
    }

    set isSubscribedUser(value: boolean) {
        this._isSubscribedUser = value;
        this.isSubscribedUser$.next(value);
    }

    private _quota: NahausQuota;

    get quota(): NahausQuota {
        return this._quota;
    }

    set quota(value: NahausQuota) {
        this.quota$.next(value);
        this._quota = value;
    }

    private _idToken: IdTokenResult;

    get idToken(): firebase.auth.IdTokenResult {
        return this._idToken;
    }

    set idToken(value: firebase.auth.IdTokenResult) {
        this.idToken$.next(value);
        this._idToken = value;
    }

    private _algoliaClient: SearchClient;

    get algoliaClient(): SearchClient {
        return this._algoliaClient;
    }

    set algoliaClient(value: SearchClient) {
        console.log('algoliaClient received: ', value);
        this.algoliaClient$.next(value);
        this._algoliaClient = value;
    }

    buildPath = (path: string): string => this.customerID ? `customers/${this.customerID}/${path}` : path;

    getAlgoliaSearchClient(allowNullQuery = true) {
        // console.log('this.algoliaClient 1 --> ', this.algoliaClient);
        const x = this.algoliaClient;
        if (allowNullQuery) {
            return this.algoliaClient;
        } else {
            return {
                // tslint:disable-next-line:typedef
                search(requests: any): any {
                    if (requests.every(({params}) => !params.query)) {
                        return Promise.resolve({
                            results: requests.map(() => ({
                                hits: [],
                                nbHits: 0,
                                processingTimeMS: 0
                            }))
                        });
                    }
                    console.log('this.algoliaClient 2 --> ', x);
                    return x.search(requests);
                }
            };
        }
    }

    isMasterUser(): boolean {
        return this.authUser?.uid === this.customerID || this.customerID === this.SUPPORT_CUSTOMER_ID;
    }

    isAdminUser(): boolean {
        return this.firestoreUser?.role === UserRoleOptions.ADMIN;
    }

    hasGreenPass(): boolean {
        return this.isMasterUser() || this.isAdminUser();
    }

    hasPermissionOrRedirect(value: NahausModulesPermission, redirect: boolean): Observable<boolean> {
        return this.permissions$.pipe(filter(permissions => !!permissions), take(1), concatMap(permission => permission?.includes(value) || this.hasGreenPass() ? of(true) : redirect ? this.router.navigate(['fehler', '403'], {queryParams: {permission: value}}) : of(false)));
    }

    hasPermissionPromise(value: NahausModulesPermission): Promise<boolean> {
        return firstValueFrom(this.permissions$.pipe(filter(permissions => this.hasGreenPass() || permissions?.length > 0), take(1), concatMap(permission => of(permission?.includes(value) || this.hasGreenPass()))));
    }

    hasPermission(value: NahausModulesPermission): boolean {
        return this.hasGreenPass() || this.firestoreUser?.permissions?.includes(value);
    }

    isOwner(redirect: boolean): Observable<boolean> {
        return this.userRole$.pipe(filter(role => !!role), take(1), concatMap((role: UserRole) => role === UserRoleOptions.OWNER ? of(true) : redirect ? this.router.navigate(['fehler', '403']) : of(false)));
    }

    isAdmin(redirect: boolean): Observable<boolean> {
        return this.userRole$.pipe(filter(role => !!role), take(1), concatMap((role: UserRole) => role === UserRoleOptions.ADMIN ? of(true) : redirect ? this.router.navigate(['fehler', '403']) : of(false)));
    }

    isAdminOrOwner(redirect: boolean): Observable<boolean> {
        return this.userRole$.pipe(filter(role => !!role), take(1), concatMap((role: UserRole) => [UserRoleOptions.OWNER, UserRoleOptions.ADMIN].includes(role) ? of(true) : redirect ? this.router.navigate(['fehler', '403']) : of(false)));
    }

    getCurrentUser(): Observable<User> {
        console.log('AuthService.getCurrentUser2()');
        return combineLatest([
            this.user$.pipe(takeUntil(this.unsubscribeAll),
                debounceTime(350),
                tap(user => this.authUser = user),
                filter((user: firebase.User) => !!user && user?.uid !== this.firestoreUser?.uid),
                tap((user: firebase.User) => from(this.getCustomClaimRole(user)))
            ),
            this.auth.idTokenResult
                .pipe(takeUntil(this.unsubscribeAll)
                    // filter(token => !!token)
                )
        ]).pipe(
            takeUntil(this.unsubscribeAll),
            debounceTime(500),
            // filter(([user, idTokenResult]: [firebase.User, IdTokenResult]) => !!user),
            map(([user, idTokenResult]: [firebase.User, IdTokenResult]) => !!user ? [user, idTokenResult] : ['/anmelden', '/registrieren', '/bestaetigung-erforderlich'].includes(this.router?.url) || !this.authUser ? [null, null] : this.router.navigate(['/abmelden'])),
            // filter(([user, idTokenResult]: [firebase.User, IdTokenResult]) => !!user && user?.uid !== this.firestoreUser?.uid),
            switchMap(([user, idTokenResult]: [firebase.User, IdTokenResult]) => {
                if (!user) {
                    return of(null);
                }
                this.idToken$.next(idTokenResult);
                console.log('getCurrentUser2.switchMap', user, idTokenResult);
                // this.authUser = user;
                const targetPath = `users/${user?.uid}`;
                const customerID = idTokenResult?.claims?.customerID as string;

                if (environment.pool) {
                    if (customerID) {
                        console.log('idTokenResult --> customerID -->', customerID);
                        // this.customerID = this.SUPPORT_CUSTOMER_ID;
                        this.customerID = customerID;
                        const poolPath = `customers/${customerID}/${targetPath}`;
                        console.log('pool nahaus.de user', poolPath);
                        return this.afs.doc<User>(poolPath).valueChanges().pipe(takeUntil(this.unsubscribeAll));
                    } else {
                        // go to error page if current page not anmelden oder registieren
                        console.log('go to error page because customerID is not found');
                        return of(null);
                    }
                } else {
                    console.log('normal nahaus.de user');
                    if (this.auth.currentUser) {
                        return this.afs.doc<User>(targetPath).valueChanges().pipe(takeUntil(this.unsubscribeAll));
                    } else {
                        return of(null);
                    }
                }
            })
        );
    }

    setConfig(appearance: AppConfig): void {
        // console.log('setConfig', appearance);
        if (appearance?.theme) {
            this.fuseConfigService.config = {theme: appearance.theme};
        }
        if (appearance?.scheme) {
            this.fuseConfigService.config = {scheme: appearance.scheme};
        }
    }

    async resendConfirmationEmail(): Promise<any> {
        return await this.authUser.sendEmailVerification();
    }

    async refreshCustomClaims(): Promise<firebase.auth.IdTokenResult> {
        console.log('refreshCustomClaims is now running');
        try {
            const currentUser = await this.auth?.currentUser;
            // const idToken = await currentUser?.getIdToken(true);
            const idTokenResult = await currentUser?.getIdTokenResult(true);
            console.log('refreshCustomClaims:idTokenResult', idTokenResult);
            console.log('refreshCustomClaims:idTokenResult.claims', idTokenResult?.claims);
            this.tier = idTokenResult?.claims?.stripeRole || (environment?.pool ? null : NahausTiersOptions.ENTERPRISE);
            return idTokenResult;
        } catch (err) {
            console.error('Error', err);
        }
    }

    // Get custom claim role helper
    async getCustomClaimRole(user: firebase.User): Promise<firebase.User> {
        console.log('getCustomClaimRole started');
        try {
            const decodedToken = await user?.getIdTokenResult(true);
            console.log('getCustomClaimRole started 2 --> ', decodedToken, decodedToken?.claims?.stripeRole);
            this.tier = decodedToken?.claims?.stripeRole || (environment?.pool ? null : NahausTiersOptions.ENTERPRISE);
            console.log('getCustomClaimRole started 3 --> ', this.tier);
        } catch (e) {
            console.error('Error', e);
        }
        return user;
    }

    completeSubscription(): void {
        console.log('completeSubscription()');
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }

    reInitSubscription(): void {
        this.completeSubscription();
        this.resetEveryThing();
        this.initUserListener();
    }

    resetEveryThing(): void {
        this.unsubscribeAll = new Subject<any>();
        this.customerID = null;
        this.quota = null;
        this.tier = null;
        this.isSubscribedUser = null;
        this.idToken = null;
        this.algoliaClient = null;

    }

    initUserListener(): void {
        this.listenToQuota();
        this.getAlgoliaCustomSearchKey()
            .then((client) => {
                this.algoliaClient = client;
            })
            .catch((err) => console.error('Error: ', err));
        this.currentUser$ = this.getCurrentUser();
        this.currentUser$.subscribe((user) => {
            console.log('user on switch map', user);
            if (!this.authUser && this.firestoreUser) {
                return;
                // return this.auth.signOut();
            } else if (!this.authUser) {
                return;
            }

            if (!this.firestoreUser) {
                this.setConfig(user?.settings?.appearance);
            }

            this.firestoreUser = user;
            // console.log('firestoreUser', this.firestoreUser);;
        }, error => console.error('Error: ', error));
    }

    getAlgoliaCustomSearchKey(): Promise<SearchClient> {
        if (environment?.pool) {
            return firstValueFrom(this.idToken$
                .pipe(
                    filter(tokenResult => !!tokenResult),
                    take(1), // after sign out and sign in --> recall the function getAlgoliaCustomSearchKey
                    takeUntil(this.unsubscribeAll),
                    tap((tokenResult) => console.log('getAlgoliaCustomSearchKey from cloud functions', tokenResult)),
                    mergeMap((tokenResult: IdTokenResult) => {
                        console.log('token for algolia: ', tokenResult);
                        return of(fetch('https://europe-west3-' + environment?.firebase?.projectId + '.cloudfunctions.net/algoliaPool-getSearchKey', {
                            headers: {Authorization: 'Bearer ' + tokenResult?.token}
                        }));
                    }),
                    mergeMap(promise => {
                        return promise
                            .then(res => res.json()
                                .then(data => {
                                    console.log('getSearchKey data --> ', data);
                                    return algoliasearch(
                                        environment.algolia.applicationId,
                                        data.key);
                                })
                            );
                    })
                ));
            //
            // this.idToken$
            //   .pipe(
            //     filter(tokenResult => !!tokenResult),
            //     take(1), // after sign out and sign in --> recall the function getAlgoliaCustomSearchKey
            //     tap(() => console.log('listening on quota changes'))
            //   ).subscribe((tokenResult) => {
            //   // The token is then passed to our getSearchKey Cloud Function
            //   // https://europe-west3-pool-dev-nahaus-de.cloudfunctions.net/algoliaPool-getSearchKey
            //   fetch('https://europe-west3-' + environment?.firebase?.projectId + '.cloudfunctions.net/algoliaPool-getSearchKey/', {
            //     headers: { Authorization: 'Bearer ' + tokenResult }
            //   }).then(function(response) {
            //     // The Fetch API returns a stream, which we convert into a JSON object.
            //     return response.json();
            //   }).then(function(data) {
            //     // Data will contain the restricted key in the `key` field.
            //     return algoliasearch(
            //       environment.algolia.applicationId,
            //       data.key
            //     );
            //     // client = algoliasearch(ALGOLIA_APP_ID, data.key);
            //     // index = client.initIndex('notes');
            //     // Perform the search as usual.
            //     // return index.search({query});
            //   }).catch((err) => console.error('Error in getAlgoliaCustomSearchKey --> ', err));
            // });
        } else {
            return new Promise<SearchClient>(resolve => resolve(algoliasearch(
                environment.algolia.applicationId,
                environment.algolia.searchApiKey)
            ));
        }
    }

    // todo: 17.10.22 --> adapt this code for JUG
    listenToQuota(): void {
        if (environment?.pool) {
            this.customerID$
                .pipe(
                    filter(id => !!id),
                    takeUntil(this.unsubscribeAll),
                    tap(() => console.log('listening on quota changes')),
                    switchMap(customerID => this.afs.doc(`customers/${customerID}/quota/${customerID}`).valueChanges().pipe(takeUntil(this.unsubscribeAll)))
                ).subscribe((quota: NahausQuota) => {
                console.log('nahaus quota received', quota);
                this.quota = quota;

                if (this.quota?.bannedToUpgrade) {
                    this.router.navigate(['fehler', '426']);
                    this.store.dispatch(bannedToUpgrade());
                }
            });
        } else {
            this.afs
                .doc(`quota/${environment?.customer}`)
                .valueChanges().pipe(takeUntil(this.unsubscribeAll))
                .subscribe((quota: NahausQuota) => {
                    console.log('nahaus quota received', quota);
                    this.quota = quota;

                    if (this.quota?.bannedToUpgrade) {
                        this.router.navigate(['fehler', '426']);
                        this.store.dispatch(bannedToUpgrade());
                    }
                });

        }
    }

    checkPermission(permission: PermissionOptions): boolean {
        return [UserRoleOptions.OWNER, UserRoleOptions.ADMIN].includes(this.firestoreUser?.role) || this.firestoreUser?.permissions.includes(permission);
    }

}
