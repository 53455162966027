import {FileStatus} from './constants';

export class DropZoneFile<T = any> {
    file: File;
    status: FileStatus;
    error: string[];
    progress: number;

    constructor(file: File);

    constructor(file: File, status?: FileStatus, error?: number) {
        console.log("DropZoneFile file --> ", file)
        {
            this.file = file;
            this.status = FileStatus.Ready;
            this.error = [];
            this.loaded = 0;
            this.progress = 0;
        }

    }

    private _loaded = 0;

    public get loaded(): number {
        return this._loaded;
    }

    public set loaded(v: number) {
        this.progress = +((v * 100) / (this.file.size || 1)).toPrecision(2);
        this._loaded = v;
    }
}

export type ResponseTypes = 'blob' | 'arraybuffer' | 'text' | 'json' | undefined;

export interface ChunkInfo {
    totalChunks: number;
    currentChunk: number;
}

export interface ValidatorFunction {
    fn: (file: DropZoneFile) => boolean;
    errorMessage: string;
}

export interface AvatarCropper {
    width: number;
    height: number;
    round: boolean;
    srcImage?: any;
}

