import {environment} from '@env/environment';
import {FuseNavigationItem} from '@fuse/components/navigation';
import {NahausTier, NahausTiersOptions} from '@users/models/user.interface';
import {ManagementType, Property} from '../modules/admin/properties/models/property.interface';

export const accountingNavItems: FuseNavigationItem[] = [
    {
        id: 'accounting',
        title: 'Buchhaltung',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'bank-accounts',
                title: 'Bankkonten',
                type: 'basic',
                icon: 'mat_outline:account_balance',
                link: '/bankkonten',
                permissions: ['owner,admin, vermietung']
            },
            {
                id: 'bank-Transaktionen',
                title: 'Transaktionen',
                type: 'basic',
                icon: 'mat_outline:timeline',
                link: '/transaktionen',
                permissions: ['owner,admin, vermietung'],
                // badge: {
                //     title: 'Beta',
                //     classes: 'px-2 bg-primary text-on-primary rounded'
                // }
                // disabled: environment.pool && environment.production
            },
            {
                title: 'Sollstellung',
                type: 'basic',
                icon: 'mat_outline:payments',
                link: '/buchungen/sollstellung',
                // exactMatch: false,
                // isActiveMatchOptions: {
                //     paths: 'exact',
                //     matrixParams: 'ignored',
                //     fragment: 'ignored',
                //     queryParams: 'ignored'
                // }
                // exactMatch: false,
            },
            {
                title: 'Offene Posten',
                type: 'basic',
                icon: 'mat_outline:money_off',
                link: '/buchungen/op',
                // exactMatch: true
            },
            {
                title: 'Kaution',
                type: 'basic',
                icon: 'mat_outline:money',
                link: '/buchungen/kaution',
            },
            {
                title: 'Ausgaben',
                type: 'basic',
                icon: 'mat_outline:pie_chart',
                link: '/ausgaben',
                exactMatch: true,
                badge: {
                    title: 'Beta',
                    classes: 'px-2 bg-primary text-on-primary rounded'
                },
                // disabled: environment.production,
                // hidden: (() => environment.production),
            },
            {
                title: 'Buchungskonten',
                type: 'basic',
                icon: 'mat_outline:account_tree',
                link: '/buchungskonten',
                tooltip: 'Verwaltung der Buchungskonten innerhalb des Kontenrahmens. Hier können Sie Konten bearbeiten, hinzufügen oder löschen.',
                exactMatch: true,
                badge: {
                    title: 'Beta',
                    classes: 'px-2 bg-primary text-on-primary rounded'
                },
                // disabled: environment.production,
                // hidden: (() => environment.production),
            },
            // {
            //     title: 'Rechnungen',
            //     type: 'basic',
            //     icon: 'mat_outline:money_off',
            //     link: '/buchungen/op',
            //     exactMatch: true,
            //     disabled: environment.pool && environment.production,
            //     hidden: () => environment.production
            // },

            {
                id: 'pages.invoice',
                // title: 'Rechnungen erstellen',
                title: 'Rechnungen',
                type: 'collapsable',
                icon: 'heroicons_outline:calculator',
                children: [
                    {
                        title: 'Erstellen',
                        type: 'basic',
                        icon: 'mat_outline:money_off',
                        link: '/invoice',
                        exactMatch: true,
                        disabled: environment.pool && environment.production,
                        hidden: () => environment.production
                    },
                    {
                        id: 'pages.invoice.printable',
                        title: 'Printable',
                        type: 'collapsable',
                        children: [
                            {
                                id: 'pages.invoice.printable.compact',
                                title: 'Compact',
                                type: 'basic',
                                link: '/invoice/printable/compact',
                            },
                            {
                                id: 'pages.invoice.printable.modern',
                                title: 'Modern',
                                type: 'basic',
                                link: '/invoice/printable/modern',
                            },
                        ],
                    },
                ],
                disabled: environment.pool && environment.production,
                badge: {
                    title: 'Demnächst',
                    classes: 'px-2 bg-primary text-on-primary rounded'
                },
                hidden: () => environment.production
            },
            // {
            //     id: 'online-banking',
            //     title: 'Online Banking',
            //     type: 'basic',
            //     icon: 'mat_outline:private_connectivity',
            //     link: '/bankkonten',
            //     hidden: (() => environment.production),
            //     badge: {
            //         title: 'Beta',
            //         classes: 'px-2 bg-primary text-on-primary rounded'
            //     },
            // },
        ]
    }
];

export const verwaltungNavItems: FuseNavigationItem[] = [
    {
        id: 'management',
        title: 'Verwaltung',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'dashboards',
                title: 'Dashboard',
                type: 'basic',
                icon: 'mat_outline:space_dashboard',
                link: '/dashboards',

                permissions: ['owner,admin, vermietung']
            },
            {
                id: 'properties',
                title: 'Immobilien',
                type: 'basic',
                icon: 'mat_outline:apartment',
                link: '/immobilien',
                permissions: ['owner,admin, vermietung']
            },
            {
                id: 'eigentuemer',
                title: 'Eigentümer',
                type: 'basic',
                icon: 'mat_outline:groups',
                link: '/eigentuemer',
                permissions: ['owner,admin, vermietung']
            },
            {
                id: 'managers',
                title: 'Verwalter',
                type: 'basic',
                icon: 'mat_outline:supervisor_account',
                link: '/verwalter'
            },
            {
                id: 'contacts',
                title: 'Kontakte',
                tooltip: 'Verwalten Sie alle Ihre wichtigen Kontakte an einem Ort – ob Handwerker, Hausmeister, Geschäftspartner oder andere relevante Personen.',
                type: 'basic',
                icon: 'mat_outline:person_pin',
                link: '/kontakte',
            },
            {
                title: 'Dateimanager ',
                type: 'basic',
                icon: 'heroicons_outline:folder-open',
                link: '/files'
            },
        ]
    }
];

export const productivityNavItems: FuseNavigationItem[] = [
    {
        id: 'productivity',
        title: 'Produktivität',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'apps.mailbox',
                title: 'Mailbox',
                type: 'basic',
                // icon: 'heroicons_outline:envelope',
                icon: 'mat_outline:mail',
                link: '/mailbox',
                badge: {
                    title: 'Demnächst',
                    classes: 'px-2 bg-primary text-on-primary rounded'
                },
                disabled: environment.pool && environment.production,
                hidden: () => environment.production
            },
            {
                id: 'apps.scrumboard',
                title: 'Ticketing',
                type: 'basic',
                icon: 'mat_outline:view_column',
                link: '/ticketing',
                badge: {
                    title: 'Beta',
                    classes: 'px-2 bg-primary text-on-primary rounded'
                },
                // disabled: environment.pool && environment.production,
                // hidden: () => environment.production
            },
            {
                id: 'apps.tasks',
                title: 'Aufgaben',
                type: 'basic',
                icon: 'heroicons_outline:check-circle',
                link: '/aufgaben',
                // disabled: environment.pool && environment.production
            },
            {
                id: 'apps.notes',
                title: 'Notizen',
                type: 'basic',
                icon: 'heroicons_outline:pencil-alt',
                link: '/notes',
                // badge: {
                //     title: 'Beta',
                //     classes: 'px-2 bg-primary text-on-primary rounded'
                // },
                // disabled: environment.pool && environment.production,
                // hidden: () => environment.production
            },
        ],
    }
];
export const technikNavItems: FuseNavigationItem[] = [
    {
        id: 'Technik',
        title: 'Instandhaltung & Technik',
        type: 'group',
        icon: 'apps',
        children:
            [
                {
                    id: 'apps.claims',
                    // title: 'Mängelmanagement',
                    title: 'Mängel & Schäden',
                    type: 'basic',
                    icon: 'mat_outline:build_circle',
                    link: '/ticketing/maintenance',
                    badge: {
                        title: 'Beta',
                        classes: 'px-2 bg-primary text-on-primary rounded'
                    },
                    // disabled: environment.pool && environment.production,
                    // hidden: () => environment.production
                },
                {
                    id: 'meters',
                    title: 'Zähler',
                    type: 'collapsable',
                    icon: 'mat_outline:dynamic_form',
                    children: [
                        {
                            id: 'feature.meters.list',
                            title: 'Liste',
                            type: 'basic',
                            icon: 'mat_outline:view_list',
                            link: '/zaehler/liste',
                            permissions: ['owner,admin, vermietung'
                            ]
                        },
                        {
                            id: 'feature.meters.re_registration',
                            title: 'Ummeldung',
                            type: 'basic',
                            icon: 'mat_outline:autorenew',
                            link: '/zaehler/ummeldung',
                            permissions: ['owner,admin, vermietung']
                        }
                    ]
                },
                {
                    id: 'feature.serviceProviders',
                    hidden: (item => environment.pool),
                    title: 'Dienstleistungen',
                    type: 'basic',
                    icon: 'description',
                    link: '/dienstleistungen',
                    permissions: ['owner,admin, vermietung'],
                    badge: {
                        title: 'PRO',
                        classes: 'px-2 bg-yellow-300 text-black rounded-full'
                    }
                },
                {
                    id: 'feature.insurance',
                    hidden: (item => environment.pool),
                    title: 'Versicherungen',
                    type: 'basic',
                    icon: 'description',
                    link: '/versicherungen',
                    permissions: ['owner,admin, vermietung'],
                    badge: {
                        title: 'PRO',
                        classes: 'px-2 bg-yellow-300 text-black rounded-full'
                    }
                }
            ]
    }
];

export const aiNavItems: FuseNavigationItem[] = [
    {
        id: 'ai',
        title: 'Künstliche Intelligenz',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'anwaltAI',
                title: 'Nahaus AI',
                type: 'basic',
                // icon: 'mat_outline:contact_page',
                png: '/assets/icons/chip/ai.svg',
                // png: '/assets/icons/counters/counter-50.png',
                link: '/chat',
                badge: {
                    title: 'Neu',
                    classes: 'px-2 bg-primary text-on-primary rounded'
                },
            },
        ]
    }
]
export const vermietungNavItems: FuseNavigationItem[] = [
    {
        id: 'vermietung',
        title: 'Vermietung',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'feature.msa',
                title: 'Mieterselbstauskunft',
                type: 'basic',
                icon: 'mat_outline:contact_page',
                link: '/mieterselbstauskunft'
            },
            {
                id: 'feature.tenants',
                title: 'Mietersuche',
                type: 'basic',
                icon: 'mat_outline:person_search',
                link: '/mietersuche',
            },
            {
                id: 'feature.vacancies',
                title: 'Leerstand',
                type: 'basic',
                icon: 'mat_outline:home_work',
                link: '/leerstand',
                permissions: ['owner,admin, vermietung']
            },
            {
                id: 'feature.handover',
                title: 'Ein/Auszüge',
                type: 'basic',
                icon: 'mat_outline:compare_arrows',
                link: '/ein-auszug',
                permissions: ['owner,admin, vermietung']
            },
            {
                id: 'feature.contracts',
                title: 'Mietverträge',
                type: 'basic',
                icon: 'description',
                link: '/mietvertraege',
                permissions: ['owner,admin, vermietung']
            },
            // {
            //   id: 'gewerbe',
            //   title: 'Gewerbe',
            //   type: 'basic',
            //   icon: 'house',
            //   link: '/gewerbe',
            //   permissions: ['owner,admin, vermietung']
            // },
            {
                id: 'feature.letters',
                title: 'Anschreiben',
                type: 'basic',
                icon: 'mat_outline:drive_file_rename_outline',
                link: '/anschreiben',
                permissions: ['owner,admin, vermietung']
            },
            {
                id: 'feature.graduated-rent',
                title: 'Staffelmiete',
                type: 'basic',
                icon: 'mat_outline:trending_up',
                link: '/staffelmiete',
                permissions: ['owner,admin, vermietung']
                // badge: {
                //   title: 'Beta',
                //   classes: 'px-2 bg-primary text-on-primary rounded'
                // }
            }
            // {
            //   id: 'calendar',
            //   title: 'Kalendar',
            //   type: 'basic',
            //   icon: 'heroicons_outline:calendar',
            //   link: '/kalendar',
            //   permissions: ['owner,admin, vermietung']
            // }
            // {
            //   id: 'terminations',
            //   title: 'Kündigungen',
            //   type: 'basic',
            //   icon: 'description',
            //   link: '/apps/stats/kuendigungen',
            //   permissions: ['owner,admin, vermietung']
            // },
            // {
            //   id: 'new_rental',
            //   title: 'Neue Vermietungen',
            //   type: 'basic',
            //   icon: 'description',
            //   link: '/apps/stats/vermietungen',
            //   permissions: ['owner,admin, vermietung']
            // },
            // {
            //   id: 'todo',
            //   title: 'Aufgaben',
            //   type: 'basic',
            //   icon: 'description',
            //   link: '/apps/todo/rental/all',
            //   permissions: ['owner,admin, vermietung']
            // }
        ]
    }
];

export const supportNavItems: FuseNavigationItem[] = [
    {
        id: 'apps.help-center',
        title: 'Kundenservice',
        type: 'group',
        icon: 'heroicons_outline:support',
        link: '/apps/help-center',
        children: [
            // {
            //   id        : 'apps.help-center.home',
            //   title     : 'Home',
            //   type      : 'basic',
            //   link      : '/apps/help-center',
            //   exactMatch: true
            // },
            // {
            //   id   : 'apps.help-center.faqs',
            //   title: 'FAQs',
            //   type : 'basic',
            //   link : '/apps/help-center/faqs'
            // },
            // {
            //   id   : 'apps.help-center.guides',
            //   title: 'Guides',
            //   type : 'basic',
            //   link : '/apps/help-center/guides'
            // },
            {
                id: 'apps.help-center.support',
                title: 'Support',
                type: 'basic',
                link: 'support',
                icon: 'mat_outline:contact_support'
            },
            {
                id: 'apps.help-center.youtube',
                title: 'Video-Anleitungen',
                type: 'basic',
                link: 'https://www.youtube.com/playlist?list=PL-NOTIdb9ez0NbLdmt4lWgFuC67XuyPCK',
                externalLink: true,
                target: '_blank',
                icon: 'mat_outline:ondemand_video'
            },
            {
                id: 'apps.help-center.instructions',
                title: 'Anleitungen',
                type: 'basic',
                link: 'anleitungen',
                icon: 'mat_outline:school',
                hidden: (() => environment.production),
                badge: {
                    title: 'Demnächst',
                    classes: 'px-2 bg-primary text-on-primary rounded'
                },
            },
            {
                id: 'apps.help-center.blog',
                title: 'Blog',
                type: 'basic',
                link: 'https://blog.nahaus.de',
                externalLink: true,
                target: '_blank',
                icon: 'mat_outline:chrome_reader_mode'
            }
        ]
    }
];

export const assetsNavItems: FuseNavigationItem[] =
    [
        {
            id: 'assets',
            title: 'Vermögen',
            type: 'group',
            children: [
                {
                    id: 'overview',
                    title: 'Übersicht',
                    type: 'basic',
                    icon: 'mat_outline:analytics',
                    link: '/vermoegen/uebersicht'
                },
                {
                    id: 'reports',
                    title: 'Berichte',
                    type: 'basic',
                    icon: 'heroicons_outline:document-report',
                    link: '/vermoegen/berichte'
                }
            ]
        }
    ];

export const settingsNavItems: FuseNavigationItem[] =
    [
        {
            id: 'settings',
            title: 'Einstellungen',
            type: 'group',
            children: [
                {
                    id: 'settings-general',
                    title: 'Allgemein',
                    type: 'basic',
                    icon: 'mat_outline:settings',
                    link: '/einstellungen',
                    badge: null
                },
                {
                    id: 'settings.users',
                    title: 'Benutzer',
                    type: 'basic',
                    icon: 'mat_outline:group',
                    link: '/benutzer'
                    // badge: {
                    //   title: 'ENTERPRISE',
                    //   classes: 'px-2 bg-white text-black rounded-full'
                    // }
                }
            ]
        }
    ];

export const subsNavItems: FuseNavigationItem[] = [
    {
        id: 'subscriptions',
        title: 'Abonnements',
        type: 'group',
        hidden: (() => environment.customer === 'jug'),
        children: [
            {
                id: 'subscriptions.my',
                title: 'Meine Abos',
                type: 'basic',
                icon: 'mat_outline:shopping_cart',
                link: '/abonnement',
                isActiveMatchOptions: {paths: 'exact', fragment: null, queryParams: null, matrixParams: null}
            },
            {
                id: 'subscriptions.manage',
                title: 'Zahlungskonto',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: '/abonnement/zahlungskonto',
                isActiveMatchOptions: {paths: 'exact', fragment: null, queryParams: null, matrixParams: null}
            },
            {
                id: 'subscriptions.pricing',
                title: 'Preise',
                type: 'basic',
                icon: 'mat_outline:payments',
                link: '/upgrade'
            },
            {
                id: 'subscriptions.affiliates',
                title: 'Empfehlungsbonus',
                type: 'basic',
                icon: 'mat_outline:card_giftcard',
                link: '/empfehlungsbonus',
                hidden: (() => environment.customer === 'jug' || environment.production),
                badge: {
                    title: 'Neu',
                    classes: 'px-2 bg-primary text-on-primary rounded'
                },
            }
        ]
    }
];

export const getBadeForWEG = (property: Property, availableForTier: NahausTier, currentTier: NahausTier = null) => {
    if (property.managementType === ManagementType.WEG) {
        return {
            title: 'Mietverwaltung',
            classes: 'px-2 bg-primary text-on-primary rounded'
        };
    } else {
        return getTierBadge(availableForTier, currentTier);
    }
};

export const getTierBadge = (availableForTier: NahausTier, currentTier: NahausTier = null) => {
    switch (availableForTier) {
        case NahausTiersOptions.STARTER:
            return !currentTier ? {
                title: 'STARTER',
                classes: 'px-2 bg-blue-400 text-black rounded-full'
            } : null;
        case NahausTiersOptions.PRO:
            return !currentTier || currentTier === NahausTiersOptions.STARTER ? {
                title: 'PRO',
                classes: 'px-2 bg-yellow-300 text-black rounded-full'
            } : null;
        case NahausTiersOptions.ENTERPRISE:
            return currentTier !== NahausTiersOptions.ENTERPRISE ? {
                title: 'ENTERPRISE',
                classes: 'px-2 bg-black dark:bg-white text-white dark:text-black rounded'
            } : null;
        default:
            return null;
    }
};
