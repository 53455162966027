import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {QuerySnapshot} from '@angular/fire/compat/firestore';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {fuseAnimations} from '@fuse/animations';
import {Subject, take} from 'rxjs';
import {BookingPayment} from '../../models/bookingPayment';
import {BookingPaymentDivision} from '../../models/bookingPaymentDivision';
import {BookingPaymentsDivisionService} from '../../services/bookingPaymentsDivision.service';

export interface CreateBookingPaymentDivisionDialog {
    propertyID: string;
    unitID: string;
    periodID: string;
    bookingPayment: BookingPayment;
}

@Component({
    selector: 'fuse-confirmation-dialog',
    templateUrl: './create-booking-payment-division-dialog.component.html',
    styles: [
        `
            .fuse-confirmation-dialog-panel {

                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {

                    .mat-mdc-dialog-surface {
                        padding: 0 !important;
                    }
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [BookingPaymentsDivisionService]
})
export class CreateBookingPaymentDivisionDialogComponent implements OnInit, OnDestroy {

    today = new Date();

    propertyID: string;
    unitID: string;
    periodID: string;
    bookingPayment: BookingPayment;

    loadingBookingPaymentDivisions: boolean;

    loading: boolean;
    saving: boolean;
    showError: boolean;
    errorMsg: string;
    bookingPaymentFormGroup: FormGroup;
    bookingPaymentsFormArray: FormArray;

    divisionsRefList: QuerySnapshot<BookingPaymentDivision>;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: CreateBookingPaymentDivisionDialog,
                private matDialogRef: MatDialogRef<CreateBookingPaymentDivisionDialogComponent>,
                private formBuilder: FormBuilder,
                private bookingPaymentsDivisionService: BookingPaymentsDivisionService,
                private snackbar: MatSnackBar) {
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.bookingPayment = this.data?.bookingPayment;

        this.propertyID = this.data?.propertyID || this.bookingPayment?.propertyID;
        this.unitID = this.data?.unitID || this.bookingPayment?.unitID;
        this.periodID = this.data?.periodID || this.bookingPayment?.periodID;

        if (!(this.propertyID && this.unitID && this.periodID && this.bookingPayment)) {
            this.snackbar.open('Etwas ist schief gelaufen, bitte versuchen Sie es später erneut oder wenden Sie sich an den Support', 'OK', {duration: 10000});
            this.matDialogRef.close('cancelled');
        }

        console.log('CreateBookingPaymentDivisionDialogComponent --> propertyID --> ', this.propertyID);
        console.log('CreateBookingPaymentDivisionDialogComponent --> unitID --> ', this.unitID);
        console.log('CreateBookingPaymentDivisionDialogComponent --> periodID --> ', this.periodID);
        console.log('CreateBookingPaymentDivisionDialogComponent --> bookingPayment --> ', this.bookingPayment);

        this.bookingPaymentsDivisionService.setParentPath(this.propertyID, this.unitID, this.periodID, this.bookingPayment?.bookingID);
        this.loadData();

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    loadData(): void {
        this.loadingBookingPaymentDivisions = true;
        this.bookingPaymentsDivisionService.collection().get().pipe(take(1)).subscribe((divisionQuery) => {


            if (divisionQuery?.size > 0) {
                this.divisionsRefList = divisionQuery;
                const divisions: BookingPaymentDivision[] = divisionQuery.docs.map(d => d.data() as BookingPaymentDivision);
                this.bookingPaymentsFormArray = this.formBuilder.array(divisions.map(bookingPaymentDivision => this.createBookingPaymentFG(bookingPaymentDivision)));
                console.log('divisions = ', divisions);
            } else {
                this.bookingPaymentsFormArray = this.formBuilder.array([]);
            }
            this.bookingPaymentFormGroup = this.formBuilder.group({
                divisions: this.bookingPaymentsFormArray
            });
            this.loadingBookingPaymentDivisions = false;
        });
    }

    createBookingPaymentFG(bookingPaymentDivision?: BookingPaymentDivision): FormGroup {
        return this.formBuilder.group({
            id: bookingPaymentDivision?.id,
            amount: bookingPaymentDivision?.amount || 0,
            saldo: bookingPaymentDivision?.saldo || 0,
            description: bookingPaymentDivision?.description,
            art: bookingPaymentDivision?.art,
            paymentArt: bookingPaymentDivision?.paymentArt
        });
    }


    save(): void {
        this.showError = false;
        this.errorMsg = null;
        this.saving = true;

        const divisions = this.bookingPaymentsFormArray.getRawValue() as BookingPaymentDivision[];
        const totalSpend = divisions?.map(division => division?.amount ?? 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0);

        console.log('on save --> divisions = ', divisions);
        console.log('on save --> totalSpend = ', totalSpend);

        if (totalSpend !== this.bookingPayment?.amount) {
            this.errorMsg = `Bitte stellen Sie sicher, dass die Summe der Aufteilungen dem Zahlungsbetrag ${this.bookingPayment?.amount}€ entspricht`;
            this.showError = true;
            this.saving = false;
            return;
        }

        const batch = this.bookingPaymentsDivisionService.batch();

        for (let division of divisions) {
            const divisionRef = this.divisionsRefList.docs.find(doc => doc.id === division?.id);
            const divisionData = divisionRef.data() as BookingPaymentDivision;
            const amount = division?.amount ?? 0;
            const saldo = divisionData?.saldo + amount;
            batch.update(divisionRef.ref, {amount});
        }

        batch.commit()
            .then(() => {
                this.snackbar.open('Die Aufteilung der Zahlung wurde erfolgreich aktualisiert.', 'OK', {duration: 5000});
                this.matDialogRef.close();
            }).catch((err) => {
            this.errorMsg = `Leider ist einen Fehler aufgetreten! Bitte versuchen Sie später noch einmal - ${err}`;
            this.showError = true;
        }).finally(() => this.saving = false);

    }
}
