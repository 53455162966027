// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {env} from '@env/.env';

// export type NAHAUS_CUSTOMERS = 'JUG' | 'AFI'

export const environment = {
    production: false,
    useEmulators: false,
    pool: true,
    isComingSoon: true,
    showQuickChat: false,
    customer: 'pool',
    version: env.npm_package_version + '-dev-p',
    defaultLanguage: 'de',
    supportedLanguages: ['en', 'de', 'fr'],
    GTM_ID: 'GTM-5QX5V3C',
    GOOGLE_MAPS_API_KEY: 'AIzaSyBUatcv92TBlAxy-XHGQavV6u-o0FkQrx4',
    stripeKey: 'pk_test_51J2LciBpGVbP5eMS1tLLjN3P3pcRkApEvGKZ9CZ49aPUngV6H6XAl57ZxQ5ovkEzD2EgNMTe9objTvSe6X6YxSU200lRrf6ize',
    reCaptchaV3Key: '6LeVTFoiAAAAAHWzB1VBcsWe8vZ4fBAcGotpMN9y',
    firebase: {
        apiKey: 'AIzaSyDl6rdcEcVwu_X-ckblHbg8wqoOp6qdxwI',
        authDomain: 'dev.app.nahaus.de',
        projectId: 'pool-dev-nahaus-de',
        storageBucket: 'pool-dev-nahaus-de.appspot.com',
        messagingSenderId: '637668461487',
        appId: '1:637668461487:web:6887c8bde59455c1b53d5a',
        measurementId: 'G-DGLW1722KH'
    },
    webApiKey: 'AIzaSyAl9q026thJtozspiXkmGzDBrQ_BQJF6c0',
    googleclientId: '988017796709-b74cafcgg5qo42dktlf6qoav6imk937g.apps.googleusercontent.com',
    algolia: {
        applicationId: '4HXO4US6DJ',
        searchApiKey: 'd2b0ed4dddf2a29548b2c2fc438b8302',
        indexName: {
            bankAccounts: 'bankAccounts',
            msa: 'msa',
            users: 'users',
            properties: 'properties',
            places: 'places',
            units: 'units',
            tenants: 'tenants',
            landlords: 'landlords',
            vacancies: 'vacancies',
            serviceProviders: 'serviceProviders',
            serviceContracts: 'serviceContracts',
            meters: 'meters',
            metersReRegistrations: 'metersReRegistrations',
            insurance: 'insurance',
            contracts: 'contracts',
            loans: 'loans',
            letters: 'letters',
            institutions: 'institutions',
            managers: 'managers',
            graduatedRent: 'graduatedRent',
            transactions: 'transactions',
            bookings: 'bookings',
            files: 'files',
            terminations: 'terminations',
            letterTemplates: 'letterTemplates',
            contacts: 'contacts',
            dunnings: 'dunnings',
            nahausBookingsAccounts: 'nahausBookingsAccounts',
            cards: 'cards',
        }
    },
    buckets: {
        main: 'pool-dev-nahaus-de'
    },
    msaBaseURL: 'https://dev.mieter.nahaus.de'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
