import {Pipe, PipeTransform} from '@angular/core';
import {FuseConfigService} from "@fuse/services/config";
import {FuseMediaWatcherService} from "@fuse/services/media-watcher";
import {combineLatest, firstValueFrom, take} from "rxjs";
import {map} from "rxjs/operators";

@Pipe({
    name: 'currentScheme'
})
export class CurrentSchemePipe implements PipeTransform {

    constructor(private _fuseConfigService: FuseConfigService,
                private _fuseMediaWatcherService: FuseMediaWatcherService) {
    }

    async transform(value: any): Promise<'dark' | 'light'> {
        let scheme = await firstValueFrom(combineLatest([
            this._fuseConfigService.config$,
            this._fuseMediaWatcherService.onMediaQueryChange$(['(prefers-color-scheme: dark)', '(prefers-color-scheme: light)'])
        ]).pipe(
            take(1),
            map(([config, mql]) => {

                const options = {
                    scheme: config.scheme,
                    theme: config.theme
                };

                // If the scheme is set to 'auto'...
                if (config.scheme === 'auto') {
                    // Decide the scheme using the media query
                    options.scheme = mql.breakpoints['(prefers-color-scheme: dark)'] ? 'dark' : 'light';
                }

                return options.scheme;
            }),
        ));
        console.log("on init CurrentSchemePipe --> scheme --> ", scheme);
        return scheme;
    }

}
