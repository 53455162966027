import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {TransactionsRange} from "@transactions/services/transactions-modular.service";

@Injectable()
export class BookingsHelperService {

    onRangeChanged$: BehaviorSubject<TransactionsRange> = new BehaviorSubject<TransactionsRange>(null);


    constructor() {
    }
}
