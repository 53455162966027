<div
    cdkScrollable
    class="absolute inset-0 flex flex-col min-w-0 overflow-y-auto">

    <!-- Header -->
    <div
        class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <!-- Actions -->
        <div *ngIf="this.accessLayer !== this.bookingsAccessLayerOptions.ACCOUNT"
             class="flex shrink-0 items-center  sm:mx-4">
            <button
                (click)="matDrawer.toggle()"
                mat-icon-button>
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </div>
        <div *ngIf="this.accessLayer !== this.bookingsAccessLayerOptions.ACCOUNT" class="flex-1 min-w-0">
            <!-- Title -->
            <div class="mt-2 flex flex-row items-center space-x-2">
                <h2 *ngIf="!this.onlyDeposit && !this.onlyUnpaid"
                    class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    Buchhaltung
                </h2>
                <h2 *ngIf="this.onlyUnpaid"
                    class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    Offene Posten
                </h2>
                <h2 *ngIf="this.onlyDeposit"
                    class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    Kautionsverwaltung
                </h2>
                <div>
                    <mat-icon *ngIf="this.accessLayer !== this.bookingsAccessLayerOptions.PERIOD && !this.onlyUnpaid"
                              matTooltip="Hier werden nur die Sollstellungen der letzten 60 Tage angezeigt. Für mehr Daten navigieren Sie bitte in den gewünschten Vermietungszeitraum, um weitere Details zu sehen."
                              svgIcon="mat_outline:info"></mat-icon>
                </div>
            </div>
        </div>

        <div *ngIf="this.accessLayer === this.bookingsAccessLayerOptions.ACCOUNT" class="w-full">
            <ais-instantsearch [config]='this.configProperties'>
                <!--								<ais-configure [searchParameters]="{ facets: ['customerID'] }"></ais-configure>-->
                <app-algolia-autocomplete
                    (onQuerySuggestionClick)=this.onQueryPropertySuggestionClick($event)
                    class="flex"
                    formFieldClass="w-full"
                    label="Immobilie auswählen"
                    matOptionStyles="height: 70px; line-height: 20px;"
                    placeholder="Suche...">
                    <ng-template let-option='option'>
                        <div class="flex flex-col">
                                            <span
                                                [innerHTML]="option?._highlightResult?.h_id_text?.value && option?._highlightResult?.h_id_text?.matchLevel === 'full' ? option?._highlightResult?.h_id_text?.value : 'H' + option?._highlightResult?.h_id?.value + ' ' + option?._highlightResult?.address?.streetName?.value + ' ' + option?._highlightResult?.address?.streetNumber?.value"
                                                matLine></span>
                            <span
                                [innerHTML]="option?._highlightResult?.address?.postalCode?.value + ' ' + option?._highlightResult?.address?.locality?.long?.value"
                                class="text-xs" matLine></span>
                            <mat-divider class="mt-2"></mat-divider>
                        </div>
                    </ng-template>
                </app-algolia-autocomplete>
            </ais-instantsearch>
            <div class="w-full mt-1">
                <mat-chip-listbox #chipGrid>
                    <mat-chip (removed)="remove(property)" *ngFor="let property of this.selectedProperties">
                        H{{property?.h_id}} {{property?.address | formatAddress}}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                </mat-chip-listbox>
            </div>
        </div>
        <!-- Actions -->
        <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-2 items-center space-x-4">
            <!--			<mat-form-field class="w-full sm:w-36" subscriptSizing="dynamic">-->
            <!--				<mat-select-->
            <!--						(selectionChange)="filterByCategory($event)"-->
            <!--						[value]="'all'">-->
            <!--					<ng-container *ngFor="let category of this.categories; trackBy: trackByFn">-->
            <!--						<mat-option [value]="category?.value">{{category?.title}}</mat-option>-->
            <!--					</ng-container>-->
            <!--				</mat-select>-->
            <!--			</mat-form-field>-->
            <div *ngIf="!this.selection?.isEmpty()" @slideOutRight
                 class="flex flex-row space-x-4">
                <button (click)="this.requestDeleteSelection()"
                        @slideInRight @slideOutLeft
                        [disabled]="this.deleting"
                        class="px-1 sm:px-4 min-w-10"
                        color="warn"
                        mat-stroked-button>
                    <mat-icon *ngIf="!this.deleting"
                              [svgIcon]="'mat_outline:delete'"
                              class="icon-size-5"></mat-icon>
                    <span *ngIf="!this.deleting" class="hidden sm:inline-block ml-2">Auswahl löschen</span>
                    <mat-progress-spinner
                        *ngIf=this.deleting
                        [diameter]="24"
                        [mode]="'indeterminate'"
                        matTooltip="Bitte warten..."></mat-progress-spinner>
                </button>
                <button (click)="this.requestMarkingSelectionAsPaid()"
                        @slideInRight @slideOutLeft
                        [color]="'primary'"
                        [disabled]="this.addingPayments"
                        class="px-1 sm:px-4 min-w-10"
                        mat-flat-button>
                    <mat-icon *ngIf="!this.addingPayments"
                              [svgIcon]="'mat_outline:done'"
                              class="icon-size-5"></mat-icon>
                    <span *ngIf="!this.addingPayments"
                          class="hidden sm:inline-block ml-2">Als bezahlt markieren</span>
                    <mat-progress-spinner
                        *ngIf=this.addingPayments
                        [diameter]="24"
                        [mode]="'indeterminate'"
                        matTooltip="Bitte warten..."></mat-progress-spinner>
                </button>
            </div>
            <!-- <button (click)="this.generatePdf()"
                    class="px-1 sm:px-4 min-w-10">Export as PDF</button>
                            [svgIcon]="'material-symbols:export-notes-outline'"

                -->

            <button (click)="this.export()" *ngIf="this.accessLayer === this.bookingsAccessLayerOptions.PERIOD"
                    @slideInRight @slideOutRight
                    [color]="'accent'"
                    class="px-1 sm:px-4 min-w-10" mat-raised-button matTooltip="Exportieren Sie als PDF oder CSV.">
                <mat-icon @zoomIn @zoomOut
                          [svgIcon]="'heroicons_outline:document-download'"
                          class="icon-size-5"></mat-icon>
                <span class="hidden sm:inline-block ml-2">Export</span>
            </button>

            <button (click)="this.openNewBookingDialog()"
                    *ngIf="this.accessLayer === this.bookingsAccessLayerOptions.PERIOD && this.selection?.isEmpty()"
                    @slideInRight @slideOutRight
                    [color]="'primary'"
                    [disabled]="this.creating"
                    class="px-1 sm:px-4 min-w-10" mat-flat-button matTooltip="Buchung hinzufügen">
                <mat-icon *ngIf="!this.creating" @zoomIn @zoomOut [svgIcon]="'heroicons_outline:plus'"
                          class="icon-size-5"></mat-icon>
                <span *ngIf="!this.creating" class="hidden sm:inline-block ml-2">Hinzufügen</span>
                <mat-progress-spinner
                    *ngIf="this.creating"
                    [diameter]="24"
                    [mode]="'indeterminate'"
                    matTooltip="Bitte warten..."></mat-progress-spinner>
            </button>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">

        <!-- List -->
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden">
            <mat-progress-bar *ngIf="this.isLoading" @zoomIn @zoomOut color="primary"
                              mode="query"></mat-progress-bar>
            <!-- Booking Table -->
            <ng-container *ngIf="this.bookingsDataSource?.data?.length; else loading">
                <div
                    cdkScrollable
                    class="overflow-x-auto sm:overflow-y-auto">
                    <mat-progress-bar *ngIf="this.isLoading" @zoomIn @zoomOut color="primary"
                                      mode="query"></mat-progress-bar>
                    <table
                        (matSortChange)='this.sortMsaData($event)'
                        [dataSource]="this.bookingsDataSource"
                        [matSortDisableClear]="true"
                        [multiTemplateDataRows]="true"
                        [ngClass]="{'pointer-events-none': this.isLoading}"
                        [trackBy]="trackByFn"
                        class="w-full bg-transparent"
                        mat-table
                        matSort>

                        <!-- Transaction ID -->
                        <ng-container matColumnDef="transactionId">
                            <th *matHeaderCellDef
                                class="w-44 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                matTooltip="Beschreibung der Buchung, z.B. Miete, Kaution, etc."
                                mat-header-cell>
                                <div class="flex flex-row space-x-2 items-center">
                                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                                  [aria-label]="checkboxLabel()"
                                                  [checked]="selection.hasValue() && isAllSelected()"
                                                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                  color="primary">
                                    </mat-checkbox>
                                    <div>
                                        Bezeichnung
                                    </div>
                                </div>
                            </th>
                            <td *matCellDef="let booking; let i = index"
                                class="w-40"
                                mat-cell>
                                <div class="flex flex-row items-center pr-6 space-x-2">
                                    <mat-checkbox (change)="$event ? selection.toggle(booking) : null"
                                                  (click)="$event.stopPropagation()"
                                                  *ngIf="!booking?.bookingID"
                                                  [aria-label]="checkboxLabel(booking,i)"
                                                  [checked]="selection.isSelected(booking)"
                                                  color="primary">
                                    </mat-checkbox>
                                    <mat-checkbox *ngIf="booking?.bookingID" class="opacity-0">
                                    </mat-checkbox>
                                    <ng-container *ngIf="!booking?.bookingID">
                                        <ng-container [ngSwitch]="booking?.status">
                                            <div *ngSwitchCase="this.bookingStatusOptions.PAID"
                                                 @zoomIn
                                                 class="relative flex items-center justify-center w-8 h-8 rounded-full overflow-hidden ring-4 ring-bg-card bg-white drop-shadow-[0_15px_25px_rgba(79,70,229,1)]"
                                                 matTooltip="Bezahlt">
                                                <!-- Upload / Remove avatar -->
                                                <div class="absolute inset-0 z-10"></div>
                                                <!-- Image/Letter -->
                                                <!--										<img @zoomIn [src]="cost?.type | nkCostIcon"-->
                                                <img [src]="'assets/icons/booking/payment-finance-pay.svg'"
                                                     class="object-cover w-full h-full">
                                            </div>
                                            <div *ngSwitchCase="this.bookingStatusOptions.EXPECTED"
                                                 @zoomIn
                                                 class="relative flex items-center justify-center w-8 h-8 rounded-full overflow-hidden ring-4 ring-bg-card bg-white drop-shadow-[0_15px_25px_rgba(245,158,11,1)]"
                                                 matTooltip="Zahlung ist zu erwarten">
                                                <!-- Upload / Remove avatar -->
                                                <div class="absolute inset-0 z-10"></div>
                                                <!-- Image/Letter -->
                                                <!--										<img @zoomIn [src]="cost?.type | nkCostIcon"-->
                                                <img [src]="'assets/icons/booking/payment-pay-later.svg'"
                                                     class="object-cover w-full h-full">
                                            </div>
                                            <div *ngSwitchDefault
                                                 @zoomIn
                                                 [matTooltip]="booking?.saldo && booking?.saldo !== booking?.amount ? 'Teilweise bezahlt' : 'Noch unbezahlt'"
                                                 class="relative flex items-center justify-center w-8 h-8 rounded-full overflow-hidden ring-4 ring-bg-card bg-white drop-shadow-[0_15px_25px_rgba(220,38,38,1)]">
                                                <!-- Upload / Remove avatar -->
                                                <div class="absolute inset-0 z-10"></div>
                                                <!-- Image/Letter -->
                                                <!--										<img @zoomIn [src]="cost?.type | nkCostIcon"-->
                                                <img [src]="'assets/icons/booking/unpaid.svg'"
                                                     class="object-cover w-full h-full">
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="booking?.bookingID">
                                        <div class="relative flex items-center justify-center w-8 h-8 overflow-hidden">
                                            <div class="absolute inset-0 z-10"></div>
                                        </div>
                                    </ng-container>
                                    <!--															<img class="w-6" [src]="cost?.type | nkCostIcon">-->
                                    <span [matTooltip]="booking?.text"
                                          [ngClass]="{'leading-normal text-sm text-secondary' : !!booking?.bookingID}"
                                          class="w-44 font-bold whitespace-nowrap truncate">
										{{booking?.text}}
									</span>
                                    <!--									<span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">{{booking?.text}}</span>-->
                                </div>
                            </td>
                            <td
                                *matFooterCellDef
                                class="border-b-0 border-t-4"
                                mat-footer-cell>
                            </td>
                        </ng-container>

                        <!-- address -->
                        <ng-container matColumnDef="address">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                matTooltip="Adresse der Immobilie"
                                mat-header-cell>
                                Immobilie
                            </th>
                            <td
                                *matCellDef="let booking"
                                mat-cell>
                                <div class="flex w-44 xl:w-fit truncate">
                                    <span *ngIf="booking?.address as address" [matTooltip]="address | formatAddress"
                                          class="pr-6 whitespace-nowrap truncate">
                                        {{address | formatAddress }}
                                    </span>
                                </div>
                            </td>
                            <td
                                *matFooterCellDef
                                class="border-b-0 border-t-4"
                                mat-footer-cell>
                            </td>
                        </ng-container>

                        <!-- Einheit -->
                        <ng-container matColumnDef="unitLabel">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                matTooltip="Einheit der Immobilie, z.B. EG Links"
                                mat-header-cell>
                                Einheit
                            </th>
                            <td
                                *matCellDef="let booking"
                                mat-cell>
                                <span class="pr-6 whitespace-nowrap">
                                    {{booking?.unitLabel}}
                                </span>
                            </td>
                            <td
                                *matFooterCellDef
                                class="border-b-0 border-t-4"
                                mat-footer-cell>
                            </td>
                        </ng-container>

                        <!-- Date -->
                        <ng-container matColumnDef="date">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                matTooltip="Datum der Sollstellung"
                                mat-sort-header
                                mat-header-cell>
                                Datum
                            </th>
                            <td
                                *matCellDef="let booking"
                                mat-cell>
                                <span [ngClass]="{'leading-normal text-sm text-secondary' : !!booking?.bookingID}"
                                      class="pr-6 whitespace-nowrap">
                                    {{booking?.date | toDate | date:'mediumDate'}}
                                </span>
                            </td>
                            <td
                                *matFooterCellDef
                                class="border-b-0 border-t-4"
                                mat-footer-cell>
                            </td>
                        </ng-container>

                        <!-- Name -->
                        <ng-container matColumnDef="name">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                matTooltip="Name des Mieters"
                                mat-header-cell>
                                Name
                            </th>
                            <td
                                *matCellDef="let booking"
                                mat-cell>
                                <span [ngClass]="{'leading-normal text-sm text-secondary' : !!booking?.bookingID}"
                                      class="pr-6 whitespace-nowrap">
                                    {{booking?.tenantsName}}
                                </span>
                            </td>
                            <td
                                *matFooterCellDef
                                class="border-b-0 border-t-4"
                                mat-footer-cell>
                            </td>
                        </ng-container>

                        <!-- Amount -->
                        <ng-container matColumnDef="amount">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                mat-header-cell
                                mat-sort-header
                                matTooltip="Betrag, der in Soll gestellt wurde">
                                Soll
                            </th>
                            <td
                                *matCellDef="let booking"
                                mat-cell>
                                <span *ngIf="!booking?.bookingID" class="pr-6 font-medium whitespace-nowrap">
                                    {{booking?.amount | currency:'EUR'}}
                                </span>
                            </td>
                            <td
                                *matFooterCellDef
                                class="border-b-0 border-t-4"
                                mat-footer-cell>
								<span *ngIf="this.accessLayer === this.bookingsAccessLayerOptions.PERIOD"
                                      class="pr-6 text-right font-semibold whitespace-nowrap">
									Gesamt:
								</span>
                            </td>
                        </ng-container>

                        <!-- Amount -->
                        <ng-container matColumnDef="haben">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                matTooltip="Geleistete Zahlungen und manuelle Erfassung"
                                mat-sort-header
                                mat-header-cell>
                                Zahlung
                            </th>
                            <td #appMouseHover2="appMouseHover"
                                *matCellDef="let booking"
                                appMouseHover="I'm a appMouseHover!!"
                                mat-cell>
                                <div *ngIf="!booking?.bookingID"
                                     class="flex flex-row justify-start w-full space-x-1">
                                    <div *ngIf="false" class="flex w-full">
										<span
                                            [ngClass]="{'text-primary': booking?.haben > 0, 'text-warn': booking?.haben < 0, 'font-extrabold': booking?.haben !== 0}"
                                            class="pr-6 whitespace-nowrap">
											<span *ngIf="booking?.haben > 0"
                                                  class="font-extrabold text-primary">+</span>
                                            {{booking?.haben | currency:'EUR'}}
										</span>
                                    </div>
                                    <div
                                        [matTooltip]="booking?.type === this.bookingTypeOptions.PAYOUT ? 'Auszahlung erfassen' : 'Zahlung erfassen'"
                                        class="flex w-full ml-2 -mt-2 -mr-3">
                                        <button
                                            (click)="this.openBookingPaymentsDialog(booking); $event.stopPropagation()"
                                            @zoomIn
                                            color="accent"
                                            mat-mini-fab>
                                            <mat-icon svgIcon="mat_outline:add"></mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <span *ngIf="booking?.bookingID"
                                      [ngClass]="{'text-primary': booking?.amount > 0, 'text-warn': booking?.amount < 0, 'font-extrabold': booking?.amount !== 0}"
                                      class="pr-6 whitespace-nowrap">
											<span *ngIf="booking?.amount > 0"
                                                  class="font-extrabold text-primary">+</span>
                                    {{booking?.amount | currency:'EUR'}}
										</span>
                            </td>
                            <td
                                *matFooterCellDef
                                class="border-b-0 border-t-4"
                                mat-footer-cell>
                                <ng-container *ngIf="this.onlyDeposit">
									 <span *ngIf="!this.loadingSaldo"
                                           [ngClass]="{'text-warn': this.totalAmount < 0, 'text-primary': this.totalAmount > 0, 'font-extrabold': this.totalAmount !== 0}"
                                           class="pr-6 whitespace-nowrap">
										 <span *ngIf="this.totalAmount > 0"
                                               class="font-extrabold text-primary">+</span>
                                         {{this.totalAmount | currency:'EUR'}}
                                </span>
                                    <div *ngIf="this.loadingSaldo" class="flex w-full">
                                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>

                        <!-- saldo -->
                        <ng-container matColumnDef="saldo">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                matTooltip="Aktueller Saldo der Sollstelle"
                                mat-sort-header
                                mat-header-cell>
                                Saldo
                            </th>
                            <td
                                *matCellDef="let booking"
                                mat-cell>
                                <span
                                    [ngClass]="{'text-warn': booking?.saldo < 0, 'text-primary': booking?.saldo > 0, 'font-extrabold': booking?.saldo !== 0}"
                                    class="pr-6 whitespace-nowrap">
	                                <span *ngIf="booking?.saldo > 0"
                                          class="font-extrabold text-primary">+</span>
                                    {{booking?.saldo | currency:'EUR'}}
                                </span>
                            </td>
                            <td
                                *matFooterCellDef
                                class="border-b-0 border-t-4"
                                mat-footer-cell>
								 <span *ngIf="!this.loadingSaldo"
                                       [ngClass]="{'text-warn': this.saldo < 0, 'text-primary': this.saldo > 0, 'font-extrabold': this.saldo !== 0}"
                                       class="pr-6 whitespace-nowrap">
                                    {{this.saldo | currency:'EUR'}}
                                </span>
                                <div *ngIf="this.loadingSaldo" class="flex w-full">
                                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                </div>
                            </td>
                        </ng-container>

                        <!-- Status -->
                        <ng-container matColumnDef="status">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                matTooltip="Zahlungsstatus: bezahlt, teilweise bezahlt, unbezahlt, erwartet"
                                mat-sort-header
                                mat-header-cell>
                                Status
                            </th>
                            <td
                                *matCellDef="let booking"
                                mat-cell>
                                <div (click)="$event.stopPropagation()" *ngIf="!booking?.bookingID" class="flex w-full">
                                    <mat-form-field
                                        *ngIf="![this.bookingStatusOptions.PAID, this.bookingStatusOptions.PARTIALLY_PAID].includes(booking?.status)"
                                        class="w-full mt-4">
                                        <mat-select #statusSelect (click)="$event.stopPropagation()"
                                                    (selectionChange)="this.onStatusChangeRequest($event, booking, statusSelect)"
                                                    [value]="booking.status">
                                            <mat-option [value]="this.bookingStatusOptions.PAID">
                                                <span
                                                    *ngIf="booking?.type !== this.bookingTypeOptions.PAYOUT">Bezahlt</span>
                                                <span *ngIf="booking?.type === this.bookingTypeOptions.PAYOUT">Ausgezahlt</span>
                                            </mat-option>
                                            <mat-option [value]="this.bookingStatusOptions.PARTIALLY_PAID">
                                                <span *ngIf="booking?.type !== this.bookingTypeOptions.PAYOUT">Teilweise bezahlt</span>
                                                <span *ngIf="booking?.type === this.bookingTypeOptions.PAYOUT">Teilweise ausgezahlt</span>
                                            </mat-option>
                                            <mat-option [value]="this.bookingStatusOptions.NOT_PAID">
                                                <span
                                                    *ngIf="booking?.type !== this.bookingTypeOptions.PAYOUT">Unbezahlt</span>
                                                <span *ngIf="booking?.type === this.bookingTypeOptions.PAYOUT">Nicht ausgezahlt</span>
                                            </mat-option>
                                            <mat-option [value]="this.bookingStatusOptions.EXPECTED">Erwartet
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="booking?.status === this.bookingStatusOptions.PAID"
                                         class="px-2 bg-primary text-on-primary rounded">
                                        <span *ngIf="booking?.type !== this.bookingTypeOptions.PAYOUT">Bezahlt</span>
                                        <span *ngIf="booking?.type === this.bookingTypeOptions.PAYOUT">Ausgezahlt</span>
                                    </div>
                                    <div *ngIf="booking?.status === this.bookingStatusOptions.PARTIALLY_PAID"
                                         class="px-2 bg-warn text-on-warn rounded">
                                        <span
                                            *ngIf="booking?.type !== this.bookingTypeOptions.PAYOUT">Teilw. bezahlt</span>
                                        <span
                                            *ngIf="booking?.type === this.bookingTypeOptions.PAYOUT">Teilw. ausgezahlt</span>
                                    </div>
                                </div>
                            </td>
                            <td
                                *matFooterCellDef
                                class="border-b-0 border-t-4"
                                mat-footer-cell>
                                <div *ngIf="!this.loadingSaldo" class="flex w-full">
                                    <div *ngIf="this.saldo < 0" class="px-2 bg-warn text-on-warn rounded">
                                        Nicht alles bezahlt
                                    </div>
                                    <div *ngIf="this.saldo >= 0 && this.bookingsDataSource?.data?.length > 0"
                                         class="px-2 bg-primary text-on-primary rounded">
                                        Alles bezahlt
                                    </div>
                                </div>
                                <div *ngIf="this.loadingSaldo" class="flex w-full">
                                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                </div>
                            </td>
                        </ng-container>

                        <!-- Dunnning -->
                        <ng-container matColumnDef="lastDunning.date">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                matTooltip="Status der Mahnung (z.B. Zahlungserinnerung, Mahnungsstufe 1,2..)"
                                mat-sort-header
                                mat-header-cell>
                                Mahnungsstatus
                            </th>
                            <td
                                *matCellDef="let booking"
                                mat-cell>
                                <div *ngIf="!booking?.bookingID" class="flex w-full">
                                    <ng-container *ngIf="!booking?.lastDunning?.id">
                                        <button (click)="$event.stopPropagation(); this.createDunning(booking)"
                                                *ngIf="booking | canCreateDunning" @zoomIn
                                                @zoomOut
                                                color="warn"
                                                mat-raised-button>
                                            Mahnen
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="booking?.lastDunning?.id">
                                        <div class="flex flex-row space-x-2 items-center">
                                            <div
                                                *ngIf="booking?.lastDunning?.type === this.dunningTypeOptions.PAYMENT_REMINDER"
                                                class="flex items-center justify-center py-1 px-3 rounded-full leading-normal text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700"
                                                matTooltip="Erstellt am: {{ booking?.lastDunning?.date | toDate | date:'longDate' }} | Fällig am: {{ booking?.lastDunning?.dueDate | toDate | date:'longDate' }}">
                                                        <span
                                                            class="text-sm font-medium whitespace-nowrap">Zahlungserinnerung</span>
                                            </div>

                                            <div
                                                *ngIf="booking?.lastDunning?.type === this.dunningTypeOptions.FIRST_REMINDER"
                                                class="flex items-center justify-center py-1 px-3 rounded-full leading-normal text-white bg-orange-500 dark:text-white-300 dark:bg-orange-400"
                                                matTooltip="Erstellt am: {{ booking?.lastDunning?.date | toDate | date:'longDate' }} | Fällig am: {{ booking?.lastDunning?.dueDate | toDate | date:'longDate' }}">
                                                <span class="text-sm font-medium whitespace-nowrap">Erste Mahnung</span>
                                            </div>

                                            <div
                                                *ngIf="booking?.lastDunning?.type === this.dunningTypeOptions.SECOND_REMINDER"
                                                class="flex items-center justify-center py-1 px-3 rounded-full leading-normal text-white bg-warn-400 dark:text-white-300 dark:bg-warn-500"
                                                matTooltip="Erstellt am: {{ booking?.lastDunning?.date | toDate | date:'longDate' }} | Fällig am: {{ booking?.lastDunning?.dueDate | toDate | date:'longDate' }}">
                                                <span
                                                    class="text-sm font-medium whitespace-nowrap">Zweite Mahnung</span>
                                            </div>

                                            <div
                                                *ngIf="booking?.lastDunning?.type === this.dunningTypeOptions.FINAL_REMINDER"
                                                class="flex items-center justify-center py-1 px-3 rounded-full leading-normal text-white bg-warn dark:text-white-300 dark:bg-warn-900"
                                                matTooltip="Erstellt am: {{ booking?.lastDunning?.date | toDate | date:'longDate' }} | Fällig am: {{ booking?.lastDunning?.dueDate | toDate | date:'longDate' }}">
                                                <ng-container [ngSwitch]="booking?.lastDunning?.lastWarningType">
                                                            <span *ngSwitchDefault
                                                                  class="text-sm font-medium whitespace-nowrap">Letzte Mahnung</span>
                                                    <span
                                                        *ngSwitchCase="this.lastWarningTypeOptions.FINAL_WARNING_BEFORE_LEGAL_ACTION"
                                                        class="text-sm font-medium whitespace-nowrap">LM. vor Mahnbescheid</span>
                                                    <span
                                                        *ngSwitchCase="this.lastWarningTypeOptions.FINAL_WARNING_BEFORE_TERMINATION"
                                                        class="text-sm font-medium whitespace-nowrap">LM. vor Fristloskündigung</span>
                                                </ng-container>
                                            </div>

                                            <div>
                                                <button (click)="$event.stopPropagation(); this.getPdf(url)"
                                                        *ngIf="booking?.lastDunning?.url as url"
                                                        color="accent"
                                                        mat-icon-button
                                                        matTooltip="Mahnungsschreiben als PDF herunterladen und ansehen">
                                                    <mat-icon
                                                        [svgIcon]="'heroicons_outline:document-download'"
                                                        class="text-hint"></mat-icon>
                                                </button>
                                            </div>

                                            <div>
                                                <button (click)="$event.stopPropagation(); this.createDunning(booking)"
                                                        *ngIf="booking | canCreateDunning"
                                                        color="warn"
                                                        mat-icon-button
                                                        matTooltip="Mahnung hochstufen, falls die offene Posten noch unbezahlt sind">
                                                    <mat-icon
                                                        [svgIcon]="'mat_outline:arrow_upward'"
                                                        class="text-hint"></mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </td>
                            <td
                                *matFooterCellDef
                                class="border-b-0 border-t-4"
                                mat-footer-cell>
                            </td>
                        </ng-container>

                        <!-- action -->
                        <ng-container matColumnDef="action">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5 text-right"
                                mat-header-cell>
                                Aktionen
                            </th>
                            <td
                                *matCellDef="let booking"
                                mat-cell>
                                <div class="flex w-full items-center justify-end">
                                    <button (click)="this.openNewBookingDialog()"
                                            [color]="'accent'"
                                            class="ml-4"
                                            mat-raised-button
                                            matTooltip="Zahlung erfassen">
                                        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                                        <span class="ml-2 mr-1">Zahlung erfassen</span>
                                    </button>
                                </div>
                            </td>
                            <td
                                *matFooterCellDef
                                class="border-b-0 border-t-4"
                                mat-footer-cell>
                            </td>
                        </ng-container>

                        <!-- Buttons -->
                        <ng-container matColumnDef="buttons">
                            <th
                                *matHeaderCellDef
                                class="w-12 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                mat-header-cell>
                            </th>
                            <td
                                *matCellDef="let booking"
                                mat-cell>
                                <div *ngIf="!booking?.bookingID" class="flex justify-end">
                                    <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu"
                                            mat-icon-button>
                                        <mat-icon [svgIcon]="'heroicons_outline:dots-vertical'"></mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button (click)="this.openBookingPaymentsDialog(booking)" mat-menu-item>
                                            <mat-icon color="accent"
                                                      svgIcon="mat_outline:add_circle"></mat-icon>
                                            Zahlung erfassen
                                        </button>
                                        <button (click)="this.openNewBookingDialog(booking)" mat-menu-item>
                                            <mat-icon color="accent"
                                                      svgIcon="mat_outline:edit"></mat-icon>
                                            Bearbeiten
                                        </button>
                                        <button (click)="this.openDeleteBookingDialog(booking)" mat-menu-item>
                                            <mat-icon color="warn"
                                                      matTooltip="Löschen"
                                                      svgIcon="mat_outline:delete"></mat-icon>
                                            Löschen
                                        </button>
                                    </mat-menu>
                                </div>
                            </td>
                            <td
                                *matFooterCellDef
                                class="border-b-0 border-t-4"
                                mat-footer-cell>
                            </td>
                        </ng-container>

                        <!-- Product details row -->
                        <ng-container matColumnDef="propertyUnitsOverview">
                            <td
                                *matCellDef="let booking"
                                [attr.colspan]="this.columns?.length"
                                class="p-0 border-b-0"
                                mat-cell>
                                <div
                                    [@expandCollapse]="this.selectedBooking?.id === booking.id ? 'expanded' : 'collapsed'"
                                    class="shadow-lg overflow-hidden">
                                    <div class="overflow-x-auto">
                                        <table
                                            #recentTransactionsTable
                                            [dataSource]="this.bookingsDataSource"
                                            [multiTemplateDataRows]="true"
                                            [ngClass]="{'pointer-events-none': this.isLoading}"
                                            [trackBy]="trackByFn"
                                            class="w-full bg-transparent"
                                            mat-table
                                            matSort>

                                            <!-- Transaction ID -->
                                            <ng-container matColumnDef="transactionId">
                                                <td *matCellDef="let booking"
                                                    [colSpan]="1"
                                                    mat-cell>
                                                    <div class="flex flex-row items-center pr-6 space-x-2">
                                                        <!--															<img class="w-6" [src]="cost?.type | nkCostIcon">-->
                                                        <span
                                                            class="truncate leading-normal text-sm text-secondary whitespace-nowrap">{{booking?.text}}</span>
                                                        <!--									<span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">{{booking?.text}}</span>-->
                                                    </div>
                                                </td>
                                            </ng-container>

                                            <!-- Date -->
                                            <ng-container matColumnDef="date">
                                                <td *matCellDef="let booking"
                                                    [attr.colspan]="3"
                                                    [colSpan]="3"
                                                    mat-cell>
                                                    <span
                                                        class="pr-6 whitespace-nowrap truncate text-sm text-secondary">{{booking?.date | toDate | date:'mediumDate'}}</span>
                                                </td>
                                            </ng-container>

                                            <!-- Name -->
                                            <ng-container matColumnDef="name">
                                                <td *matCellDef="let booking"
                                                    mat-cell>
                                                    <span
                                                        class="pr-6 whitespace-nowrap truncate leading-normal text-sm text-secondary">{{booking?.tenantsName}}</span>
                                                </td>
                                            </ng-container>

                                            <!-- haben -->
                                            <ng-container matColumnDef="haben">
                                                <td #appMouseHover2="appMouseHover"
                                                    *matCellDef="let booking"
                                                    [attr.colspan]="4"
                                                    appMouseHover="I'm a appMouseHover!!"
                                                    mat-cell>
                                                    <div class="flex flex-row justify-between w-full space-x-1">
                                                        <div class="flex w-full">
										<span
                                            [ngClass]="{'text-primary': booking?.haben > 0, 'text-warn': booking?.haben < 0, 'font-extrabold': booking?.haben !== 0}"
                                            class="pr-6 whitespace-nowrap">
											<span *ngIf="booking?.haben > 0"
                                                  class="font-extrabold text-primary">+</span>
                                            {{booking?.haben | currency:'EUR'}}
										</span>
                                                        </div>
                                                        <!--														<div class="flex w-full justify-end ml-2 -mt-2 -mr-3"-->
                                                        <!--														     matTooltip="Zahlung erfassen">-->
                                                        <!--															<button (click)="this.openBookingPaymentsDialog(booking)"-->
                                                        <!--															        @zoomIn-->
                                                        <!--															        color="accent"-->
                                                        <!--															        mat-mini-fab>-->
                                                        <!--																<mat-icon svgIcon="mat_outline:add"></mat-icon>-->
                                                        <!--															</button>-->
                                                        <!--														</div>-->
                                                    </div>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="a">
                                                <td *matCellDef="let booking"
                                                    mat-cell>
                                                    <div class="w-26">

                                                    </div>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="b">
                                                <td *matCellDef="let booking"
                                                    mat-cell>
                                                    <div class="w-1">

                                                    </div>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="c">
                                                <td *matCellDef="let booking"
                                                    mat-cell>
                                                    <div class="w-22">

                                                    </div>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="d">
                                                <td *matCellDef="let booking"
                                                    mat-cell>
                                                    <div class="w-36">

                                                    </div>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="e">
                                                <td *matCellDef="let booking"
                                                    mat-cell>
                                                    <div class="w-28">

                                                    </div>
                                                </td>
                                            </ng-container>


                                            <tr
                                                *matRowDef="let booking; columns: this.columns2;"
                                                class="border-hidden order-row border-b-0 border-t-0 h-16 hover:bg-gray-100 dark:hover:bg-hover"
                                                mat-row></tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <!-- Footer -->
                        <ng-container matColumnDef="recentOrdersTableFooter">
                            <td
                                *matFooterCellDef
                                class="py-6 px-0 border-0"
                                colspan="6"
                                mat-footer-cell>
                                <button *ngIf="false" class="ml-4" mat-stroked-button>Export</button>
                            </td>
                        </ng-container>

                        <tr
                            *matHeaderRowDef="this.columns"
                            mat-header-row></tr>
                        <tr
                            (click)="this.openBookingPaymentOrDivisionDialog(booking);$event.stopPropagation()"
                            *matRowDef="let booking; columns: this.columns;"
                            [ngClass]="{'filter blur-sm': this.selectedBooking && this.selectedBooking?.id !== booking?.id, 'cursor-pointer shadow': !booking.bookingID || (!!booking.bookingID && booking?.art === this.bookingArtOptions.WARM_MIETE)}"
                            class="order-row h-16 hover:bg-gray-100 dark:hover:bg-hover"
                            mat-row></tr>
                        <tr *matFooterRowDef="this.columns"
                            @slideInLeft
                            class="h-16 hover:bg-transparent"
                            mat-footer-row></tr>
                        <!--                        <tr-->
                        <!--                            *matRowDef="let booking; columns: ['propertyUnitsOverview']"-->
                        <!--                            class="h-0 hover:bg-transparent"-->
                        <!--                            mat-row></tr>-->
                        <tr
                            *matFooterRowDef="['recentOrdersTableFooter']"
                            class="ml-2 h-16 border-0 hover:bg-transparent"
                            mat-footer-row></tr>
                    </table>
                </div>
                <mat-paginator (page)='onPageChanged($event)'
                               @zoomIn
                               @zoomOut
                               [disabled]="this.isLoading"
                               [length]="this.page?.length"
                               [ngClass]="{'pointer-events-none': this.isLoading}"
                               [pageIndex]="this.page?.pageIndex"
                               [pageSizeOptions]="[10, 25, 50]"
                               [pageSize]="this.page?.pageSize"
                               [showFirstLastButtons]="false"
                               class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent">
                </mat-paginator>
            </ng-container>

            <!-- No Bookings -->
            <ng-template #loading>
                <div *ngIf="!this.initialLoad; else nodata"
                     class="p-8 sm:p-16 flex justify-center items-center nodata-container h-full">
                    <mat-spinner></mat-spinner>
                </div>
            </ng-template>
            <ng-template #nodata>
                <div class="flex flex-col h-full overflow-x-auto sm:overflow-y-auto">
                    <div class="flex flex-col justify-center p-8 sm:p-16 h-full">
                        <div class="flex justify-center">
                            <!-- Card #4 -->
                            <fuse-card *ngIf="!this.onlyUnpaid; else opCard"
                                       class="flex flex-col max-w-80 sm:max-w-100 w-full filter-article">
                                <img
                                    [src]="'assets/illustrations/online_banking/' + this.appTheme + '/undraw_online_banking.svg'"
                                    alt="Card cover image"
                                    class="object-cover">
                                <div class="m-8 mb-4">
                                    <div [ngSwitch]="this.accessLayer" class="text-2xl font-semibold leading-tight">
                                        <span *ngSwitchCase="this.bookingsAccessLayerOptions.PERIOD">
                                            Für diese Vermietung wurden noch keine Buchungen eingetragen
                                        </span>
                                        <span *ngSwitchCase="this.bookingsAccessLayerOptions.PROPERTY">
                                                Innerhalb dieser Immobilie wurden noch keine Sollstellungen eingetragen
                                        </span>
                                        <span *ngSwitchCase="this.bookingsAccessLayerOptions.ACCOUNT">
                                                Innerhalb dieses Kontos wurden noch keine Sollstellungen eingetragen
                                        </span>
                                    </div>
                                    <!--									<div class="text-secondary text-md mt-1">Unbegrenzt</div>-->
                                    <div [ngSwitch]="this.accessLayer" class="mt-4">
                                        <span *ngSwitchCase="this.bookingsAccessLayerOptions.PERIOD">
                                            Sie können jetzt Buchungen für diese Vermietung erstellen. Klicken Sie einfach auf den untenstehenden Button und folgen Sie den Anweisungen.
                                        </span>
                                        <span *ngSwitchCase="this.bookingsAccessLayerOptions.PROPERTY">
                                                Sie können innerhalb einer Vermietung dieser Immobilie Sollstellungen hinzufügen, um hier etwas zu sehen.
                                        </span>
                                        <span *ngSwitchCase="this.bookingsAccessLayerOptions.ACCOUNT">
                                            Sie können Sollstellungen für verschiedene Immobilien und Vermietungen innerhalb dieses Kontos hinzufügen, um hier etwas zu sehen.
                                        </span>

                                        <!--                                        <div class="mt-4">Add a user to join your business and digital management in order to push your productivity even more-->
                                    </div>
                                    <div *ngIf="this.accessLayer === this.bookingsAccessLayerOptions.PERIOD"
                                         class="flex mt-3 -mx-3 justify-center">
                                        <button (click)="this.openNewBookingDialog()"
                                                [color]="'primary'"
                                                class="px-3 items-center"
                                                mat-button>
                                            <mat-icon
                                                [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                                            <span class="ml-2">Jetzt Buchung hinzufügen</span>
                                        </button>
                                    </div>
                                </div>
                            </fuse-card>
                            <ng-template #opCard>
                                <fuse-card class="flex flex-col max-w-80 sm:max-w-100 w-full filter-article">
                                    <img
                                        [src]="'assets/illustrations/agree/' + this.appTheme + '/undraw_agree.svg'"
                                        alt="Card cover image"
                                        class="object-cover">
                                    <div class="m-8 mb-4">
                                        <div [ngSwitch]="this.accessLayer" class="text-2xl font-semibold leading-tight">
                                        <span *ngSwitchCase="this.bookingsAccessLayerOptions.PERIOD">
                                            Keine offenen Posten für diese Vermietung
                                        </span>
                                            <span *ngSwitchCase="this.bookingsAccessLayerOptions.PROPERTY">
                                                Keine offenen Posten für diese Immobilie
                                        </span>
                                            <span *ngSwitchCase="this.bookingsAccessLayerOptions.ACCOUNT">
                                                 Keine offenen Posten in Ihrem Account
                                        </span>
                                        </div>
                                        <!--									<div class="text-secondary text-md mt-1">Unbegrenzt</div>-->
                                        <div [ngSwitch]="this.accessLayer" class="mt-4">
                                        <span *ngSwitchCase="this.bookingsAccessLayerOptions.PERIOD">
                                            Alle Sollstellungen für diese Vermietung sind bezahlt. Wenn neue offene Posten entstehen, werden sie hier angezeigt.
                                        </span>
                                            <span *ngSwitchCase="this.bookingsAccessLayerOptions.PROPERTY">
                                                Alle Sollstellungen für die Einheiten dieser Immobilie sind bezahlt. Wenn neue offene Posten entstehen, werden sie hier angezeigt.
                                        </span>
                                            <span *ngSwitchCase="this.bookingsAccessLayerOptions.ACCOUNT">
                                            Glückwunsch! Alle Sollstellungen für Ihre Immobilien und Einheiten sind bezahlt. Neue offene Posten werden hier erscheinen.
                                        </span>

                                            <!--                                        <div class="mt-4">Add a user to join your business and digital management in order to push your productivity even more-->
                                        </div>
                                        <div *ngIf="this.accessLayer === this.bookingsAccessLayerOptions.PERIOD"
                                             class="flex mt-3 -mx-3 justify-center">
                                            <button (click)="this.openNewBookingDialog()"
                                                    [color]="'primary'"
                                                    class="px-3 items-center"
                                                    mat-button>
                                                <mat-icon
                                                    [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                                                <span class="ml-2">Jetzt Buchung hinzufügen</span>
                                            </button>
                                        </div>
                                    </div>
                                </fuse-card>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </ng-template>

            <!-- loading -->
            <ng-template #loading>
                <div class="flex flex-auto flex-col items-center justify-center bg-gray-100 dark:bg-transparent">
                    <mat-spinner></mat-spinner>
                </div>
            </ng-template>
        </div>

    </div>

</div>
