import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[hover]'
})
export class HoverDirective {

  isHovering: boolean;

  @HostListener('mouseover') onMouseOver(): void {
    this.isHovering = true;
  }

  @HostListener('mouseout') onMouseOut(): void {
    this.isHovering = false;
  }
}
