import {Pipe, PipeTransform} from '@angular/core';
import {NahausImage} from "@shared/models/image.interface";

@Pipe({
    name: 'nahausImageExists'
})
export class NahausImageExistsPipe implements PipeTransform {
    transform(img: NahausImage): boolean {
        return nahausImageExists(img);
    }

}


export const nahausImageExists = (img: NahausImage): boolean => {
    return !!(img && (img.url || img.downloadURL || (img.thumbs && Object.keys(img.thumbs).length > 0)));
}
