<div [ngClass]="{ roundCropper: avatar.round }" [ngStyle]="{ 'width.px': avatar.width, 'height.px': avatar.height }"
     class="cropper-container">
	<img #cropper class="image-cropper"/>
</div>
<div class="actions">
	<button (click)="onCancel()" class="icon-size-12"
	        color="warn"
	        mat-icon-button
	        matTooltip="Zurücksetzen">
		<mat-icon svgIcon="mat_outline:close"></mat-icon>
	</button>
	<button (click)="getImageBlob()" class="icon-size-12"
	        color="primary"
	        mat-icon-button
	        matTooltip="Bestätigen">
		<mat-icon svgIcon="mat_outline:check"></mat-icon>
	</button>
</div>
