import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolToString'
})
export class BoolToStringPipe implements PipeTransform {
  transform(value: boolean, args?: string): any {
    // console.log('value:', value, value ? 'Ja' : 'Nein');
    return value ? 'Ja' : 'Nein';
  }
}
