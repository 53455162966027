import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'formAutoValidate'
})
export class FormAutoValidatePipe implements PipeTransform {

  transform(value: UntypedFormGroup, timeout: number = 5000): UntypedFormGroup {
    console.log('calling transform');
    setTimeout(() => {
      console.log('calling timeout');
      if (value) {
        value.markAllAsTouched();
      }
    }, timeout);
    return value;
  }
}
