import {Pipe, PipeTransform} from '@angular/core';
import {NahausImage} from "@shared/models/image.interface";

@Pipe({
    name: 'toThumb'
})
export class ToThumbPipe implements PipeTransform {

    transform(img: NahausImage, size: number): string | undefined {
        return closestImage(img, size);
    }
}


export const closestImage = (img: NahausImage, size: number): string | undefined => {
    if (!img || !img.thumbs) {
        return undefined;
    }
    const sizes = Object.keys(img.thumbs).map(Number).sort((a, b) => a - b);
    const closestSize = sizes.find(s => s >= size) || sizes[0];
    console.log("closestSize", closestSize);
    return img.thumbs[closestSize.toString()]?.downloadURL;
}
