import {Injectable} from '@angular/core';
import {Firestore} from '@angular/fire/firestore';
import {AbstractFirestoreModularService} from '@shared/services/abstracts/AbstractFirestoreModularService';
import {AuthService} from '@shared/services/auth.service';
import {Dunning} from "../models/dunning.interface";

@Injectable()
export class DunningsModularService extends AbstractFirestoreModularService<Dunning> {

    COLLECTION = 'dunnings';

    constructor(authService: AuthService,
                firestore: Firestore) {
        super(authService, firestore);
    }

    setParentPathForPeriod(propertyID: string, unitID: string, periodID: string): void {
        this.parentPath = `properties/${propertyID}/units/${unitID}/periods/${periodID}`;
    }

}
