<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar'">
	<button
			(click)="open()"
			*ngIf="!opened"
			mat-icon-button>
		<mat-icon [svgIcon]="'heroicons_outline:search'"></mat-icon>
	</button>
	<div
			*ngIf="opened"
			@slideInTop
			@slideOutTop
			class="absolute inset-0 flex items-center shrink-0 z-99 bg-card">
		<mat-icon
				[svgIcon]="'heroicons_outline:search'"
				class="absolute ml-6 sm:ml-8"></mat-icon>
		<input
          #barSearchInput
		  (keydown)="onKeydown($event)"
		  [formControl]="searchControl"
		  [matAutocomplete]="matAutocomplete"
		  [placeholder]="'Search...'"
		  class="w-full h-full px-16 sm:px-18">
		<mat-autocomplete
          #matAutocomplete="matAutocomplete"
		  [autoSelectActiveOption]="true"
		  [disableRipple]="true"
		  class="max-h-128 sm:px-2 border-t rounded-b shadow-md">
			<mat-option
					*ngIf="resultSets && !resultSets.length"
					class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent">
				No results found!
			</mat-option>
			<ng-container *ngFor="let resultSet of resultSets; trackBy: trackByFn">
				<mat-optgroup class="flex items-center mt-2 px-2">
					<span class="text-sm font-semibold tracking-wider text-secondary">{{resultSet.label.toUpperCase()}}</span>
				</mat-optgroup>
				<ng-container *ngFor="let result of resultSet.results; trackBy: trackByFn">
					<mat-option
							[routerLink]="result.link"
							[value]="result.value"
							class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover">
						<!-- Contacts -->
						<ng-container *ngIf="resultSet.id === 'contacts'">
							<ng-container
									*ngTemplateOutlet="contactResult; context: {$implicit: result}"></ng-container>
						</ng-container>
						<!-- Pages -->
						<ng-container *ngIf="resultSet.id === 'pages'">
							<ng-container *ngTemplateOutlet="pageResult; context: {$implicit: result}"></ng-container>
						</ng-container>
						<!-- Tasks -->
						<ng-container *ngIf="resultSet.id === 'tasks'">
							<ng-container *ngTemplateOutlet="taskResult; context: {$implicit: result}"></ng-container>
						</ng-container>
					</mat-option>
				</ng-container>
			</ng-container>
		</mat-autocomplete>
		<button
				(click)="close()"
				class="absolute top-1/2 right-5 sm:right-7 shrink-0 w-10 h-10 -mt-5"
				mat-icon-button>
			<mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
		</button>
	</div>
</ng-container>

<!-- Basic search -->
<ng-container *ngIf="appearance === 'basic'">
	<div class="w-full sm:min-w-80">
		<mat-form-field subscriptSizing="dynamic" class="w-full">
			<mat-icon
					[svgIcon]="'heroicons_outline:search'"
					matPrefix></mat-icon>
			<input
					(keydown)="onKeydown($event)"
					[formControl]="searchControl"
					[matAutocomplete]="matAutocomplete"
					[placeholder]="'Search...'"
					matInput>
		</mat-form-field>
		<mat-autocomplete
          #matAutocomplete="matAutocomplete"
		  [autoSelectActiveOption]="true"
		  [disableRipple]="true"
		  class="max-h-128 mt-1 rounded">
			<mat-option
					*ngIf="resultSets && !resultSets.length"
					class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent">
				No results found!
			</mat-option>
			<ng-container *ngFor="let resultSet of resultSets; trackBy: trackByFn">
				<mat-optgroup class="flex items-center mt-2 px-2">
					<span class="text-sm font-semibold tracking-wider text-secondary">{{resultSet.label.toUpperCase()}}</span>
				</mat-optgroup>
				<ng-container *ngFor="let result of resultSet.results; trackBy: trackByFn">
					<mat-option
							[routerLink]="result.link"
							[value]="result.value"
							class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover">
						<!-- Contacts -->
						<ng-container *ngIf="resultSet.id === 'contacts'">
							<ng-container
									*ngTemplateOutlet="contactResult; context: {$implicit: result}"></ng-container>
						</ng-container>
						<!-- Pages -->
						<ng-container *ngIf="resultSet.id === 'pages'">
							<ng-container *ngTemplateOutlet="pageResult; context: {$implicit: result}"></ng-container>
						</ng-container>
						<!-- Tasks -->
						<ng-container *ngIf="resultSet.id === 'tasks'">
							<ng-container *ngTemplateOutlet="taskResult; context: {$implicit: result}"></ng-container>
						</ng-container>
					</mat-option>
				</ng-container>
			</ng-container>
		</mat-autocomplete>
	</div>
</ng-container>

<!-- Contact result template -->
<ng-template
  #contactResult
  let-result>
	<div class="flex items-center">
		<div class="flex shrink-0 items-center justify-center w-8 h-8 rounded-full overflow-hidden bg-primary-100 dark:bg-primary-800">
			<img
					*ngIf="result.avatar"
					[src]="result.avatar">
			<mat-icon
					*ngIf="!result.avatar"
					[svgIcon]="'heroicons_outline:user-circle'"
					class="m-0 icon-size-5 text-primary dark:text-primary-400"></mat-icon>
		</div>
		<div class="ml-3 truncate">
			<span [innerHTML]="result.name"></span>
		</div>
	</div>
</ng-template>

<!-- Page result template -->
<ng-template
  #pageResult
  let-result>
	<div class="flex flex-col">
		<div
				[innerHTML]="result.title"
				class="truncate leading-normal"></div>
		<div class="truncate leading-normal text-sm text-secondary">
			{{result.link}}
		</div>
	</div>
</ng-template>

<!-- Task result template -->
<ng-template
  #taskResult
  let-result>
	<div class="flex items-center">
		<ng-container *ngIf="result.completed">
			<mat-icon
					[svgIcon]="'heroicons_outline:check-circle'"
					class="mr-0 text-primary dark:text-primary-400"></mat-icon>
		</ng-container>
		<ng-container *ngIf="!result.completed">
			<mat-icon
					[svgIcon]="'heroicons_outline:check-circle'"
					class="mr-0 text-hint"></mat-icon>
		</ng-container>
		<div
				[innerHTML]="result.title"
				[ngClass]="{'line-through text-hint': result.completed}"
				class="ml-3 truncate leading-normal"></div>
	</div>
</ng-template>
