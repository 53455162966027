import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FuseLoadingService } from '@fuse/services/loading';
import { LogoSettingsService } from '@settings/services/logoSettings.service';
import { AngularDropzoneBase } from '../angular-dropzone-base.component';

@Component({
  selector: 'dropzone-advanced',
  templateUrl: './dropzone-advanced.component.html',
  styleUrls: ['./dropzone-advanced.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropzoneAdvancedComponent extends AngularDropzoneBase {

  constructor(cdRef: ChangeDetectorRef, storage: AngularFireStorage, logoSettingsService: LogoSettingsService, fuseLoadingService: FuseLoadingService) {
    super(cdRef, storage, logoSettingsService, fuseLoadingService);
  }


}
