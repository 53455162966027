import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { BaseWidget, NgAisIndex, NgAisInstantSearch } from 'angular-instantsearch';
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appNahausSearchBox]'
})
export class NahausSearchBoxDirective extends BaseWidget implements OnInit, OnDestroy {

  state: {
    query: string;
    refine: Function;
    clear: Function;
    isSearchStalled: boolean;
    widgetParams: object;
  }; // Private

  ngOnInit(): void {
    this.createWidget(connectSearchBox, {
      // instance options
    });
    super.ngOnInit();

    fromEvent(this.el.nativeElement, 'keyup')
      .pipe(takeUntil(this._unsubscribeAll), debounceTime(20), distinctUntilChanged())
      .subscribe((ref: any) => {
        console.log('ref', ref?.target?.value);
        this.state?.refine(ref?.target?.value);
        console.log('algolia state', this.state);
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    console.log('onDestroy() --> NahausSearchBoxDirective');
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    // this.instantSearchParent?.instantSearchInstance?.dispose();
  }

  private _unsubscribeAll: Subject<any>;

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch,
    private el: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super('SearchBox');

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  @HostListener('keyup', ['$event']) onKeyUp(input): void {
    console.log('value', input.target.value);
    // this.state.refine(input.target.value);
    this._changeDetectorRef.markForCheck();
  }

}
