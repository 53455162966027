import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AngularDropzoneBase} from './components/angular-dropzone-base.component';
import {CropperjsComponent} from './components/cropperjs/cropperjs.component';
import {DropzoneAdvancedComponent} from './components/dropzone-advanced/dropzone-advanced.component';
import {DropzoneAvatarComponent} from './components/dropzone-avatar/dropzone-avatar.component';
import {ConvertSizeUnitPipe} from './pipes/convert-size-unit.pipe';

@NgModule({
    declarations: [AngularDropzoneBase, ConvertSizeUnitPipe, CropperjsComponent, DropzoneAvatarComponent, DropzoneAdvancedComponent],
    imports: [CommonModule, HttpClientModule, MatButtonModule, MatIconModule, MatTooltipModule, AngularFireStorageModule],
    exports: [AngularDropzoneBase, DropzoneAvatarComponent, DropzoneAdvancedComponent],
    providers: [],
    schemas: []
})
export class AngularDropzoneModule {
}
