import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';
import { BaseWidget, NgAisIndex, NgAisInstantSearch } from 'angular-instantsearch';
import { connectAutocomplete } from 'instantsearch.js/es/connectors';
import { Landlord } from '../../../../modules/admin/landlords/models';

@Component({
  selector: 'app-admin-autocomplete-algolia-landlords',
  templateUrl: './autocomplete-algolia.component.html',
  styleUrls: ['./autocomplete-algolia.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteAlgoliaLandlordsComponent),
      multi: true
    }
  ]
})
export class AutocompleteAlgoliaLandlordsComponent extends BaseWidget implements OnInit, ControlValueAccessor {

  @Input() label: string;
  @Input() hint: string;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onQuerySuggestionClick = new EventEmitter<Landlord>();
  @Output() focus = new EventEmitter<void>();

  fc = new UntypedFormControl(null, Validators.required);
  disabled: boolean;

  selectedLandlord: Landlord;
  state: {
    query: string;
    refine: Function;
    indices: object[];
  };

  propagateChange = (_: any) => {
  };


  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch) {
    super('AutocompleteComponent');
  }

  public ngOnInit() {
    this.createWidget(connectAutocomplete, {});
    super.ngOnInit();
  }

  private _value: string;

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.propagateChange(this._value);
  }


  public handleChange($event: KeyboardEvent) {
    this.state.refine(($event.target as HTMLInputElement).value);
  }

  getDisplayName(value: any) {
    if (!value) {
      return;
    }
    return `${value?.displayName}`;
  }

  getDisplayNameHighlighted(value: any) {
    if (!value) {
      return;
    }
    return value?.displayName?.value;
  }

  trackByFn(index: number, landlord: Landlord) {
    return landlord.id; // or item.id
  }

  writeValue(value: string): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateValue($event: any) {
    console.log('update value -->', $event.target.value);
    this.value = $event.target.value;
  }

  clear() {
    this.fc.patchValue(null);
    this.value = null;
    this.selectedLandlord = null;
  }

  selectLandlord(landlord: Landlord) {
    this.selectedLandlord = landlord;
    this.onQuerySuggestionClick.emit(landlord);
    this.disabled = true;
  }
}
