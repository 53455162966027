import {Pipe, PipeTransform} from '@angular/core';
// import { Landlord, LandlordTypeOptions } from '../../modules/admin/landlords/models';
import {NahausContact} from 'app/modules/admin/contacts/models';

@Pipe({
    name: 'contactName'
})
export class ContactNamePipe implements PipeTransform {

    transform(contact: NahausContact): string {
        if (contact.firstName && contact.lastName) {
            return `${contact.firstName} - ${contact.lastName}`;
        } else {
            return ''
        }

    }

}
